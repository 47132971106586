import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Button, Box, Grid} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import HeaderView from 'view/common/Header';
import backgroundMain1 from 'asset/imageV2/background_main1.svg';
import iconArrowRight from 'asset/imageV2/icon_arrow_right.png';
import iconCert1 from 'asset/imageV2/icon_cert1.svg';
import iconCert2 from 'asset/imageV2/icon_cert2.png';
import iconCert3 from 'asset/imageV2/icon_cert3.png';
import iconCert4 from 'asset/imageV2/icon_cert4.png';
import iconCert5 from 'asset/imageV2/icon_cert5.png';
import iconCert6 from 'asset/imageV2/icon_cert6.png';
import imgMain1 from 'asset/imageV2/image_main25-1.png';
import imgMain2 from 'asset/imageV2/image_main2.png';
import imgMain3 from 'asset/imageV2/image_main25-3.png';
import imgMain5 from 'asset/imageV2/image_main5.png';
import imgBird from 'asset/imageV2/keewi-bird-left.png';
import {mainColoredBtn, iconBox} from 'view/main/Styles';
import FooterView from 'view/common/Footer';
import SideOverlay1 from 'view/common/SideOverlay1';
import APIUtils from 'common/utils/APIUtils';
import {GetRunningPopup} from 'common/utils/GraphQLUtils';

const MainPage = () => {
  const authReducer = useSelector(state => state.authReducer);
  const navigate = useNavigate();
  const [expand1, setExpand1] = useState(false);
  const [expand2, setExpand2] = useState(false);
  const [expand3, setExpand3] = useState(false);
  const [expand4, setExpand4] = useState(false);
  function showMainButton() {
    if (authReducer.isLogin) {
      return (
        <Button
          variant="standard"
          style={mainColoredBtn}
          onClick={() => {
            navigate('/mypage');
          }}
        >
          {'들어가기'}
          <img
            src={iconArrowRight}
            alt="iconArrowRight"
            style={{marginLeft: '1rem', width: '1.5rem'}}
          />
        </Button>
      );
    }
    return (
      <Button
        variant="standard"
        style={mainColoredBtn}
        onClick={() => {
          navigate('/login');
        }}
      >
        {'회원가입/로그인'}
        <img
          src={iconArrowRight}
          alt="iconArrowRight"
          style={{marginLeft: '1rem', width: '1.5rem'}}
        />
      </Button>
    );
  }
  return (
    <Box
      style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
    >
      <GetRunningPopup />
      <SideOverlay1 />
      <HeaderView />

      <Box
        style={{
          marginTop: '0rem',
          width: '80%',
          maxWidth: '90rem',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box style={{flex: 4}}>
          <Box
            style={{
              fontSize: '1rem',
              color: '#49545c',
              marginTop: '3rem',
            }}
          >
            재미있는 글쓰기
          </Box>
          <Box
            style={{
              fontSize: '2.625rem',
              color: '#2f3941',
              fontWeight: '700',
              marginTop: '1rem',
            }}
          >
            <span style={{color: '#00C500'}}>쉽게</span> 쓰고
          </Box>
          <Box
            style={{fontSize: '2.625rem', color: '#2f3941', fontWeight: '700'}}
          >
            <span style={{color: '#00C500'}}>함께</span> 고치자!
          </Box>
          <Box style={{marginTop: '2rem', marginBottom: '5rem'}}>
            {showMainButton()}
          </Box>
        </Box>
        <Box
          style={{
            flex: 4,
            marginTop: '2rem',
            position: 'relative',
          }}
        >
          <img
            src={imgMain1}
            alt="imgMain1"
            style={{
              display: 'flex',
              marginRight: '5rem',
              width: '100%',
              height: '100%',
              position: 'absolute',
              objectFit: 'contain',
              objectPosition: 'center center',
            }}
          />
          <img
            src={backgroundMain1}
            alt={'backgroundMain1'}
            style={{
              width: '120%',
              height: '100%',
              zIndex: '-1',
              position: 'absolute',
              objectFit: 'contain',
              objectPosition: 'center center',
              marginLeft: '-20%',
            }}
          />
        </Box>
      </Box>
      <Box
        style={{
          marginTop: '3rem',
          width: '80%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box style={{color: '#00C500', fontWeight: '700'}}>수상 및 인증</Box>
        <Box
          style={{
            marginTop: '1rem',
            width: '120%',
            maxWidth: '60rem',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Box
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              marginTop: '2rem',
              fontSize: '0.7rem',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box style={iconBox}>
                <img
                  src={iconCert6}
                  alt="iconCert1"
                  style={{
                    height: '40%',
                  }}
                />
              </Box>
              <Box>국가공헌대상</Box>
              <Box style={{color: '#46b785', fontWeight: '700'}}>
                교육부 장관상
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box style={iconBox}>
                <img
                  src={iconCert1}
                  alt="iconCert5"
                  style={{
                    height: '40%',
                  }}
                />
              </Box>
              <Box>에듀테크 실증 </Box>
              <Box style={{color: '#46b785', fontWeight: '700'}}>
                KERIS 원장상
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box style={iconBox}>
                <img
                  src={iconCert5}
                  alt="iconCert2"
                  style={{
                    height: '40%',
                  }}
                />
              </Box>
              <Box>이러닝-에듀테크</Box>
              <Box style={{color: '#46b785', fontWeight: '700'}}>
                비즈 모델 최우수상
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box style={iconBox}>
                <img
                  src={iconCert4}
                  alt="iconCert3"
                  style={{
                    height: '40%',
                  }}
                />
              </Box>
              <Box>에듀테크 우수기업</Box>
              <Box style={{color: '#46b785', fontWeight: '700'}}>
                콘텐츠·서비스 분야
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box style={iconBox}>
                <img
                  src={iconCert3}
                  alt="iconCert4"
                  style={{
                    height: '40%',
                  }}
                />
              </Box>
              <Box>SW 품질 평가</Box>
              <Box style={{color: '#46b785', fontWeight: '700'}}>
                {' '}
                Good Software 1등급
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          width: '90%',
          maxWidth: '90rem',
          marginTop: '5rem',
          marginBottom: '3rem',
          display: 'flex',
        }}
      >
        <Box style={{flex: '4'}}>
          <Box style={{color: '#00C500', fontWeight: '700'}}>키위챗과 함께</Box>
          <Box
            style={{
              fontSize: '1.75rem',
              fontWeight: '700',
              marginTop: '0.5rem',
              color: '#49545c',
            }}
          >
            글쓰기 전과정을 키위챗과 함께
          </Box>
          <Box style={{fontSize: '1rem', color: '#7d7d7d', marginTop: '1rem'}}>
            과제가 시작되면 선생님의 지침을 함께 이해하고, 글쓰기 전략에 대해
            키위챗과 대화할 수 있습니다. 이해하기 어려운 단어, 제목 구상 등
            과제를 완수하는 데 도움을 줍니다.
          </Box>
          <Box style={{fontSize: '1rem', color: '#7d7d7d'}}>
            수정할 때에는 키위챗이 글을 함께 읽고 응원해 줍니다.
          </Box>
          <Box style={{fontSize: '1rem', color: '#7d7d7d'}}>
            과제가 종료되면 키위챗과 대화할 수 있습니다. 키위챗이 선생님의
            피드백을 함께 읽고 내용에 대해서 토론합니다. 논리적 대화의 능력을
            키워 보세요.
          </Box>
        </Box>
        <Box
          style={{
            flex: '6',
            maxWidth: '20rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={imgBird}
            alt="imgBird"
            style={{width: '50%', maxWidth: '15rem'}}
          />
        </Box>
      </Box>
      <Box
        style={{
          width: '90%',
          maxWidth: '90rem',
          marginTop: '5rem',
          marginBottom: '3rem',
          display: 'flex',
        }}
      >
        <Box style={{flex: '4'}}>
          <Box style={{color: '#00C500', fontWeight: '700'}}>평가와 피드백</Box>
          <Box
            style={{
              fontSize: '1.75rem',
              fontWeight: '700',
              marginTop: '0.5rem',
              color: '#49545c',
            }}
          >
            AI 피드백을 반영해서 완성하기
          </Box>
          <Box style={{fontSize: '1rem', color: '#7d7d7d', marginTop: '1rem'}}>
            AI 피드백을 받으면서 즉각적으로 글을 수정할 수 있습니다. 맞춤법,
            띄어쓰기, 문장 호응, 다양한 문장 구성 등 글의 기본 형식을 갖추는 데
            도움을 줍니다.
          </Box>
          <Box style={{fontSize: '1rem', color: '#7d7d7d'}}>
            <span style={{fontWeight: 'bold', color: '#00C500'}}>
              AI 글쓰기 연습
            </span>
            을 활용하세요. 키위 AI 피드백과 함께 스스로 글을 완성해 나가는 힘을
            키워 보세요.
          </Box>
        </Box>
        <Box
          style={{
            flex: '6',
            maxWidth: '20rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={imgMain2}
            alt="imgMain2"
            style={{width: '80%', maxWidth: '25rem'}}
          />
        </Box>
      </Box>
      <Box
        style={{
          width: '90%',
          maxWidth: '90rem',
          marginTop: '3rem',
          marginBottom: '3rem',
          display: 'flex',
        }}
      >
        <Box style={{flex: '4'}}>
          <Box style={{color: '#00C500', fontWeight: '700'}}>
            선생님별 과제 관리
          </Box>
          <Box
            style={{
              fontSize: '1.75rem',
              fontWeight: '700',
              marginTop: '0.5rem',
              color: '#49545c',
            }}
          >
            선생님마다 강의실이 만들어져요!
          </Box>
          <Box style={{fontSize: '1rem', color: '#7d7d7d', marginTop: '1rem'}}>
            선생님께 인증 요청을 보내세요. 인증을 받으면 ‘나의 강의실’과 ‘AI
            글쓰기 연습’ 기능이 활성화됩니다. 선생님께 과제를 받기 위해서는
            반드시 선생님 연결이 필요합니다.
          </Box>
        </Box>
        <Box
          style={{
            flex: '6',
            maxWidth: '20rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={imgMain3}
            alt="imgMain2"
            style={{width: '80%', maxWidth: '25rem'}}
          />
        </Box>
      </Box>
      <Box
        style={{
          width: '90%',
          maxWidth: '90rem',
          marginTop: '3rem',
          marginBottom: '3rem',
          display: 'flex',
        }}
      >
        <Box style={{flex: '4'}}>
          <Box style={{color: '#00C500', fontWeight: '700'}}>
            자주 묻는 질문
          </Box>
          <Box
            style={{
              fontSize: '1.75rem',
              fontWeight: '700',
              marginTop: '0.5rem',
            }}
          >
            글쓰기 대회와 문해력 더하기
          </Box>
          <Box style={{display: 'block'}}>
            <Button
              style={{
                display: 'block',
                marginTop: '2rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                setExpand1(prev => !prev);
              }}
            >
              Q. 키위런을 혼자 사용할 수 있나요?
            </Button>
            <Box
              style={{
                display: expand1 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              키위런은 키위티 선생님과 연결되어 사용할 수 있습니다. 글쓰기 대회
              주최자 선생님 등 기본 제공되는 선생님과 연결하면 개인적인 기능을
              이용할 수 있습니다. 연결된 모든 선생님과 연결이 끊어져도 이전의
              과제 제출과 AI 연습 기록을 확인할 수 있습니다.
            </Box>
            <Button
              style={{
                display: 'block',
                marginTop: '1rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                setExpand2(prev => !prev);
              }}
            >
              Q. AI 글쓰기 연습은 무료인가요?
            </Button>
            <Box
              style={{
                display: expand2 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              ‘AI 글쓰기 연습’은 스스로 글쓰기 연습할 때 사용합니다. 스스로
              관리하고 선생님에게 제출되지 않습니다. 글의 종류를 선택하고,
              제목과 본문을 입력하세요. AI 피드백을 받을 수 있습니다. 글쓰기를
              완성한 후, 키위챗과도 대화해 보세요. AI 글쓰기 연습은 [연습한
              글]에 저장됩니다.
            </Box>
            <Button
              style={{
                display: 'block',
                marginTop: '1rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                setExpand3(prev => !prev);
              }}
            >
              Q. 문해력 더하기는 무엇인가요?
            </Button>
            <Box
              style={{
                display: expand3 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              챗GPT 등 최신 AI가 학습하는 방법이 바로 빈칸 채우기 콘텐츠입니다.
              스스로 빈칸 콘텐츠를 만들어서 친구에게 공유할 수 있습니다. 또한
              공유되어 있는 콘텐츠를 선택해서 좋은 글을 완성해 보세요. 어휘력과
              작문 능력에 확실한 효과가 있습니다.
            </Box>
            <Button
              style={{
                display: 'block',
                marginTop: '1rem',
                padding: '0.3rem 1rem',
                fontSize: '1rem',
                color: '#49545c',
                border: '1px solid #D8DCDE',
              }}
              onClick={() => {
                setExpand4(prev => !prev);
              }}
            >
              Q. 글쓰기 대회는 어디서 하나요?
            </Button>
            <Box
              style={{
                display: expand4 ? 'block' : 'none',
                margin: '1rem',
                color: '#68737D',
              }}
            >
              ‘온라인 AI 글쓰기 대회’는 키위런 안에서 진행됩니다. 인증 요청 즉시
              인증해주는 특별한 ‘글쓰기 대회 주최자 선생님’과 연결되면 글쓰기
              대회 과제가 나타납니다. 인증 번호는{' '}
              <font color="#00C500">1000-1000</font>
              입니다. 선생님과의 연결되면 대회 참여는{' '}
              <font color="red">무료</font>입니다.
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            display: expand1 || expand2 || expand3 || expand4 ? 'none' : 'flex',
            marginTop: '6.7rem',
            maxWidth: '20rem',
            flex: '6',
            alignItems: 'start',
            justifyContent: 'center',
          }}
        >
          <img src={imgMain5} alt="imgMain5" style={{width: '80%'}} />
        </Box>
      </Box>
      <FooterView />
    </Box>
  );
};
export default MainPage;
