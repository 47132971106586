import {Backdrop, CircularProgress, Box} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const ApiLoading = props => {
  return (
    <>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
        open={props.loading}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size="10rem" color="inherit" thickness={10} />
          <Box
            style={{
              marginTop: '5rem',
              fontSize: 'calc(0.8rem + 1vw)',
              fontWeight: 'bold',
            }}
          >
            {'ㆍㆍ화면을 구성하는 중입니다ㆍㆍ'}
          </Box>
        </Box>
      </Backdrop>
    </>
  );
};

ApiLoading.propTypes = {
  loading: PropTypes.bool,
};

export default ApiLoading;
