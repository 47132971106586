import React, {useEffect, useState} from 'react';
import {Box, Typography, TextField, Button} from '@mui/material';
import PropTypes from 'prop-types';
import EditIcon from 'asset/image/edit.svg';
import DeleteIcon from 'asset/image/trash.svg';
import RepleIcon from 'asset/imageV2/turn-left.svg';
import {StringUtils} from 'common/utils/StringUtils';

const CommentList = ({
  commentData,
  handleUpdateComment,
  my_id,
  owner_id,
  student_name,
}) => {
  if (!commentData || commentData.length === 0) return null;

  const [sortedComments, setSortedComments] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [editMode, setEditMode] = useState('');
  const [editText, setEditText] = useState('');
  const [newReply, setNewReply] = useState({});
  const [reMode, setReMode] = useState('close');
  const [reIndex, setReIndex] = useState('close');

  useEffect(() => {
    if (commentData.length > 0) {
      setSortedComments(
        [...commentData].sort(
          (a, b) => new Date(a.a_time) - new Date(b.a_time),
        ),
      );
    }
  }, [commentData]);

  const handleDelete = async (index, mode) => {
    const this_comment = sortedComments[index];
    setSortedComments(prevComments =>
      prevComments.map((comment, i) =>
        i === index
          ? {...comment, [mode === 'b' ? 'b_comment' : 'a_comment']: '(삭제됨)'}
          : comment,
      ),
    );
    await handleUpdateComment(
      mode === 'b' ? 're' : 'update',
      this_comment,
      '(삭제됨)',
    );
  };

  const handleTeacherDelete = async (index, mode) => {
    const this_comment = sortedComments[index];
    setSortedComments(prevComments =>
      prevComments.map((comment, i) =>
        i === index
          ? {...comment, [mode === 'b' ? 'b_comment' : 'a_comment']: '(삭제됨)'}
          : comment,
      ),
    );
    await handleUpdateComment(
      mode === 'b' ? 'b-delete' : 'a-delete',
      this_comment,
      '(삭제됨)',
    );
  };

  const handleEdit = (index, mode, text) => {
    setEditIndex(index);
    setEditMode(mode);
    setEditText(
      text.replaceAll('(수정됨)', '').replaceAll('(삭제됨)', '').trim(),
    );
  };

  const handleSave = async index => {
    if (!editText.trim()) {
      setEditIndex(null);
      setEditMode('');
      return;
    }

    const this_comment = sortedComments[index];
    const updatedText = editText
      .replaceAll('(수정됨)', '')
      .replaceAll('(삭제됨)', '')
      .trim();
    const postfix = ' (수정됨)';

    setSortedComments(prevComments =>
      prevComments.map((comment, i) =>
        i === index
          ? {
              ...comment,
              [editMode === 'b' ? 'b_comment' : 'a_comment']:
                updatedText + postfix,
            }
          : comment,
      ),
    );

    await handleUpdateComment(
      editMode === 'b' ? 're' : 'update',
      this_comment,
      updatedText + postfix,
    );
    setEditIndex(null);
    setEditMode('');
  };

  const handleCancel = async index => {
    setEditIndex(null);
    setEditMode('');
    return;
  };

  const handleReplyChange = (index, text) => {
    setNewReply(prev => ({...prev, [index]: text}));
  };

  const handleReplySubmit = async index => {
    const this_comment = sortedComments[index];
    const replyText = newReply[index]?.trim();

    if (!replyText) return;

    await handleUpdateComment('re', this_comment, replyText);
    setSortedComments(prevComments =>
      prevComments.map((comment, i) =>
        i === index ? {...comment, b_comment: replyText} : comment,
      ),
    );

    setNewReply(prev => ({...prev, [index]: ''}));
    setReMode('close');
  };

  const handleReplyCancel = async index => {
    setReMode('close');
    return;
  };

  return (
    <>
      {sortedComments.map((comment, index) => (
        <Box
          key={index}
          sx={{
            padding: '1rem',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            marginBottom: '1rem',
            marginRight: '1rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '1rem',
                display: 'inline-flex',
                alignItems: 'center',
              }}
            >
              {comment.a_name}
              <Box
                component="span"
                sx={{
                  fontSize: '0.8rem',
                  color: '#898989',
                  marginLeft: '0.5rem',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                ({comment.a_time})
                {comment.b_comment == '' &&
                  my_id === owner_id &&
                  comment.a_id != my_id &&
                  reMode == 'close' && (
                    <Box
                      component="span"
                      onClick={() => {
                        setReIndex(index);
                        setReMode('open');
                      }}
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        marginLeft: '0.5rem',
                        cursor: 'pointer',
                      }}
                    >
                      <img
                        src={RepleIcon}
                        alt="Reple Icon"
                        style={{width: '24px', height: '14px'}}
                      />{' '}
                      답글
                    </Box>
                  )}
              </Box>
            </Typography>
          </Box>

          {editIndex === index && editMode === 'a' ? (
            <Box
              sx={{display: 'flex', alignItems: 'center', marginTop: '0.5rem'}}
            >
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                value={editText}
                onChange={e => setEditText(e.target.value)}
                onKeyDown={e => e.key === 'Enter' && handleSave(index)}
                sx={{marginRight: '0.5rem'}}
              />
              <Button
                variant="contained"
                size="small"
                onClick={() => handleSave(index)}
                sx={{backgroundColor: '#4FD7C3', color: 'white'}}
              >
                저장
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={() => handleCancel(index)}
                sx={{
                  backgroundColor: '#898989',
                  marginLeft: '0.5rem',
                  color: 'white',
                }}
              >
                취소
              </Button>
            </Box>
          ) : (
            <Box
              sx={{display: 'flex', alignItems: 'center', marginTop: '0.5rem'}}
            >
              <Typography>{comment.a_comment}</Typography>
              {comment.a_id === my_id && (
                <>
                  <Box
                    component="span"
                    onClick={() => handleEdit(index, 'a', comment.a_comment)}
                    sx={iconStyle('#4FD7C3')}
                  >
                    <img src={EditIcon} alt="EditIcon" style={iconSize} />
                  </Box>
                  <Box
                    component="span"
                    onClick={() => handleDelete(index, 'a')}
                    sx={iconStyle('#FF5555')}
                  >
                    <img src={DeleteIcon} alt="DeleteIcon" style={iconSize} />
                  </Box>
                </>
              )}
              {comment.a_id !== my_id && my_id === 0 && (
                <>
                  <Box
                    component="span"
                    onClick={() => handleTeacherDelete(index, 'a')}
                    sx={iconStyle('#FF5555')}
                  >
                    <img src={DeleteIcon} alt="DeleteIcon" style={iconSize} />
                  </Box>
                </>
              )}
            </Box>
          )}

          {/* 🔹 대댓글 (답변) - 동일한 형태 적용 */}
          {comment.b_comment && (
            <Box
              sx={{
                marginTop: '0.5rem',
                paddingLeft: '1.5rem',
                borderLeft: '2px solid #ddd',
              }}
            >
              {/* 🔹 대댓글 - 저자(이름) & 날짜 */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography sx={{fontWeight: 'bold', fontSize: '1rem'}}>
                  {student_name}
                  <Box
                    component="span"
                    sx={{
                      fontSize: '0.8rem',
                      color: '#898989',
                      marginLeft: '0.5rem',
                    }}
                  >
                    ({comment.b_time})
                  </Box>
                </Typography>
              </Box>

              {/* 🔹 대댓글 - 본문 */}
              {editIndex === index && editMode === 'b' ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '0.5rem',
                  }}
                >
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={editText}
                    onChange={e => setEditText(e.target.value)}
                    onKeyDown={e => e.key === 'Enter' && handleSave(index)}
                    sx={{marginRight: '0.5rem'}}
                  />
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleSave(index)}
                    sx={{backgroundColor: '#4FD7C3', color: 'white'}}
                  >
                    저장
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleCancel(index)}
                    sx={{
                      backgroundColor: '#898989',
                      marginLeft: '0.5rem',
                      color: 'white',
                    }}
                  >
                    취소
                  </Button>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '0.5rem',
                    marginBottom: '1rem',
                  }}
                >
                  <Typography>{comment.b_comment}</Typography>

                  {my_id === 0 && (
                    <>
                      <Box
                        component="span"
                        onClick={() => handleTeacherDelete(index, 'b')}
                        sx={iconStyle('#FF5555')}
                      >
                        <img
                          src={DeleteIcon}
                          alt="DeleteIcon"
                          style={iconSize}
                        />
                      </Box>
                    </>
                  )}
                  {my_id === owner_id && (
                    <>
                      <Box
                        component="span"
                        onClick={() =>
                          handleEdit(index, 'b', comment.b_comment || '')
                        }
                        sx={iconStyle('#007AFF')}
                      >
                        <img src={EditIcon} alt="EditIcon" style={iconSize} />
                      </Box>
                      <Box
                        component="span"
                        onClick={() => handleDelete(index, 'b')}
                        sx={iconStyle('#FF5555')}
                      >
                        <img
                          src={DeleteIcon}
                          alt="DeleteIcon"
                          style={iconSize}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              )}
            </Box>
          )}
          {comment.b_comment == '' &&
            my_id === owner_id &&
            comment.a_id != my_id &&
            reIndex == index &&
            reMode == 'open' && (
              <Box
                sx={{
                  display: 'flex',
                  marginTop: '1rem',
                  paddingLeft: '1.5rem',
                  borderLeft: '2px solid #ddd',
                }}
              >
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  placeholder="답글을 입력하세요..."
                  value={newReply[index] || ''}
                  onChange={e => handleReplyChange(index, e.target.value)}
                  onKeyDown={e => e.key === 'Enter' && handleReplySubmit(index)}
                  sx={{marginRight: '0.5rem'}}
                />
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: '#4FD7C3',
                    width: '6rem',
                    color: 'white',
                  }}
                  onClick={() => handleReplySubmit(index)}
                >
                  답글 달기
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => handleReplyCancel(index)}
                  sx={{
                    backgroundColor: '#898989',
                    marginLeft: '0.5rem',
                    color: 'white',
                  }}
                >
                  취소
                </Button>
              </Box>
            )}
        </Box>
      ))}
    </>
  );
};

// ✅ 스타일 공통화
const iconSize = {width: '16px', height: '16px'};
const iconStyle = color => ({
  cursor: 'pointer',
  color,
  fontSize: '1rem',
  userSelect: 'none',
  marginLeft: '0.7rem',
  display: 'inline',
  '&:hover': {color},
});

// ✅ PropTypes 추가
CommentList.propTypes = {
  commentData: PropTypes.array.isRequired,
  handleUpdateComment: PropTypes.func.isRequired,
  my_id: PropTypes.number.isRequired,
  owner_id: PropTypes.number.isRequired,
  student_name: PropTypes.string.isRequired,
};

export default CommentList;
