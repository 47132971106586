import React, {useState, useEffect} from 'react';
import {Box} from '@mui/material';
import {useSelector} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import HeaderView from 'view/common/Header';
import FooterView from 'view/common/Footer';
import SidebarView from 'view/mypage/SidebarView';
import FragmentHome from 'view/mypage/FragmentHome';
import FragmentAccount from 'view/mypage/FragmentAccount';
import FragmentClass from 'view/mypage/FragmentClass';
import FragmentClassSelected from 'view/mypage/FragmentClassSelected';
import FragmentSubmitTask from 'view/mypage/FragmentSubmitTask';
import FragmentPractice from 'view/mypage/FragmentPractice';
const MyPage = () => {
  const authReducer = useSelector(state => state.authReducer);
  const navigate = useNavigate();
  const location = useLocation();

  const [fragmentStatus, setFragmentStatus] = useState(1);
  const [selectedUserID, setSelectedUserID] = useState(0);
  const [selectedTeacherName, setSelectedTeacherName] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(prev => !prev);
  };

  function showFragment() {
    switch (fragmentStatus) {
      case 1:
        return <FragmentHome />;
      case 2:
        return <FragmentAccount />;
      case 3:
        return <FragmentClass classSelect={classSelect} />;
      case 31:
        return (
          <FragmentClassSelected
            user_id={selectedUserID}
            teacher_name={selectedTeacherName}
          />
        );
      case 4:
        return <FragmentSubmitTask user_id={selectedUserID} />;
      case 5:
        return <FragmentPractice user_id={selectedUserID} />;
      default:
        return <FragmentHome />;
    }
  }

  function classSelect(user_id, name) {
    setFragmentStatus(31);
    setSelectedUserID(user_id);
    setSelectedTeacherName(name);
  }

  function SidebarClickEvent(option) {
    setFragmentStatus(option);
  }

  useEffect(() => {
    let fragmentVal = location.state?.fragmentNum || 1;
    setFragmentStatus(fragmentVal);
  }, [location.state]);

  return (
    <Box
      style={{
        width: '100%',
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <HeaderView />
      <Box
        style={{
          marginBottom: '5%',
          marginTop: '1%',
          width: '96%',
          flex: 1,
          display: 'flex',
        }}
      >
        <Box
          className={`
            bg-white shadow-lg transition-all duration-300 ease-in-out relative
            ${isSidebarOpen ? 'w-64' : 'w-12'}
          `}
          style={{
            position: 'relative', // 버튼 위치 기준 설정
            paddingTop: '20px', // 버튼과 상단 컨테이너 간 여백 추가
          }}
        >
          {/* 화살표 버튼 */}
          <button
            onClick={toggleSidebar}
            style={{
              position: 'absolute',
              top: '0px', // 화살표 위치 아래로 조정
              left: '0px',
              backgroundColor: '#E6FAE6',
              color: '#00C500',
              border: 'none',
              borderRadius: '30%',
              width: '30px',
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            aria-label={isSidebarOpen ? '사이드바 닫기' : '사이드바 열기'}
          >
            {isSidebarOpen ? '<' : '>'}
          </button>

          {/* 사이드바 내용 */}
          {isSidebarOpen && (
            <SidebarView
              currentFragment={fragmentStatus}
              clickEvent={SidebarClickEvent}
            />
          )}
        </Box>

        {/* Main content */}
        <Box
          style={{
            flex: 1,
            marginLeft: '5%',
            marginRight: '1%',
          }}
        >
          {showFragment()}
        </Box>
      </Box>
      <FooterView />
    </Box>
  );
};

export default MyPage;
