import React, {useEffect, useState, useMemo, useRef} from 'react';
import {Box, Grid, TextField, Button, IconButton} from '@mui/material';
import {useLocation} from 'react-router-dom';
import APIUtils from 'common/utils/APIUtils';
import {AnalysisText} from 'view/result/AnalysisText';
import {StringUtils} from 'common/utils/StringUtils';
import {NumberUtils} from 'common/utils/NumberUtils';
import emojiRegex from 'emoji-regex';
import {
  MarginBoxComponent,
  TitleComponent,
  SingleLineComponent,
  SingleLineTitle,
  WhiteBox,
} from 'view/common/Components';
import ApiLoading from 'view/common/ApiLoading';
import CommentItem from 'view/comment/CommentItem';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {throttle} from 'lodash';
import HeaderView from 'view/common/Header';
import FooterView from 'view/common/Footer';
import RadarChart from 'view/common/RadarChart';
import KeewiCloud from 'view/common/KeewiCloud_None';
import EssayEditor from 'view/result/EssayEditor';
import PencilIcon2 from 'asset/imageV2/icon_pencil2.svg';
import CopyIcon from 'asset/imageV2/icon_copy.svg';
import ChatIcon from 'asset/imageV2/icon_chat.svg';
import ToastPopup from 'view/popup/ToastPopup';
import ToastPopup2 from 'view/popup/ToastPopup2';
import {modifySideKeewiMsg} from 'common/reducer/auth';
import KeewiBird from 'asset/imageV2/keewi-bird-right.png';

const MainPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);

  const [commentParagraph, setCommentParagraph] = useState([]);
  const [highlightWords, setHighlightWords] = useState([]);

  const [loading, setLoading] = useState(true);
  const [onError, setOnError] = useState(false);
  const [currentText, setCurrentText] = useState(location.state.text);
  const [isScrollTop, setIsScrollTop] = useState(true);
  const [bottomOverlay, setBottomOverlay] = useState(true);

  const [practiceEvalID, setPracticeEvalID] = useState(0);
  const [chatUrl, setChatUrl] = useState('');

  const [writingJanre, setWritingJanre] = useState('');
  const [titleText, setTitleText] = useState('');
  const [titleEditable, setTitleEditable] = useState(false);

  const [currentTryCount, setCurrentTryCount] = useState(0);
  const [maxTryLimit, setMaxTryLimit] = useState(0);

  const [toastText, setToastText] = useState('');
  const [toastVisibility, setToastVisibility] = useState(false);
  const toastTimeoutRef = useRef(null); // `setTimeout`을 추적할 ref
  const [toast2Text, setToast2Text] = useState('');
  const [toast2FontSizeText, setToast2FontSizeText] = useState('1rem');
  const [toast2Visibility, setToast2Visibility] = useState(false);
  const toast2TimeoutRef = useRef(null);
  const [toast3Text, setToast3Text] = useState('');
  const [toast3FontSizeText, setToast3FontSizeText] = useState('1rem');
  const [toast3Visibility, setToast3Visibility] = useState(false);
  const toast3TimeoutRef = useRef(null);
  const [currentEvalTry, setCurrentEvalTry] = useState(1);
  const [prevScore, setPrevScore] = useState(0);
  const [keewiComment, setKeewiComment] = useState('');

  function Toast2Show(text, time, sizeStr) {
    setToast2Text(text);
    setToast2FontSizeText(sizeStr);
    setToast2Visibility(true);

    if (toast2TimeoutRef.current) {
      clearTimeout(toast2TimeoutRef.current);
    }

    toast2TimeoutRef.current = setTimeout(() => {
      setToast2Visibility(false);
    }, time);
  }

  function Toast3Show(text, time, sizeStr) {
    let selectedSentence = text;
    const regex = new RegExp(`^(.{20,}?)(\\n|\\. |! |$)`);
    const match = text.match(regex);
    if (match) {
      selectedSentence = match[1];
    }

    setToast3Text(selectedSentence);
    setToast3FontSizeText(sizeStr);
    setToast3Visibility(true);

    if (toast3TimeoutRef.current) {
      clearTimeout(toast3TimeoutRef.current);
    }

    toast3TimeoutRef.current = setTimeout(() => {
      setToast3Visibility(false);
    }, time);
  }

  function ToastShow(text, time) {
    setToastText(text);
    setToastVisibility(true);

    // 기존 `setTimeout`이 실행 중이라면 취소
    if (toastTimeoutRef.current) {
      clearTimeout(toastTimeoutRef.current);
    }

    // 새로운 `setTimeout` 설정 후 페이지 이동
    toastTimeoutRef.current = setTimeout(() => {
      setToastVisibility(false);
    }, time);
  }

  const [labelText, setLabelText] = useState('');
  const [analyText, setAnalyText] = useState('');
  const [isGPT, setIsGPT] = useState(false);
  const [keewiScore, setKeewiScore] = useState({
    겹치는단어비율: 0,
    단어의평균길이: 0,
    문단별문장수: 0,
    문장별단어수: 0,
    분석오류문장수: 0,
    전체글자수: 0,
    전체문장수: 0,
    전체문단수: 0,
    흐름이자연스러운정도: 0,
    긴문장의수: 0,
    독자나이: 0,
  });
  const [radarData, setRadarData] = useState({
    labels: [],
    datasets: [],
  });

  const [wordCloudData, setWordCloudData] = useState([
    {text: '키위', value: 1},
    {text: '글쓰기', value: 2},
    {text: '평가', value: 3},
    {text: '엔진', value: 4},
    {text: '피드백', value: 5},
  ]);

  const handleTitle = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 100) {
        inputVal = inputVal.substring(0, 100);
      }
      setTitleText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };

  const memoizedWordCloud = useMemo(
    () => <KeewiCloud data={wordCloudData} height={'15rem'} />,
    [wordCloudData],
  );

  const [tScore, setTScore] = useState(0);

  function transformWordCloudData(wordCloud) {
    // 원본 데이터의 weight 합계 계산
    const totalWeight = wordCloud
      .slice(0, 40)
      .reduce((sum, item) => sum + item.weight, 0);

    // weight를 100으로 정규화 (스케일링)
    const transformedData = wordCloud.slice(0, 40).map(item => ({
      text: item.name,
      value: (item.weight / totalWeight) * 100,
    }));

    // 변환된 데이터를 설정
    setWordCloudData(transformedData);
  }

  const handleScoreEvaluation = async (prevScore, keewiRes) => {
    const truncatedText =
      currentText.length > 2000
        ? currentText.slice(0, 1000) + '\n . . . \n' + currentText.slice(-1000)
        : currentText;

    const formatKeewiContent = `
    다음은 학생이 글쓰기 연습으로 작성한 글입니다. 
    이름 : ${authReducer.student_name}
    제목 : ${titleText}
    본문 : ${truncatedText}
    장르 : ${writingJanre}
    수정 회수 : ${currentEvalTry}

    다음은 키위 글쓰기 평가 엔진이 자동 평가한 결과입니다. 
    ** 키위 엔진이 분석한 6 Traits of Writing 평가 **
    문법의 정확성 : ${keewiRes.trait_1_score} / 9
    어휘의 풍부성 : ${keewiRes.trait_2_score} / 9
    의도의 표현 : ${keewiRes.trait_3_score} / 9 
    문장 구사력 : ${keewiRes.trait_4_score} / 9 
    조직과 전개 : ${keewiRes.trait_5_score} / 9
    주제의 명확성 : ${keewiRes.trait_6_score} / 9

    ** 키위 엔진이 분석한 글의 통계 **
    겹치는 단어비율 : ${keewiRes.겹치는단어비율.toFixed(2)} / 1
    단어의 평균길이 : ${keewiRes.단어의평균길이.toFixed(1)} 글자
    문단별 문장수 : ${keewiRes.문단별문장수.toFixed(1)} 문장
    문장별 단어수: ${keewiRes.문장별단어수.toFixed(1)} 단어
    전체 글자수 : ${keewiRes.전체글자수} 글자
    전체 문장수 : ${keewiRes.전체문단수} 문장

    키위 엔진이 분석한 글의 최종 점수 : ${keewiRes.keewi_score}
    이전 버전 글의 점수 : ${prevScore}
  `;

    if (prevScore != 0 && prevScore < keewiRes.keewi_score) {
      try {
        const gptResponse = await APIUtils.ChatGPT(
          0.8,
          '너는 글쓰기 교육 전문가야. 학생이 AI 피드백을 받고 여러 번 글을 수정하는 과정에서 AI 자동 평가 결과를 반영해서 실질적인 도움을 줄 수 있도록 구체적인 피드백을 제공해 줘. "이름+님"을 불러주면 좋겠어.',
          formatKeewiContent,
          '이전 버전 글의 점수가 0점이라면 처음 작성한 글이야, 더 많은 설명을 해줘도 되. 수정 회수가 늘어나면 도움말의 내용을 줄여줘. 과제의 내용과 제약 조건을 검토하고 점수를 올리기위한 구체적인 방안을 제시해줘. 1. 키위 AI 평가 결과를 활용하여, 현재 글의 강점과 약점을 분석해 줘. 2. 무조건 긍정적인 칭찬은 삼가고 학생이 직접 개선할 수 있도록 구체적인 수정 방향을 제시해 줘.  3. 어떤 부분을 어떻게 수정하면 더 좋은 글이 될지, 실질적인 조언을 해 줘. 피드백 형식 (세 문단, 150자 내외) 첫 문단에서는 학생의 글에서 수정해야 할 주요 부분을 알려 줘. 두 번째 문단에서는 수정 방법을 예시와 함께 제시해 줘. 학생이 어떤 부분을 어떻게 바꾸면 더 좋은 글이 되는지 예시를 포함해 줘. 세번째 문단에서는 글에 사용되었으면 좋을 어휘와 이유를 설명해줘. 점수가 이미 90점 이상이라면 어휘추천 대신에 조직과 주제의 점수를 높이기 위한 전략을 제시해줘.  학생글의 수준에 맞는 피드백을 주고, 글을 개선할 수 있도록 구체적인 가이드라인을 제공해 줘. 두 문단, 100자 내외로 짧지만 강렬하게 작성해 줘.  첫 문단에서는 학생의 글에서 좋았던 점을 구체적으로 칭찬해 줘.  두 번째 문단에서는 점수를 올릴 수 있는 현실적인 힌트를 줘.  "이 부분을 살짝 다듬으면 한 단계 더 업그레이드될 거예요!"  이런 식으로 긍정적이고 친근하게 조언해 줘.  학생의 수준에 맞춘 쉬운 어휘를 사용하고, 항상 존댓말로 말해 줘. 노력하라는 말 대신에 잘할 수 있는 방향을 제시해줘. 그리고 이모티콘은 사용하지 말아줘. ',
        );

        if (
          gptResponse?.status === 200 &&
          gptResponse?.data?.ret_code === 1000
        ) {
          Toast3Show(gptResponse.data.text, 2000, '1.2rem');
          setKeewiComment(gptResponse.data.text.replace(/\n{2,}/g, '\n'));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const gptResponse = await APIUtils.ChatGPT(
          0.8,
          '너는 글쓰기 교육 전문가야. 학생이 AI 피드백을 받고 여러 번 글을 수정하는 과정에서 AI 자동 평가 결과를 반영해서 실질적인 도움을 줄 수 있도록 구체적인 피드백을 제공해 줘. "이름+님"을 불러주면 좋겠어.',
          formatKeewiContent,
          '이전 버전 글의 점수가 0점이라면 처음 작성한 글이야, 더 많은 설명을 해줘도 되. 수정 회수가 늘어나면 도움말의 내용을 줄여줘. 스스로 해당 장르의 글을 연습하는 과정에 도움을 줘.  1. 키위 AI 평가 결과를 활용하여, 현재 글의 강점과 약점을 분석해 줘. 2. 무조건 긍정적인 칭찬은 삼가고 학생이 직접 개선할 수 있도록 구체적인 수정 방향을 제시해 줘.  3. 어떤 부분을 어떻게 수정하면 더 좋은 글이 될지, 실질적인 조언을 해 줘. 피드백 형식 (세 문단, 150자 내외) 첫 문단에서는 학생의 글에서 수정해야 할 주요 부분을 알려 줘. 두 번째 문단에서는 수정 방법을 예시와 함께 제시해 줘. 학생이 어떤 부분을 어떻게 바꾸면 더 좋은 글이 되는지 예시를 포함해 줘. 세번째 문단에서는 글에 사용되었으면 좋을 어휘와 이유를 설명해줘. 점수가 이미 90점 이상이라면 어휘추천 대신에 조직과 주제의 점수를 높이기 위한 전략을 제시해줘.  학생글의 수준에 맞는 피드백을 주고, 글을 개선할 수 있도록 구체적인 가이드라인을 제공해 줘. 두 문단, 100자 내외로 짧지만 강렬하게 작성해 줘.  첫 문단에서는 학생의 글에서 좋았던 점을 구체적으로 칭찬해 줘.  두 번째 문단에서는 점수를 올릴 수 있는 현실적인 힌트를 줘.  "이 부분을 살짝 다듬으면 한 단계 더 업그레이드될 거예요!"  이런 식으로 긍정적이고 친근하게 조언해 줘.  학생의 수준에 맞춘 쉬운 어휘를 사용하고, 항상 존댓말로 말해 줘. 노력하라는 말 대신에 잘할 수 있는 방향을 제시해줘. 그리고 이모티콘은 사용하지 말아줘. ',
        );

        if (
          gptResponse?.status === 200 &&
          gptResponse?.data?.ret_code === 1000
        ) {
          setToast3Text(gptResponse.data.text.replace('\n\n', '\n'));
          Toast3Show(gptResponse.data.text, 2000, '1.2rem');
          setKeewiComment(gptResponse.data.text.replace(/\n{2,}/g, '\n'));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  function setAnalysisInfoFromKeewiApiResult(res, anlayzedText) {
    const keewiScore = {
      겹치는단어비율: NumberUtils.getAnalysisNumber(
        res.data.eval_result.겹치는단어비율 * 100,
      ),
      단어의평균길이: NumberUtils.getAnalysisNumber(
        res.data.eval_result.단어의평균길이,
      ),
      문단별문장수: NumberUtils.getAnalysisNumber(
        res.data.eval_result.문단별문장수,
      ),
      문장별단어수: NumberUtils.getAnalysisNumber(
        res.data.eval_result.문장별단어수,
      ),
      분석오류문장수: res.data.eval_result.분석오류문장수,
      // 전체글자수: textLength,
      전체글자수: res.data.eval_result.전체글자수,
      전체문장수: res.data.eval_result.전체문장수,
      전체문단수: res.data.eval_result.전체문단수,
      흐름이자연스러운정도: NumberUtils.getAnalysisNumber(
        res.data.eval_result.흐름이자연스러운정도 * 100,
      ),
      긴문장의수: res.data.eval_result.긴문장의수 ?? 0,
      독자나이: res.data.eval_result.독자나이 ?? 10,
    };
    setKeewiScore(keewiScore);

    handleScoreEvaluation(prevScore, res.data.eval_result);
    setCurrentEvalTry(currentEvalTry + 1);
    setPrevScore(res.data.eval_result.keewi_score);

    const wholeComment = [
      ...res.data.eval_result.trait_6_comment,
      ...res.data.eval_result.trait_5_comment,
      ...res.data.eval_result.trait_4_comment,
      ...res.data.eval_result.trait_3_comment,
      ...res.data.eval_result.trait_2_comment,
      ...res.data.eval_result.trait_1_comment,
    ];
    setHighlightWords(AnalysisText.createHighlightWordList(wholeComment));
    setCommentParagraph(
      AnalysisText.createComment(
        anlayzedText,
        res.data.eval_result.paragraph_texts,
        wholeComment,
      ),
    );
    setLabelText(AnalysisText.createHtmlTextLabel(res.data.eval_result.type));
    setAnalyText(
      AnalysisText.createHtmlTextAnaly(
        res.data.eval_result.paragraph_texts,
        res.data.eval_result.type,
      ),
    );
    setTScore(NumberUtils.getAnalysisNumber(res.data.eval_result.keewi_score));
    setIsGPT(res.data.eval_result.is_gpt_text);

    let datasets = [];
    datasets = [
      {
        data: [
          res.data.eval_result.trait_1_score,
          res.data.eval_result.trait_2_score,
          res.data.eval_result.trait_3_score,
          res.data.eval_result.trait_4_score,
          res.data.eval_result.trait_5_score,
          res.data.eval_result.trait_6_score,
        ],
        fill: true,
        backgroundColor: ({chart: {ctx}}) => {
          const bg = ctx.createLinearGradient(90, 90, 200, 200);
          bg.addColorStop(0, 'rgba(178, 223, 51, 0.5)');
          bg.addColorStop(1, 'rgba(255, 153, 0, 0.5)');
          return bg;
        },
        borderColor: '#FF9900',
        borderWidth: 1,
        pointStyle: 'circle',
        pointBackgroundColor: 'rgba(255, 138, 0, 1)',
        pointBorderColor: 'rgba(255, 255, 255, 0)',
      },
    ];
    const newRadarData = {
      labels: ['문법', '어휘', '표현', '문장', '조직', '주제'],
      datasets: datasets,
    };
    setRadarData(newRadarData);
    transformWordCloudData(res.data.eval_result.word_cloud); // 데이터를 변환하고 상태에 설정
  }

  function analyzeEvent(new_title, new_janre, new_text, new_practice_eval_id) {
    const trimmedText = StringUtils.getTrimmedBody(new_text);
    setLoading(true);
    setCurrentText(new_text);
    setTitleText(new_title);
    setWritingJanre(new_janre);
    const fetAnalasisResult = async (inputText, inputTitle, inputJanre) => {
      try {
        const response = await APIUtils.PracticeEval(
          authReducer.student_id,
          inputTitle,
          inputJanre,
          inputText,
        );
        return response;
      } catch (err) {
        console.log(err);
        setOnError(true);
      }
    };
    const saveResult = async result => {
      try {
        const response = await APIUtils.PracticeEvalSave(
          authReducer.student_id,
          result,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    const modifySaveResult = async result => {
      try {
        const response = await APIUtils.PracticeModifySave(
          authReducer.student_id,
          new_practice_eval_id,
          result,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    const keewiChatResult = async new_eval_id => {
      try {
        const response = await APIUtils.PracticeEvalKeewichat(
          authReducer.student_id,
          new_eval_id,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    fetAnalasisResult(trimmedText, new_title, new_janre)
      .then(res => {
        if (res.data.ret_code == 1000) {
          setAnalysisInfoFromKeewiApiResult(res, trimmedText);
          const jsonResult = AnalysisText.createSavingJsonFromEvalResult(
            res.data.eval_result,
            new_title,
            trimmedText,
            '성인',
            authReducer.student_name,
            1,
          );
          if (new_practice_eval_id == undefined || new_practice_eval_id == 0) {
            return saveResult(jsonResult);
          } else {
            modifySaveResult(jsonResult);
          }
        }
        return;
      })
      .then(re => {
        if (re == undefined) {
          setLoading(false);
          return;
        }
        if (re.data.ret_code == 1000) {
          localStorage.removeItem('practiceTitle');
          localStorage.removeItem('practiceBody');
          localStorage.removeItem('practiceJanre');
          setPracticeEvalID(re.data.practice_eval_id);
          return keewiChatResult(re.data.practice_eval_id);
        }
        setLoading(false);
        return;
      })
      .then(r => {
        if (r == undefined) return;
        if (r.data.ret_code == 1000) {
          setChatUrl(r.data.keewichat_url);
        }
        setLoading(false);
      });
  }
  function createWholeStatisticsText() {
    let flowLevel = '어려움';
    if (keewiScore.흐름이자연스러운정도 >= 71) flowLevel = '쉬움';
    else if (keewiScore.흐름이자연스러운정도 >= 63) flowLevel = '보통';
    return (
      <Box style={{width: '90%', marginBottom: '3rem'}}>
        <Box>
          {createStatisticsText('전체 글자 수', keewiScore.전체글자수, '자')}
        </Box>
        <Box>
          {createStatisticsText('전체 문단 수', keewiScore.전체문단수, '개')}
        </Box>
        <Box>
          {createStatisticsText('전체 문장 수', keewiScore.전체문장수, '개')}
        </Box>
        <Box>
          {createStatisticsText(
            '문단별 문장 수',
            keewiScore.문단별문장수,
            '개',
          )}
        </Box>
        <Box>
          {createStatisticsText(
            '긴 문장(50자)의 수',
            keewiScore.긴문장의수,
            '개',
          )}
        </Box>
        <Box>
          {createStatisticsText('예상 독자 나이', keewiScore.독자나이, '세')}
        </Box>
        <Box>{createStatisticsText('읽기 쉬운 정도', flowLevel, '')}</Box>
      </Box>
    );
  }
  function createStatisticsText(title, score, rest) {
    return (
      <Box
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '0.4rem',
          fontSize: '1rem',
          fontWeight: '500',
        }}
      >
        <Box>{title}</Box>
        <Box>
          <span style={{color: '#f67f10'}}>{score}</span> {rest}
        </Box>
      </Box>
    );
  }
  function textCopy() {
    const text = currentText || ''; // currentText가 정의되지 않으면 빈 문자열 사용
    const title = titleText || '';
    const currentDate = new Date();
    const formattedDate =
      currentDate.getFullYear() +
      '년 ' +
      String(currentDate.getMonth() + 1).padStart(2, '0') +
      '월 ' +
      String(currentDate.getDate()).padStart(2, '0') +
      '일';
    const copyText =
      '날짜 : ' +
      formattedDate +
      '\n제목 : ' +
      title +
      '\n본문 : \n' +
      text +
      '\n';
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(copyText)
        .then(() => {
          alert('글이 복사되었습니다.'); // props 대신 alert로 결과 알림
        })
        .catch(() => {
          alert('복사를 다시 시도해주세요.');
        });
    } else {
      // 대체 흐름: execCommand 사용
      if (!document.queryCommandSupported('copy')) {
        alert('복사하기가 지원되지 않는 브라우저입니다.');
        return;
      }
      const textarea = document.createElement('textarea');
      textarea.value = copyText;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
        alert('글이 복사되었습니다.');
      } catch (err) {
        alert('복사를 다시 시도해주세요.');
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }
  function showSaveOverlay() {
    if (bottomOverlay) {
      return (
        <Box
          style={{
            position: 'fixed',
            bottom: '0',
            top: '0',
            right: '2rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            zIndex: 3,
          }}
        >
          <IconButton
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#279EFF',
              border: 'solid 2px #279EFF',
              width: '5rem',
              height: '5rem',
              color: 'white',
              fontSize: '0.8rem',
            }}
            onClick={() => {
              setBottomOverlay(false);
            }}
          >
            <img src={PencilIcon2} alt="PencilIcon2" />
            수정하기
          </IconButton>
          <IconButton
            style={{
              width: '5rem',
              height: '5rem',
              borderRadius: '50%',
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#FF9900',
              color: 'white',
              fontSize: '0.8rem',
              fontWeight: 'bold',
              marginTop: '1rem',
            }}
            onClick={() => {
              textCopy();
            }}
          >
            <img
              src={CopyIcon}
              alt="CopyIcon"
              style={{
                width: '1.2rem',
                height: '1.2rem',
              }}
            />
            복사하기
          </IconButton>
          <IconButton
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#00C500',
              border: 'solid 2px #00C500',
              width: '5rem',
              height: '5rem',
              color: 'white',
              fontSize: '0.8rem',
              marginTop: '1rem',
            }}
            onClick={() => {
              window.open(chatUrl);
            }}
          >
            <img src={ChatIcon} alt="ChatIcon" />
            키위챗과 대화
          </IconButton>
        </Box>
      );
    }
    return (
      <EssayEditor
        currentText={currentText}
        analyzeEvent={bodyText => {
          window.localStorage.setItem('title', titleText);
          window.localStorage.setItem('text', bodyText);
          window.localStorage.setItem('janre', writingJanre);
          location.state.title = titleText;
          location.state.text = bodyText;
          location.state.writing_janre = writingJanre;
          analyzeEvent(titleText, writingJanre, bodyText, practiceEvalID);
          setBottomOverlay(true);
        }}
        cancelEvent={() => {
          setBottomOverlay(true);
        }}
        highlightWordList={highlightWords}
        currentTryCount={currentTryCount}
        maxTryLimit={maxTryLimit}
      />
    );
  }
  function showGPTValidation() {
    if (isGPT) {
      return (
        <Box
          style={{
            width: '100%',
            backgroundColor: '#f5d7da',
            color: '#bb0000',
            fontSize: '1rem',
            padding: '0.5rem',
            textAlign: 'center',
          }}
        >
          ⚠ GPT로 생성한 글일 가능성이 높습니다.
        </Box>
      );
    }
  }
  function showFooter() {
    if (bottomOverlay) {
      return <FooterView />;
    }
  }
  const throttledScroll = useMemo(
    () =>
      throttle(() => {
        if (window.scrollY > 500) {
          setIsScrollTop(false);
        } else {
          setIsScrollTop(true);
        }
      }, 1),
    [isScrollTop],
  );
  useEffect(() => {
    window.addEventListener('scroll', throttledScroll);
    return () => {
      window.removeEventListener('scroll', throttledScroll); //clean up
    };
  }, [throttledScroll]);
  useEffect(() => {
    let savedText = '';
    let savedTitle = '';
    let savedJanre = '';
    if (location.state == null && window.localStorage.getItem('text') == null) {
      navigate('/', {replace: true});
      return;
    } else if (window.localStorage.getItem('text') != null) {
      savedTitle = window.localStorage.getItem('title');
      savedText = window.localStorage.getItem('text');
      savedJanre = window.localStorage.getItem('janre');
    } else {
      savedTitle = location.state.title;
      savedText = location.state.text;
      savedJanre = location.state.writing_janre;
      window.localStorage.setItem('title', location.state.title);
      window.localStorage.setItem('text', location.state.text);
      window.localStorage.setItem('janre', location.state.writing_janre);
    }
    analyzeEvent(savedTitle, savedJanre, savedText, 0);
  }, []);
  // if (onError) return <ServerError />;
  if (loading) return <ApiLoading loading={loading} />;
  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box>
        <ToastPopup2
          text={toast3Text}
          visibility={toast3Visibility}
          fontSizeStr={toast3FontSizeText}
        />
        <ToastPopup2
          text={toast2Text}
          visibility={toast2Visibility}
          fontSizeStr={toast2FontSizeText}
        />
        <ToastPopup text={toastText} visibility={toastVisibility} />
      </Box>

      <HeaderView />
      <TitleComponent
        text1="AI 글쓰기 연습"
        text2="키위챗과 함께 글쓰기를 연습합니다."
      />
      <Box style={{marginTop: '5rem'}}></Box>
      {showGPTValidation()}
      <MarginBoxComponent>
        <>
          <Box
            style={{
              borderBottom: '1px solid #7d7d7d',
              marginTop: '2rem',
              marginBottom: '3rem',
              paddingBottom: '1rem',
            }}
          >
            <SingleLineComponent
              stepNumber="02"
              text={'AI 피드백을 참고하여 글을 수정하세요.'}
            />
            <SingleLineTitle
              title={titleText}
              tScore={tScore}
              handleInput={handleTitle}
              editable={titleEditable}
              changeEditStatus={status => {
                setTitleEditable(status);
              }}
            />
          </Box>

          <Grid container spacing={'1rem'}>
            <Grid
              item
              xs={8}
              style={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1, // 자동으로 남는 공간을 차지
                maxHeight: 'auto', // 높이를 자동 조정
              }}
            >
              <Box
                style={{
                  fontSize: '1.375rem',
                  fontWeight: '700',
                  marginBottom: '1.5rem',
                }}
              >
                내용 분석
              </Box>
              <WhiteBox>
                <>
                  {labelText}
                  {analyText}
                </>
              </WhiteBox>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                style={{
                  fontSize: '1.375rem',
                  fontWeight: '700',
                  marginBottom: '1.5rem',
                }}
              >
                글 통계 자료
              </Box>
              <WhiteBox>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'start',
                    height: '100%',
                  }}
                >
                  {createWholeStatisticsText()}
                  <Box
                    style={{
                      width: '100%',
                      minHeight: '20rem',
                      backgroundColor: '#F7F8FA',
                      borderRadius: '1.25rem',
                      padding: '1.25rem 0rem',
                      marginBottom: '2rem', // 간격 추가
                    }}
                  >
                    <RadarChart radarData={radarData} />
                  </Box>
                  <Box
                    style={{
                      width: '100%',
                      minHeight: '20rem',
                      backgroundColor: '#F7F8FA',
                      borderRadius: '1.25rem',
                      padding: '1.25rem 0rem',
                      alignItems: 'center',
                      justifyContent: 'center', // 중앙 정렬
                    }}
                  >
                    {memoizedWordCloud}
                  </Box>
                </Box>
              </WhiteBox>
            </Grid>
          </Grid>
          <Box
            style={{
              display: keewiComment !== '' ? 'flex' : 'none',
              flexDirection: 'row', // 가로 정렬
              alignItems: 'center', // 세로 중앙 정렬
              gap: '1rem', // 이미지와 텍스트 사이 간격
              marginTop: '3rem',
              width: '90%',
              backgroundColor: 'white',
              borderRadius: '1.25rem',
              border: '1px solid var(--01-gray-07, #E9EBED)',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              paddingLeft: '5%',
              paddingRight: '5%',
              paddingTop: '1rem',
              paddingBottom: '1rem',
            }}
          >
            {/* 이미지 */}
            <img
              src={KeewiBird}
              alt="Keewi Bird"
              style={{
                width: '2rem',
                height: '3rem',
              }}
            />

            {/* 텍스트 */}
            <Box
              style={{
                wordBreak: 'break-word', // break-all 대신 break-word가 더 자연스러움
                fontSize: '1rem',
                fontWeight: '500',
                color: 'rgba(48, 48, 48, 1)',
                whiteSpace: 'pre-wrap',
              }}
            >
              {keewiComment.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem',
              marginTop: '3rem',
            }}
          >
            {commentParagraph.map((paragraph, paragraphIndex) => {
              if (paragraph.length <= 2) {
                return;
              }
              if (paragraph.length == 3 && paragraphIndex == 1) {
                return;
              }
              return paragraph.map((value, idx) => {
                return (
                  <CommentItem
                    key={'commentbox' + paragraphIndex + '_' + idx}
                    paragraphIndex={paragraphIndex}
                    idx={idx}
                    commentID={value.commentID}
                    type={value.type}
                    A={value.A}
                    B={value.B}
                    C={value.C}
                    subtype={value.subtype}
                    text={value.text}
                  />
                );
              });
            })}
          </Box>
        </>
      </MarginBoxComponent>
      {showSaveOverlay()}
      {showFooter()}
    </Box>
  );
};
export default MainPage;
