import {Box, Button, TextField} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import APIUtils from 'common/utils/APIUtils';
import PropTypes from 'prop-types';
import WritingView from './WritingView1';
import Pagination from 'view/mypage/Pagination';
import ApiLoading from 'view/common/ApiLoading2';

export const FragmentView = props => {
  const authReducer = useSelector(state => state.authReducer);
  const [tabStatus, setTabStatus] = useState(1);
  const [loading, setLoading] = useState(true);

  const [originalWritingList, setOriginalWritingList] = useState([]);
  const [filteredWritingList, setFilteredWritingList] = useState([]);

  const [historyStatus, setHistoryStatus] = useState(0);
  const [searchText, setSearchText] = useState('');
  const handleSearchText = event => {
    let inputVal = event.target.value;
    if (inputVal.length > 50) {
      inputVal = inputVal.substring(0, 50);
    }
    setSearchText(inputVal);
    getFilteredTask(historyStatus, inputVal);
  };

  const countPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  function paginationClickEvent(page) {
    setCurrentPage(page);
  }

  function getTaskList() {
    const taskList = async () => {
      try {
        const response = await APIUtils.TaskHistoryView(authReducer.student_id);
        if (response.status == 200 && response.data.ret_code == 1000) {
          setOriginalWritingList(response.data.writing_list);
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    taskList();
  }
  function getFilteredTask(state, searchValue) {
    if (originalWritingList == undefined || originalWritingList.length == 0) {
      setFilteredWritingList([]);
      return;
    }
    let filtered = [...originalWritingList];
    if (searchValue != undefined && searchValue.trim() != '') {
      filtered = filtered.filter(item => {
        return (
          (item.teacher_name != undefined &&
            item.teacher_name.includes(searchValue)) ||
          (item.title != undefined && item.title.includes(searchValue))
        );
      });
    } else if (state == 1) {
      const today = new Date();
      const date = new Date(today);
      date.setMonth(date.getMonth() - 1);
      filtered = filtered.filter(item => new Date(item.eval_time) - date >= 0);
    } else if (state == 2) {
      const today = new Date();
      const date = new Date(today);
      date.setMonth(date.getMonth() - 3);
      filtered = filtered.filter(item => new Date(item.eval_time) - date >= 0);
    } else if (state == 3) {
      const today = new Date();
      const date = new Date(today);
      date.setMonth(date.getMonth() - 6);
      filtered = filtered.filter(item => new Date(item.eval_time) - date >= 0);
    } else if (state == 4) {
      const today = new Date();
      const date = new Date(today);
      date.setMonth(date.getMonth() - 12);
      filtered = filtered.filter(item => new Date(item.eval_time) - date >= 0);
    }

    filtered.sort((a, b) => {
      return new Date(b.eval_time) - new Date(a.eval_time);
    });
    setFilteredWritingList(filtered);
    setTotalPage(Math.floor((filtered.length - 1) / countPerPage + 1));
  }
  useEffect(() => {
    getTaskList();
  }, []);
  useEffect(() => {
    getFilteredTask();
  }, [originalWritingList, tabStatus]);
  if (loading) {
    return <ApiLoading />;
  }
  return (
    <Box style={{width: '100%', maxWidth: '120rem'}}>
      <Box style={{fontWeight: 'bold', fontSize: '1.375rem'}}>제출한 과제</Box>
      <Box
        style={{
          backgroundColor: '#E6E8EB',
          width: '100%',
          height: '2px',
          marginTop: '1.3rem',
          marginBottom: '1.75rem',
        }}
      />
      <Box
        style={{
          marginTop: '4rem',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Button
            style={{
              width: '4rem',
              height: '2rem',
              borderRadius: '0px',
              backgroundColor: 'white',
              color: historyStatus == 1 ? 'black' : '#636c73',
              fontWeight: historyStatus == 1 ? 'bold' : 'normal',
              border: '3px solid #f9f9f9',
              boxShadow: '0px 0px 10px 3px #f9f9f9',
              fontSize: '0.75rem',
            }}
            onClick={() => {
              setHistoryStatus(1);
              getFilteredTask(1, searchText);
            }}
          >
            1개월
          </Button>
          <Button
            style={{
              marginLeft: '3px',
              width: '4rem',
              height: '2rem',
              borderRadius: '0px',
              backgroundColor: 'white',
              color: historyStatus == 2 ? 'black' : '#636c73',
              fontWeight: historyStatus == 2 ? 'bold' : 'normal',
              border: '3px solid #f9f9f9',
              boxShadow: '0px 0px 10px 3px #f9f9f9',
              fontSize: '0.75rem',
            }}
            onClick={() => {
              setHistoryStatus(2);
              getFilteredTask(2, searchText);
            }}
          >
            3개월
          </Button>
          <Button
            style={{
              marginLeft: '3px',
              width: '4rem',
              height: '2rem',
              borderRadius: '0px',
              backgroundColor: 'white',
              color: historyStatus == 3 ? 'black' : '#636c73',
              fontWeight: historyStatus == 3 ? 'bold' : 'normal',
              border: '3px solid #f9f9f9',
              boxShadow: '0px 0px 10px 3px #f9f9f9',
              fontSize: '0.75rem',
            }}
            onClick={() => {
              setHistoryStatus(3);
              getFilteredTask(3, searchText);
            }}
          >
            6개월
          </Button>
          <Button
            style={{
              marginLeft: '3px',
              width: '4rem',
              height: '2rem',
              borderRadius: '0px',
              backgroundColor: 'white',
              color: historyStatus == 4 ? 'black' : '#636c73',
              fontWeight: historyStatus == 4 ? 'bold' : 'normal',
              border: '3px solid #f9f9f9',
              boxShadow: '0px 0px 10px 3px #f9f9f9',
              fontSize: '0.75rem',
            }}
            onClick={() => {
              setHistoryStatus(4);
              getFilteredTask(4, searchText);
            }}
          >
            1년
          </Button>
        </Box>
        <Box>
          <TextField
            autoComplete="off"
            size="small"
            variant="outlined"
            sx={{
              backgroundColor: 'white',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid black',
                },
                '&:hover fieldset': {
                  border: '1px solid black',
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid black',
                },
              },
            }}
            label={searchText == '' ? '🔍 검색(제목/이름)' : ''}
            InputLabelProps={{
              style: {
                color: 'rgba(108, 108, 108, 0.721)',
                fontSize: 'calc(0.3rem + 0.5vw)',
              },
              shrink: false,
            }}
            InputProps={{style: {fontSize: 'calc(0.3rem + 0.5vw)'}}}
            value={searchText}
            onChange={handleSearchText}
          ></TextField>
        </Box>
      </Box>

      <Box style={{marginBottom: '2rem'}}>
        {filteredWritingList
          .filter((value, index) => {
            if (Math.floor(index / countPerPage) + 1 == currentPage) {
              return true;
            }
            return false;
          })
          .map((val, idx) => {
            return <WritingView key={'writingview' + idx} writing={val} />;
          })}
      </Box>
      <Pagination
        totalPage={totalPage}
        currentPage={currentPage}
        clickEvent={paginationClickEvent}
      />
    </Box>
  );
};
FragmentView.propTypes = {
  user_id: PropTypes.number,
};
export default FragmentView;
