import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React from 'react';

export const PeerEvalView = props => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1rem',
        margin: '0.5rem 0',
        border: '1px solid #B1B1B1',
        borderRadius: '8px',
        backgroundColor:
          props.my_id == props.submitTask.student_id ? '#E6FAE6' : 'white',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        flex: '1 1 calc(30% - 1rem)', // 부모의 가로폭에 따라 카드 크기 조정
        maxWidth: 'calc(30% - 1rem)', // 한 줄에 3개씩 표시
        minWidth: '15rem', // 최소 크기 제한
      }}
    >
      {/* 왼쪽 콘텐츠 */}
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
        }}
      >
        <Box
          style={{
            color: '#898989',
            fontSize: '0.8rem',
            fontWeight: 'bold',
          }}
        >
          {props.submitTask.student_name}
        </Box>
        <Box
          style={{
            color: '#262C31',
            fontSize: '1rem',
            fontWeight: 'bold',
          }}
        >
          {props.submitTask.title}
        </Box>
      </Box>

      {/* 오른쪽 버튼 */}
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <Button
          sx={{
            color: '#00C500',
            fontSize: '0.875rem',
            border: '1px solid #00C500',
            borderRadius: '1.925rem',
            padding: '0.5rem 1rem',
            backgroundColor: 'white',
            whiteSpace: 'nowrap',
            cursor: 'pointer',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
            '&:hover': {
              backgroundColor: '#E8FBF7',
            },
          }}
          onClick={() => {
            props.resultEvent(
              props.submitTask.student_id,
              props.submitTask.task_id,
            );
          }}
        >
          {props.my_id == props.submitTask.student_id ? '보기' : '평가하기'}
        </Button>
      </Box>
    </Box>
  );
};

PeerEvalView.propTypes = {
  index: PropTypes.number,
  submitTask: PropTypes.object,
  my_id: PropTypes.number,
  resultEvent: PropTypes.func,
};

export default PeerEvalView;
