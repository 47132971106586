import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import {StringUtils} from 'common/utils/StringUtils';
import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';

export const TaskView = props => {
  const navigate = useNavigate();
  function showButton() {
    return (
      <Box style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
        <Button
          style={{
            display: props.writing.pdf_url != '' ? '' : 'none',
            color: '#279EFF',
            fontSize: '0.875rem',
            border: '1px solid #279EFF',
            borderRadius: '1.625rem',
            padding: '0.7rem 0rem',
            width: '6rem',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          onClick={() => {
            window.open(props.writing.pdf_url);
          }}
        >
          {props.writing.pdf_url == '' ? '' : '평가 리포트'}
        </Button>
        <Button
          style={{
            display: props.writing.keewichat_url == '' ? 'none' : '',
            color: '#00C500',
            fontSize: '0.875rem',
            border: '1px solid #00C500',
            borderRadius: '1.625rem',
            padding: '0.7rem 0rem',
            width: '6rem',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          onClick={() => {
            navigate('/result/saved', {
              state: {
                task_id: props.writing.task_id,
                eval_try: props.writing.eval_try,
              },
            });
          }}
        >
          저장한 글
        </Button>
        <Button
          disabled={!props.writing.is_submit}
          style={{
            display: props.writing.is_submit == 1 ? '' : 'none',
            color: '#FF9900',
            fontSize: '0.875rem',
            border: '1px solid #FF9900',
            borderRadius: '1.625rem',
            padding: '0.7rem 0rem',
            width: '6rem',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          onClick={() => {
            navigate('/result/submit', {
              state: {
                task_id: props.writing.task_id,
              },
            });
          }}
        >
          제출한 글
        </Button>
      </Box>
    );
  }
  return (
    <Box
      style={{
        display: 'flex',
        marginTop: '1.5rem',
        paddingBottom: '0.5rem',
        borderBottom: '2px solid #A8B1B7',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.75rem',
          flex: 1,
        }}
      >
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          {StringUtils.getTimeStringKr(props.writing.eval_time)}
        </Box>
        <Box style={{fontSize: '1.125rem', fontWeight: 'bold'}}>
          {props.writing.title}
        </Box>
        <Box>
          {props.writing.teacher_name +
            ' 선생님 | ' +
            props.writing.task_janre +
            ' | ' +
            props.writing.task_name}
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        {showButton()}
      </Box>
    </Box>
  );
};
TaskView.propTypes = {
  writing: PropTypes.object,
};
export default TaskView;
