import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  FormControl,
  Button,
  TextField,
  IconButton,
  Tooltip,
} from '@mui/material';
import React from 'react';
import {Palette} from 'common/utils/ColorUtils';
import {useNavigate} from 'react-router-dom';
import checkMark from 'asset/image/icon_check.png';
import bullet from 'asset/image/icon_snow.png';
import pencil from 'asset/image/icon_pencil.png';
import backgroundMain3 from 'asset/imageV2/background_main3.svg';
import backgroundMain2 from 'asset/imageV2/background_main2.png';
import PencilIcon3 from 'asset/imageV2/icon_pencil3.svg';
import Linkify from 'linkify-react';
import ArrowGreen1 from 'asset/imageV2/icon_green_arrow1.svg';
import ArrowGreen2 from 'asset/imageV2/icon_green_arrow2.svg';
import ArrowGrey1 from 'asset/imageV2/icon_grey_arrow1.svg';
import {StringUtils} from 'common/utils/StringUtils';
import {useState} from 'react';
import iconArrowDown from 'asset/image/icon_arrow_down.png';

export const MarginBoxComponent = props => {
  return (
    <Box
      style={{
        marginBottom: '5%',
        marginTop: '2%',
        width: '85%',
        maxWidth: '200rem',
      }}
    >
      {props.children}
    </Box>
  );
};
MarginBoxComponent.propTypes = {
  children: PropTypes.element,
};

export const TitleComponent = props => {
  const navigate = useNavigate();
  function goBackHome() {
    navigate('/mypage');
  }
  return (
    <Box
      style={{
        width: '100%',
        backgroundColor: '#ECFFE9',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingTop: '2rem',
        paddingBottom: '4rem',
        marginBottom: '-4rem',
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          position: 'relative',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            flex: 1,
          }}
        >
          <Box
            style={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
              backgroundColor: 'white',
              border: '3px solid #00C500',
              padding: '0.5rem 2rem',
              borderRadius: '5.5rem',
            }}
          >
            {props.text1}
          </Box>
          <Box
            style={{
              textAlign: 'center',
              color: '#68737D',
              marginTop: '1rem',
              marginBottom: '3rem',
            }}
          >
            {props.text2}
          </Box>
        </Box>
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            right: '0%',
            transform: 'translateY(-50%)',
            width: 'auto',
            height: 'auto',
            zIndex: 10,
          }}
        >
          <Button
            disabled={false}
            style={{
              color: '#00C500',
              backgroundColor: '#F6F6F6',
              fontSize: '0.875rem',
              border: '1px solid #00C500',
              borderRadius: '1.625rem',
              padding: '0.7rem 0rem',
              marginTop: '-1rem',
              marginRight: '6rem',
              width: '7rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => goBackHome()}
          >
            돌아가기
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
TitleComponent.propTypes = {
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
};

const renderTextWithYoutubeAndLinks = text => {
  const youtubeRegex =
    /(https?:\/\/(?:www\.)?(youtube\.com\/watch\?v=([\w-]+)|youtu\.be\/([\w-]+)|youtube\.com\/shorts\/([\w-]+)))/g;

  const urlRegex = /(https?:\/\/[^\s]+)/g; // 🔹 일반 URL 감지 (링크 자동 변환)
  const videoIds = [];

  const cleanTaskMessage = message => {
    const lines = message.split('\n');

    // 🔹 문제지 파일명 추출 (숨김 처리)
    const filename =
      lines
        .find(line => line.replace(/\s+/g, '').startsWith('###문제지:'))
        ?.replace(/###\s*문제지\s*:\s*/, '') || 'XXXX';

    // 🔹 문제지 링크 생성
    const problemSheetLink =
      filename !== 'XXXX' ? (
        <div style={{marginBottom: '1rem'}}>
          <a
            href={`https://keewichat.s3.ap-northeast-2.amazonaws.com/essay/exam/${filename}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontSize: '1.2rem',
              textDecoration: 'none',
              fontWeight: 'bold',
              color: '#00C500',
            }}
          >
            📄 문제지 보기
          </a>
        </div>
      ) : null;

    // 🔹 "###"으로 시작하는 모든 줄 제거
    const filteredMessage = lines.filter(
      line => !line.trim().startsWith('###'),
    );

    return {filteredMessage, problemSheetLink};
  };

  const {filteredMessage, problemSheetLink} = cleanTaskMessage(text);

  return (
    <div>
      {problemSheetLink}
      {filteredMessage.map((line, lineIndex) => {
        if (!line) return <br key={lineIndex} />;

        // 🔹 유튜브 링크 감지 및 변환
        if (youtubeRegex.test(line)) {
          const videoPart = line.match(youtubeRegex);
          if (!videoPart)
            return (
              <span key={lineIndex}>
                {line}
                <br />
              </span>
            );

          let videoId = '';
          const videoUrl = videoPart[0];

          try {
            if (videoUrl.includes('youtu.be')) {
              videoId = videoUrl.split('/').pop().split('?')[0];
            } else if (videoUrl.includes('youtube.com/watch?v=')) {
              videoId = new URL(videoUrl).searchParams.get('v');
            } else if (videoUrl.includes('youtube.com/shorts/')) {
              videoId = videoUrl.split('/').pop().split('?')[0];
            }

            // 🔹 중복된 비디오 ID 제거
            if (!videoId || videoIds.includes(videoId))
              return (
                <span key={lineIndex}>
                  {line}
                  <br />
                </span>
              );
            videoIds.push(videoId);

            return (
              <div
                key={lineIndex}
                style={{margin: '1rem 0', textAlign: 'center'}}
              >
                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${videoId}`}
                  title="YouTube video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    borderRadius: '8px',
                    display: 'inline-block',
                  }}
                ></iframe>
              </div>
            );
          } catch (error) {
            return (
              <span key={lineIndex}>
                {line}
                <br />
              </span>
            );
          }
        }

        // 🔹 일반 웹사이트 링크 감지 및 변환
        const parts = line.split(urlRegex);
        const linkedText = parts.map((part, index) => {
          if (urlRegex.test(part)) {
            return (
              <a
                key={index}
                href={part}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: '#007AFF',
                  textDecoration: 'none',
                  fontWeight: 'bold',
                }}
              >
                {part}
              </a>
            );
          }
          return part;
        });

        return (
          <span key={lineIndex}>
            {linkedText}
            <br />
          </span>
        );
      })}
    </div>
  );
};

const TaskMessage = ({message}) => {
  return <div>{renderTextWithYoutubeAndLinks(message)}</div>;
};
TaskMessage.propTypes = {
  message: PropTypes.string.isRequired, // ✅ message prop 검증 추가
};

const RenderTextWithYoutubeAndLinksAndAnswer = text => {
  const [answerText, setAnswerText] = useState('');
  const [answerShow, setAnswerShow] = useState(false);
  const handleAnswerShow = () => {
    setAnswerShow(prev => !prev);
  };

  const youtubeRegex =
    /(https?:\/\/(?:www\.)?(youtube\.com\/watch\?v=([\w-]+)|youtu\.be\/([\w-]+)|youtube\.com\/shorts\/([\w-]+)))/g;

  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const videoIds = [];

  const cleanTaskMessage = message => {
    const lines = message.split('\n');

    const filename =
      lines
        .find(line => line.replace(/\s+/g, '').startsWith('###문제지:'))
        ?.replace(/###\s*문제지\s*:\s*/, '') || 'XXXX';

    let answerfn =
      lines
        .find(line => line.replace(/\s+/g, '').startsWith('###모범답안:'))
        ?.replace(/###\s*모범답안\s*:\s*/, '') || 'XXXX';

    if (answerfn !== 'XXXX') {
      const fetchAnswerText = async () => {
        try {
          const response = await fetch(
            `https://keewichat.s3.ap-northeast-2.amazonaws.com/essay/answer/${answerfn}`,
            {
              cache: 'no-store',
            },
          );

          // ✅ 파일 존재 여부 확인
          if (!response.ok) {
            throw new Error(`파일이 존재하지 않음: ${response.status}`);
          }

          const buffer = await response.arrayBuffer();
          const decoder = new TextDecoder('euc-kr');
          const text = decoder.decode(buffer).replace(/\r\n|\r/g, '\n');
          setAnswerText(text);
        } catch (err) {
          setAnswerText('');
          answerfn = 'XXXX';
        }
      };

      fetchAnswerText();
    }

    const problemSheetLink =
      filename !== 'XXXX' ? (
        <div style={{marginBottom: '1rem'}}>
          <a
            href={`https://keewichat.s3.ap-northeast-2.amazonaws.com/essay/exam/${filename}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontSize: '1.2rem',
              textDecoration: 'none',
              fontWeight: 'bold',
              color: '#00C500',
            }}
          >
            📄 문제지 보기
          </a>
        </div>
      ) : null;

    const AnswerPart =
      answerfn !== 'XXXX' ? (
        <div
          style={{display: answerText ? 'block' : 'none', marginBottom: '1rem'}}
        >
          <Box style={{display: 'flex', alignItems: 'center'}}>
            <a
              href={`https://keewichat.s3.ap-northeast-2.amazonaws.com/essay/answer/${answerfn}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                fontSize: '1.2rem',
                textDecoration: 'none',
                fontWeight: 'bold',
                color: '#00C500',
              }}
            >
              📄 모범답안 보기
            </a>
            <Button
              style={{
                marginLeft: '0.3rem',
                width: '1.5rem',
                color: '#717171',
                fontWeight: 'bold',
              }}
              onClick={handleAnswerShow}
            >
              <img
                src={iconArrowDown}
                alt="toggle"
                style={{
                  width: '1.5rem',
                  height: '1.5rem',
                  transform: answerShow ? 'rotate(0deg)' : 'rotate(180deg)',
                  transition: 'transform 0.2s',
                }}
              />
            </Button>
          </Box>

          <Box style={{display: answerShow ? 'block' : 'none'}}>
            {answerText &&
            answerText === '모범답안을 불러오는 중 오류가 발생했습니다.' ? (
              <div style={{color: 'red', marginTop: '1rem'}}>{answerText}</div>
            ) : (
              <pre
                style={{
                  marginTop: '1rem',
                  whiteSpace: 'pre-wrap',
                  fontFamily: 'inherit',
                  fontSize: '1rem',
                  lineHeight: 1.5,
                  background: '#f6f6f6',
                  padding: '1rem',
                  borderRadius: '8px',
                }}
              >
                {answerText}
              </pre>
            )}
          </Box>
        </div>
      ) : null;

    const filteredMessage = lines.filter(
      line => !line.trim().startsWith('###'),
    );

    return {filteredMessage, problemSheetLink, AnswerPart};
  };

  const {filteredMessage, problemSheetLink, AnswerPart} =
    cleanTaskMessage(text);

  return (
    <div>
      {problemSheetLink}

      {filteredMessage.map((line, lineIndex) => {
        if (!line) return <br key={lineIndex} />;

        if (youtubeRegex.test(line)) {
          const videoPart = line.match(youtubeRegex);
          if (!videoPart)
            return (
              <span key={lineIndex}>
                {line}
                <br />
              </span>
            );

          let videoId = '';
          const videoUrl = videoPart[0];

          try {
            if (videoUrl.includes('youtu.be')) {
              videoId = videoUrl.split('/').pop().split('?')[0];
            } else if (videoUrl.includes('youtube.com/watch?v=')) {
              videoId = new URL(videoUrl).searchParams.get('v');
            } else if (videoUrl.includes('youtube.com/shorts/')) {
              videoId = videoUrl.split('/').pop().split('?')[0];
            }

            if (!videoId || videoIds.includes(videoId))
              return (
                <span key={lineIndex}>
                  {line}
                  <br />
                </span>
              );
            videoIds.push(videoId);

            return (
              <div
                key={lineIndex}
                style={{margin: '1rem 0', textAlign: 'center'}}
              >
                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${videoId}`}
                  title="YouTube video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    borderRadius: '8px',
                    display: 'inline-block',
                  }}
                ></iframe>
              </div>
            );
          } catch (error) {
            return (
              <span key={lineIndex}>
                {line}
                <br />
              </span>
            );
          }
        }

        const parts = line.split(urlRegex);
        const linkedText = parts.map((part, index) => {
          if (urlRegex.test(part)) {
            return (
              <a
                key={index}
                href={part}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: '#007AFF',
                  textDecoration: 'none',
                  fontWeight: 'bold',
                }}
              >
                {part}
              </a>
            );
          }
          return part;
        });

        return (
          <span key={lineIndex}>
            {linkedText}
            <br />
          </span>
        );
      })}
      {AnswerPart}
    </div>
  );
};

const TaskMessageSubmitted = ({message}) => {
  return <div>{RenderTextWithYoutubeAndLinksAndAnswer(message)}</div>;
};
TaskMessageSubmitted.propTypes = {
  message: PropTypes.string.isRequired, // ✅ message prop 검증 추가
};

export const TitleComponent2_1 = props => {
  const navigate = useNavigate();
  function goBackHome() {
    navigate('/mypage');
  }
  return (
    <Box
      style={{
        width: '100%',
        backgroundColor: '#ECFFE9',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingTop: '2rem',
        paddingBottom: '6rem',
        marginBottom: '-4rem',
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          position: 'relative',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            flex: 1,
          }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '0.5rem',
            }}
          >
            <Box
              style={{
                fontSize: '0.875rem',
                backgroundColor: props.dateLeftColor || 'gray',
                color: 'white',
                padding: '0.125rem 0.5rem',
                borderRadius: '0.31rem',
                marginRight: '1rem',
              }}
            >
              {props.dateLeftText}
            </Box>
            <Box style={{fontSize: '1rem', color: '#68737D'}}>
              {props.dateText}
            </Box>
          </Box>
          <Box
            style={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
            }}
          >
            {props.titleText}
          </Box>
        </Box>
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            right: '2rem',
            transform: 'translateY(-50%)',
            width: 'auto',
            height: 'auto',
            zIndex: 10,
          }}
        >
          <Button
            disabled={false}
            style={{
              color: '#00C500',
              backgroundColor: '#F6F6F6',
              fontSize: '0.875rem',
              border: '1px solid #00C500',
              borderRadius: '1.625rem',
              padding: '0.7rem 0rem',
              width: '7rem',
              marginTop: '-3rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => goBackHome()}
          >
            돌아가기
          </Button>
        </Box>
      </Box>

      <Box
        style={{
          width: '85%',
          maxWidth: '100rem',
          marginTop: '2rem',
          padding: '1rem',
          backgroundColor: 'white',
          color: '#4b4d4c',
          border: '2px solid #00C500',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-all',
        }}
      >
        <Linkify
          options={{
            target: '-blank',
          }}
        >
          <TaskMessage message={props.messageText} />
        </Linkify>

        <Box
          style={{
            display: 'block',
            marginLeft: '0rem',
            marginTop: '1.2rem',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
        >
          <Box
            style={{
              display: props.textCountChecked ? '' : 'none',
            }}
          >
            {'\u2022 필수 글자수 : ' +
              props.textCountMin +
              ' 자 ~ ' +
              props.textCountMax +
              ' 자'}
          </Box>
          <Box
            style={{
              display: props.requiredWordChecked ? '' : 'none',
            }}
          >
            {'\u2022 필수 키워드 : ' + props.requiredWord.replace(',', ', ')}
          </Box>
          <Box>
            {props.maxTryLimit !== 0 &&
              `• 최대 수정 횟수는 ${props.maxTryLimit}회입니다.`}
            {props.maxTryLimit !== 0 &&
              props.currentTryCount > 1 &&
              ` (수정 횟수 ${props.currentTryCount - 1}회)`}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
TitleComponent2_1.propTypes = {
  dateLeft: PropTypes.number,
  dateLeftText: PropTypes.string,
  dateLeftColor: PropTypes.string,
  dateText: PropTypes.string,
  titleText: PropTypes.string,
  messageText: PropTypes.string,
  textCountChecked: PropTypes.bool,
  textCountMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textCountMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  requiredWordChecked: PropTypes.bool,
  requiredWord: PropTypes.string,
  currentTryCount: PropTypes.number,
  maxTryLimit: PropTypes.number,
};
export const TitleComponent2_2 = props => {
  const navigate = useNavigate();
  function goBackHome() {
    navigate('/mypage');
  }
  return (
    <Box
      style={{
        width: '100%',
        backgroundColor: '#ECFFE9',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingTop: '2rem',
        paddingBottom: '2rem',
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          position: 'relative',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            flex: 1,
          }}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '0.5rem',
            }}
          >
            <Box
              style={{
                fontSize: '0.875rem',
                backgroundColor: props.dateLeftColor || 'gray',
                color: 'white',
                padding: '0.125rem 0.5rem',
                borderRadius: '0.31rem',
                marginRight: '1rem',
              }}
            >
              {props.dateLeftText}
            </Box>
            <Box style={{fontSize: '1rem', color: '#68737D'}}>
              {props.dateText}
            </Box>
          </Box>
          <Box
            style={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
            }}
          >
            {props.titleText}
          </Box>
        </Box>

        <Box
          style={{
            position: 'absolute',
            top: '50%',
            right: '2rem',
            transform: 'translateY(-50%)',
          }}
        >
          <Button
            style={{
              color: '#00C500',
              backgroundColor: '#F6F6F6',
              fontSize: '0.875rem',
              border: '1px solid #00C500',
              borderRadius: '1.625rem',
              padding: '0.7rem 0rem',
              width: '7rem',
              marginTop: '-3rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => goBackHome()}
          >
            돌아가기
          </Button>
        </Box>
      </Box>

      <Box
        style={{
          width: '85%',
          maxWidth: '100rem',
          marginTop: '2rem',
          padding: '1rem',
          backgroundColor: 'white',
          color: '#4b4d4c',
          border: '2px solid #00C500',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-all',
        }}
      >
        <Linkify
          options={{
            target: '-blank',
          }}
        >
          <TaskMessage message={props.messageText} />
        </Linkify>

        <Box
          style={{
            display: 'block',
            marginLeft: '0rem',
            marginTop: '1.2rem',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
        >
          <Box
            style={{
              display: props.textCountChecked ? '' : 'none',
            }}
          >
            {'\u2022 필수 글자수 : ' +
              props.textCountMin +
              ' 자 ~ ' +
              props.textCountMax +
              ' 자'}
          </Box>
          <Box
            style={{
              display: props.requiredWordChecked ? '' : 'none',
            }}
          >
            {'\u2022 필수 키워드 : ' + props.requiredWord.replace(',', ', ')}
          </Box>
          <Box>
            {props.maxTryLimit !== 0 &&
              `• 최대 수정 횟수는 ${props.maxTryLimit}회입니다.`}
            {props.maxTryLimit !== 0 &&
              props.currentTryCount > 1 &&
              ` (수정 ${props.currentTryCount - 1}회)`}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
TitleComponent2_2.propTypes = {
  dateLeft: PropTypes.number,
  dateLeftText: PropTypes.string,
  dateLeftColor: PropTypes.string,
  dateText: PropTypes.string,
  titleText: PropTypes.string,
  messageText: PropTypes.string,
  textCountChecked: PropTypes.bool,
  textCountMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textCountMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  requiredWordChecked: PropTypes.bool,
  requiredWord: PropTypes.string,
  currentTryCount: PropTypes.number,
  maxTryLimit: PropTypes.number,
};
export const TitleComponent3 = props => {
  const navigate = useNavigate();
  function goBackHome() {
    navigate('/mypage', {
      replace: true,
      state: {
        fragmentNum: 1,
      },
    });
  }
  return (
    <Box
      style={{
        width: '100%',
        backgroundColor: '#ECFFE9',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingTop: '3rem',
        paddingBottom: '5rem',
        marginBottom: '-5rem',
      }}
    >
      <Box style={{marginTop: '1rem', fontSize: '1.5rem', fontWeight: 'bold'}}>
        {props.titleText}
      </Box>
      <Box
        style={{
          position: 'absolute',
          top: '20rem',
          right: '5%',
          transform: 'translateY(-10rem)',
        }}
      >
        <Button
          style={{
            color: '#00C500',
            backgroundColor: '#F6F6F6',
            fontSize: '0.875rem',
            border: '1px solid #00C500',
            borderRadius: '1.625rem',
            padding: '0.7rem 0rem',
            width: '7rem',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          onClick={() => goBackHome()}
        >
          돌아가기
        </Button>
      </Box>
      <Box
        style={{
          width: '85%',
          maxWidth: '100rem',
          marginTop: '2rem',
          padding: '1rem',
          backgroundColor: 'white',
          color: '#4b4d4c',
          border: '2px solid #00C500',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-all',
        }}
      >
        <Linkify
          options={{
            target: '-blank',
          }}
        >
          <TaskMessage message={props.messageText} />
        </Linkify>

        <Box
          style={{
            display:
              props.textCountChecked || props.requiredWordChecked ? '' : 'none',
            marginLeft: '1rem',
            marginTop: '1rem',
            fontWeight: 'bold',
          }}
        >
          <Box
            style={{
              display: props.textCountChecked ? '' : 'none',
            }}
          >
            {'\u2022 필수 글자수 : ' +
              props.textCountMin +
              ' 자 ~ ' +
              props.textCountMax +
              ' 자'}
          </Box>
          <Box
            style={{
              display: props.requiredWordChecked ? '' : 'none',
            }}
          >
            {'\u2022 필수 키워드 : ' + props.requiredWord.replace(',', ', ')}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
TitleComponent3.propTypes = {
  dueText: PropTypes.string,
  dateText: PropTypes.string,
  titleText: PropTypes.string,
  messageText: PropTypes.string,
  textCountChecked: PropTypes.bool,
  textCountMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textCountMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  requiredWordChecked: PropTypes.bool,
  requiredWord: PropTypes.string,
  currentTryCount: PropTypes.number,
  maxTryLimit: PropTypes.number,
};
export const TitleComponent4 = props => {
  return (
    <Box
      style={{
        width: '100%',
        backgroundColor: '#F0FBFA',
        backgroundImage: `url(${backgroundMain2})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        display: 'flex',
        flexDirection: 'column',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingTop: '2rem',
        paddingBottom: '2rem',
      }}
    >
      <Box
        style={{fontSize: '1.5rem', marginLeft: '5.5rem', fontWeight: 'bold'}}
      >
        {props.text1}
      </Box>
      <Box
        style={{
          fontSize: '1.2rem',
          fontWeight: 'normal',
          marginLeft: '5.5rem',
          marginRight: '3rem',
          // marginBottom: '3rem',
          marginTop: '1rem',
        }}
      >
        {props.text2}
      </Box>
    </Box>
  );
};
TitleComponent4.propTypes = {
  text1: PropTypes.string,
  text2: PropTypes.string,
};
export const TitleComponent5 = props => {
  const navigate = useNavigate();
  function goBackHome() {
    navigate('/mypage', {
      replace: true,
      state: {
        fragmentNum: 4,
      },
    });
  }
  return (
    <Box
      style={{
        width: '100%',
        backgroundColor: '#ECFFE9',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingTop: '3rem',
        paddingBottom: '5rem',
        marginBottom: '-5rem',
      }}
    >
      <Box style={{marginTop: '1rem', fontSize: '1.5rem', fontWeight: 'bold'}}>
        {props.titleText}
      </Box>
      <Box
        style={{
          position: 'absolute',
          top: '20rem',
          right: '5%',
          transform: 'translateY(-10rem)',
        }}
      >
        <Button
          style={{
            color: '#00C500',
            backgroundColor: '#F6F6F6',
            fontSize: '0.875rem',
            border: '1px solid #00C500',
            borderRadius: '1.625rem',
            padding: '0.7rem 0rem',
            width: '7rem',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          onClick={() => goBackHome()}
        >
          돌아가기
        </Button>
      </Box>
      <Box
        style={{
          width: '85%',
          maxWidth: '100rem',
          marginTop: '2rem',
          padding: '1rem',
          backgroundColor: 'white',
          color: '#4b4d4c',
          border: '2px solid #00C500',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-all',
        }}
      >
        <Linkify
          options={{
            target: '-blank',
          }}
        >
          <TaskMessageSubmitted message={props.messageText} />
        </Linkify>

        <Box
          style={{
            display:
              props.textCountChecked || props.requiredWordChecked ? '' : 'none',
            marginLeft: '1rem',
            marginTop: '1rem',
            fontWeight: 'bold',
          }}
        >
          <Box
            style={{
              display: props.textCountChecked ? '' : 'none',
            }}
          >
            {'\u2022 필수 글자수 : ' +
              props.textCountMin +
              ' 자 ~ ' +
              props.textCountMax +
              ' 자'}
          </Box>
          <Box
            style={{
              display: props.requiredWordChecked ? '' : 'none',
            }}
          >
            {'\u2022 필수 키워드 : ' + props.requiredWord.replace(',', ', ')}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
TitleComponent5.propTypes = {
  dueText: PropTypes.string,
  dateText: PropTypes.string,
  titleText: PropTypes.string,
  messageText: PropTypes.string,
  textCountChecked: PropTypes.bool,
  textCountMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textCountMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  requiredWordChecked: PropTypes.bool,
  requiredWord: PropTypes.string,
  currentTryCount: PropTypes.number,
  maxTryLimit: PropTypes.number,
};
export const BookComponent = props => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Box
        style={{
          maxWidth: '80rem',
          minWidth: '30rem',
          width: '70%',
          minHeight: '50px',
          backgroundColor: 'white',
          borderRadius: '1.25rem',
          border: '1px solid var(--01-gray-07, #E9EBED)',
          boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
          paddingLeft: '7%',
          paddingRight: '7%',
          paddingTop: '5rem',
          paddingBottom: '3rem',
          marginBottom: '5rem',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};
BookComponent.propTypes = {
  children: PropTypes.element,
};
export const WhiteBox = props => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flex: 1,
      }}
    >
      <Box
        style={{
          width: '90%',
          backgroundColor: 'white',
          borderRadius: '1.25rem',
          border: '1px solid var(--01-gray-07, #E9EBED)',
          boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
          paddingLeft: '5%',
          paddingRight: '5%',
          paddingTop: '2rem',
          paddingBottom: '2rem',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};
WhiteBox.propTypes = {
  children: PropTypes.element,
};
export const LayerBoxComponent = props => {
  return (
    <FormControl fullWidth>
      <Box
        style={{
          fontWeight: 'bold',
          color: 'black',
          marginLeft: '0.5rem',
        }}
      >
        {props.title} <span style={{color: '#35D2BB'}}>*</span>
      </Box>
      <Box
        style={{
          minHeight: '3rem',
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
        }}
      >
        {props.children}
      </Box>
    </FormControl>
  );
};
LayerBoxComponent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
};
export const LayerBoxComponent2 = props => {
  return (
    <FormControl
      fullWidth
      style={{
        backgroundColor: Palette.green1,
        borderRadius: 10,
        height: '100%',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            marginTop: '0.5rem',
            marginLeft: '1rem',
            marginBottom: '0.5rem',
            marginRight: '0.5rem',
          }}
        >
          <img src={bullet} alt={'bullet'} style={{width: '1.5rem'}} />
        </Box>
        <Box
          style={{
            flex: 1,
            fontSize: '1.2rem',
            fontWeight: 'bold',
            color: Palette.white,
          }}
        >
          {' '}
          {props.title}
        </Box>
      </Box>
      <Box
        style={{
          backgroundColor: Palette.white,
          margin: 3,
          padding: '2rem',
          borderRadius: 10,
          height: '100%',
          wordWrap: 'break-word',
        }}
      >
        {props.children}
      </Box>
    </FormControl>
  );
};
LayerBoxComponent2.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
};

export const SingleLineTitle2 = props => {
  return (
    <Box style={{margin: '1rem 0rem'}}>
      <Box
        style={{
          marginTop: '2rem',
          display: 'flex',
          alignItems: 'left',
          justifyContent: 'flex-start',
        }}
      >
        <Box style={{display: 'flex', alignItems: 'flex-start'}}>
          <Box
            style={{
              fontSize: '1.2rem',
              fontWeight: 'bold',
              color: 'black',
              marginLeft: '0.5rem',
            }}
          >
            {props.title}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
SingleLineTitle2.propTypes = {
  title: PropTypes.string,
};

export const LayerBoxComponent3 = props => {
  return (
    <Box
      style={{
        border: '3px solid #E9EBED', //+ Palette.orange1,
        borderRadius: '1.25rem',
        backgroundColor: Palette.orange1,
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '0.5rem',
          marginBottom: '0.5rem',
          marginLeft: '1rem',
        }}
      >
        <img src={bullet} alt={'bullet'} style={{height: '25px'}} />
        <Box
          style={{
            fontSize: 'calc(0.6rem + 0.8vw)',
            fontWeight: 'bold',
            color: Palette.white,
            marginLeft: '0.5rem',
          }}
        >
          {props.title}
        </Box>
      </Box>
      <Box
        style={{
          minHeight: '3rem',
          borderBottomRightRadius: '1rem',
          borderBottomLeftRadius: '1rem',
          backgroundColor: Palette.white,
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};
LayerBoxComponent3.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
};
export const BorderBoxComponent = props => {
  return (
    <Box
      style={{
        boxSizing: 'border-box',
        borderRadius: 10,
        border: '3px solid ' + Palette.green1,
        height: '100%',
        // marginLeft: '2rem',
      }}
    >
      {props.children}
    </Box>
  );
};
BorderBoxComponent.propTypes = {
  children: PropTypes.element,
};
const disabledStyles = theme => ({
  disabledButton: {
    backgroundColor: 'red',
  },
});
export const CenteredButton = props => {
  return (
    <Box
      style={{
        marginTop: '3rem',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Button
        variant="filled"
        style={{
          cursor: props.disabled ? 'default' : 'pointer',
          color: 'white',
          backgroundColor: props.disabled ? '#87929D' : '#00C500',
          borderRadius: '6.25rem',
          padding: '0.9375rem 1.5625rem',
          fontWeight: 'bold',
          fontSize: '1.125rem',
          boxShadow: '0px 5px 10px 3px rgba(0, 0, 0, 0.15)',
          zIndex: 2,
        }}
        onClick={() => {
          props.clickEvent(props.disabled);
        }}
      >
        {props.title}
      </Button>
    </Box>
  );
};
CenteredButton.propTypes = {
  title: PropTypes.string,
  clickEvent: PropTypes.func,
  disabled: PropTypes.bool,
};

export const SingleLineComponent = props => {
  return (
    <Box
      style={{
        display: 'flex',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        marginTop: '1rem',
      }}
    >
      <Box
        style={{
          fontWeight: 'bold',
          color: 'white',
          backgroundColor: '#ff9900',
          padding: '0.3rem 0.75rem',
          borderRadius: '6.25rem',
          marginRight: '0.625rem',
        }}
      >
        {'STEP ' + props.stepNumber}
      </Box>
      <Box style={{fontSize: '1.375rem', fontWeight: 'bold', color: '#2f3941'}}>
        {props.text}
      </Box>
    </Box>
  );
};
SingleLineComponent.propTypes = {
  stepNumber: PropTypes.string,
  text: PropTypes.string,
};
export const SingleLineComponent2 = props => {
  const [leftHover, setLeftHover] = useState(false);
  const [rightHover, setRightHover] = useState(false);
  return (
    <Box
      style={{
        display: 'flex',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        marginTop: '1rem',
      }}
    >
      <Box
        style={{
          fontWeight: 'bold',
          color: 'white',
          backgroundColor: '#ff9900',
          padding: '0.3rem 0.75rem',
          borderRadius: '6.25rem',
          marginRight: '0.625rem',
        }}
      >
        {'STEP ' + props.stepNumber}
      </Box>
      <Box style={{fontSize: '1.375rem', fontWeight: 'bold', color: '#2f3941'}}>
        {props.text}
      </Box>
      <Box style={{flex: 1}}></Box>
      <Box>
        <Tooltip
          title={props.currentIndex <= 2 ? '처음 글' : '이전 글'}
          onMouseEnter={() => {
            setLeftHover(true);
          }}
          onMouseLeave={() => {
            setLeftHover(false);
          }}
        >
          <span>
            <IconButton
              disabled={props.currentIndex <= 1}
              onClick={() => props.leftClickEvent()}
            >
              <img
                src={
                  props.currentIndex <= 1
                    ? ArrowGrey1
                    : leftHover
                    ? ArrowGreen2
                    : ArrowGreen1
                }
                style={{rotate: '180deg'}}
              ></img>
            </IconButton>
          </span>
        </Tooltip>
        <span style={{fontWeight: 'bold'}}>
          {`${props.currentIndex - 1} / ${props.maxIndex - 1}`}
        </span>
        <Tooltip
          title="다음 글"
          onMouseEnter={() => {
            setRightHover(true);
          }}
          onMouseLeave={() => {
            setRightHover(false);
          }}
        >
          <span>
            <IconButton
              disabled={props.currentIndex >= props.maxIndex}
              onClick={() => props.rightClickEvent()}
            >
              <img
                src={
                  props.currentIndex >= props.maxIndex
                    ? ArrowGrey1
                    : rightHover
                    ? ArrowGreen2
                    : ArrowGreen1
                }
              ></img>
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};
SingleLineComponent2.propTypes = {
  stepNumber: PropTypes.string,
  text: PropTypes.string,
  currentIndex: PropTypes.number,
  maxIndex: PropTypes.number,
  leftClickEvent: PropTypes.func,
  rightClickEvent: PropTypes.func,
};
export const SingleLineComponent3 = props => {
  const [leftHover, setLeftHover] = useState(false);
  const [rightHover, setRightHover] = useState(false);
  return (
    <Box
      style={{
        display: 'flex',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        // marginTop: '6rem',
        marginTop: props.marginTop,
        paddingBottom: '2rem',
        marginBottom: '3rem',
        borderBottom: '3px solid #E9EBED',
      }}
    >
      <Box
        style={{
          fontSize: '1.375rem',
          fontWeight: 'bold',
          color: '#00C500',
        }}
      >
        {props.text}
      </Box>
      <Box style={{flex: 1}}></Box>
      <Box>
        <Tooltip
          title={props.currentIndex <= 1 ? '처음 글' : '이전 글'}
          onMouseEnter={() => {
            setLeftHover(true);
          }}
          onMouseLeave={() => {
            setLeftHover(false);
          }}
        >
          <span>
            <IconButton
              disabled={props.currentIndex <= 1}
              onClick={() => props.leftClickEvent()}
            >
              <img
                src={
                  props.currentIndex <= 1
                    ? ArrowGrey1
                    : leftHover
                    ? ArrowGreen2
                    : ArrowGreen1
                }
                style={{rotate: '180deg'}}
              ></img>
            </IconButton>
          </span>
        </Tooltip>
        <span style={{fontWeight: 'bold'}}>
          {props.currentIndex + '/' + props.maxIndex}
        </span>
        <Tooltip
          title="다음 글"
          onMouseEnter={() => {
            setRightHover(true);
          }}
          onMouseLeave={() => {
            setRightHover(false);
          }}
        >
          <span>
            <IconButton
              disabled={props.currentIndex >= props.maxIndex}
              onClick={() => props.rightClickEvent()}
            >
              <img
                src={
                  props.currentIndex >= props.maxIndex
                    ? ArrowGrey1
                    : rightHover
                    ? ArrowGreen2
                    : ArrowGreen1
                }
              ></img>
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};
SingleLineComponent3.propTypes = {
  marginTop: PropTypes.string,
  text: PropTypes.string,
  currentIndex: PropTypes.number,
  maxIndex: PropTypes.number,
  leftClickEvent: PropTypes.func,
  rightClickEvent: PropTypes.func,
};

export const SingleLineTitle = props => {
  return (
    <Box style={{margin: '1rem 0rem'}}>
      <Box
        style={{
          marginTop: '2rem',
          display: props.editable ? 'none' : 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box style={{display: 'flex', alignItems: 'center'}}>
          <Box
            style={{
              fontSize: '1.5625rem',
              fontWeight: 'bold',
              color: 'black',
              marginLeft: '0.5rem',
            }}
          >
            {props.title}
          </Box>
          <IconButton
            style={{
              display: 'flex',
              width: '2rem',
              height: '2rem',
            }}
            onClick={() => {
              props.changeEditStatus(true);
            }}
          >
            <img src={PencilIcon3} alt={'pencil3'} style={{width: '1.5rem'}} />
          </IconButton>
        </Box>
        <Box style={{fontSize: '1.5rem', fontWeight: 'bold', color: '#ff9900'}}>
          {'현재 점수 : ' + props.tScore + '점'}
        </Box>
      </Box>
      <Box style={{textAlign: 'end', fontSize: '0.75rem', color: '#7d7d7d'}}>
        AI 점수는 내용적 평가가 포함되지 않습니다.
      </Box>
      <Box
        style={{
          display: props.editable ? 'flex' : 'none',
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
        }}
      >
        <TextField
          autoComplete="off"
          size="small"
          variant="outlined"
          fullWidth
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '0px solid black',
              },

              '&:hover fieldset': {
                border: '0px solid black',
              },
              '&.Mui-focused fieldset': {
                border: '0px solid black',
              },
            },
          }}
          InputProps={{
            style: {
              fontSize: '1rem',
              backgroundColor: '#F7F8FA',
              border: '1px solid var(--01-gray-07, #E9EBED)',
              boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
            },
          }}
          value={props.title}
          onChange={props.handleInput}
        ></TextField>
        <Button
          style={{
            backgroundColor: '#00C500',
            color: 'white',
            fontSize: 'bold',
            whiteSpace: 'nowrap',
            marginLeft: '1rem',
          }}
          onClick={() => {
            props.changeEditStatus(false);
          }}
        >
          변경하기
        </Button>
      </Box>
    </Box>
  );
};
SingleLineTitle.propTypes = {
  title: PropTypes.string,
  tScore: PropTypes.number,
  handleInput: PropTypes.func,
  editable: PropTypes.bool,
  changeEditStatus: PropTypes.func,
};
export const CustomTextField1 = props => {
  return (
    <FormControl fullWidth>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            fontWeight: 'bold',
            color: 'black',
            marginLeft: '0.5rem',
          }}
        >
          {props.title} <span style={{color: '#35D2BB'}}>*</span>
        </Box>
      </Box>
      <Box
        style={{
          minHeight: '3rem',
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
        }}
      >
        <TextField
          autoComplete="off"
          size="small"
          variant="outlined"
          fullWidth
          sx={{
            height: '3.3rem',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '0px solid black',
              },

              '&:hover fieldset': {
                border: '0px solid black',
              },
              '&.Mui-focused fieldset': {
                border: '0px solid black',
              },
            },
          }}
          label={props.inputText == '' ? props.emptyText : ''}
          InputLabelProps={{
            style: {
              color: Palette.grey1,
              fontSize: '1rem',
            },
            shrink: false,
          }}
          InputProps={{
            style: {
              fontSize: '1rem',
              backgroundColor: '#F7F8FA',
              border: '1px solid var(--01-gray-07, #E9EBED)',
              boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
            },
          }}
          value={props.inputText}
          onChange={props.handleInput}
        ></TextField>
      </Box>
    </FormControl>
  );
};
CustomTextField1.propTypes = {
  title: PropTypes.string,
  emptyText: PropTypes.string,
  inputText: PropTypes.string,
  handleInput: PropTypes.func,
};
export const CustomTextField2 = props => {
  return (
    <FormControl fullWidth>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            fontWeight: 'bold',
            color: 'black',
            marginLeft: '0.5rem',
          }}
        >
          {props.title} <span style={{color: '#00c500'}}>*</span>
        </Box>
      </Box>
      <Box
        style={{
          minHeight: '3rem',
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
          position: 'relative',
        }}
      >
        <Box
          style={{
            position: 'absolute',
            zIndex: 3,
            right: '0.5rem',
            bottom: '1rem',
          }}
        >
          <span style={{color: '#00c500'}}>
            {StringUtils.getBodyTextLenth(props.inputText)}
          </span>
          <span style={{color: '#A8B1B7'}}>/{props.maxCount}</span>
        </Box>
        <TextField
          autoComplete="off"
          size="small"
          variant="outlined"
          fullWidth
          sx={{
            height: '3.3rem',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '0px solid black',
              },

              '&:hover fieldset': {
                border: '0px solid black',
              },
              '&.Mui-focused fieldset': {
                border: '0px solid black',
              },
            },
          }}
          label={props.inputText == '' ? props.emptyText : ''}
          InputLabelProps={{
            style: {
              color: Palette.grey1,
              fontSize: '1rem',
            },
            shrink: false,
          }}
          InputProps={{
            style: {
              fontSize: '1rem',
              backgroundColor: '#F7F8FA',
              border: '1px solid var(--01-gray-07, #E9EBED)',
              boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
            },
          }}
          value={props.inputText}
          onChange={props.handleInput}
        ></TextField>
      </Box>
    </FormControl>
  );
};
CustomTextField2.propTypes = {
  title: PropTypes.string,
  emptyText: PropTypes.string,
  inputText: PropTypes.string,
  handleInput: PropTypes.func,
  maxCount: PropTypes.string,
};
export const CustomTextField3 = props => {
  return (
    <FormControl fullWidth>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            fontWeight: 'bold',
            color: 'black',
            marginLeft: '0.5rem',
          }}
        >
          {props.title} <span style={{color: '#00c500'}}>*</span>
        </Box>
      </Box>
      <Box
        style={{
          minHeight: '3rem',
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
          position: 'relative',
        }}
      >
        <Box
          style={{
            position: 'absolute',
            zIndex: 3,
            right: '0.5rem',
            bottom: '1rem',
          }}
        >
          <span style={{color: '#00c500'}}>
            {StringUtils.getBodyTextLenth(props.inputText)}
          </span>
          <span style={{color: '#A8B1B7'}}>/{props.maxCount}</span>
        </Box>
        <TextField
          autoComplete="off"
          multiline
          size="small"
          variant="outlined"
          fullWidth
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '0px solid black',
              },
              '&:hover fieldset': {
                border: '0px solid black',
              },
              '&.Mui-focused fieldset': {
                border: '0px solid black',
              },
            },
          }}
          label={props.inputText == '' ? props.emptyText : ''}
          InputLabelProps={{
            style: {
              color: Palette.grey1,
              fontSize: '1rem',
              whiteSpace: 'pre-wrap',
            },
            shrink: false,
          }}
          InputProps={{
            style: {
              fontSize: '1rem',
              backgroundColor: '#F7F8FA',
              border: '1px solid var(--01-gray-07, #E9EBED)',
              boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
            },
          }}
          rows={20}
          value={props.inputText}
          onChange={props.handleInput}
        ></TextField>
      </Box>
    </FormControl>
  );
};
CustomTextField3.propTypes = {
  title: PropTypes.string,
  emptyText: PropTypes.string,
  inputText: PropTypes.string,
  handleInput: PropTypes.func,
  maxCount: PropTypes.string,
};
export const CommentBox = props => {
  return (
    // <Box
    //   style={{
    //     display: 'flex',
    //     justifyContent: 'center',
    //     width: '100%',
    //     flex: 1,
    //   }}
    // >
    <Box
      style={{
        width: '90%',
        backgroundColor: 'white',
        borderRadius: '1.25rem',
        border: '1px solid var(--01-gray-07, #E9EBED)',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingTop: '1rem',
        paddingBottom: '1rem',
      }}
    >
      {props.children}
    </Box>
    // </Box>
  );
};
CommentBox.propTypes = {
  children: PropTypes.element,
};
