import React from 'react';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import KeewiBird from 'asset/imageV2/keewi-bird-right.png';

const PopupView = props => {
  if (props.visibility) {
    return (
      <Box
        style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          backgroundColor: 'white',
          paddingLeft: '2rem',
          paddingRight: '2rem',
          paddingTop: '1rem',
          paddingBottom: '1rem',
          fontSize: props.fontSizeStr,
          borderRadius: '15px',
          boxShadow: '0px 0px 10px 3px rgba(0, 0, 0, 0.25)',
          transform: 'translate(-50%, -50%)',
          zIndex: '3',
        }}
      >
        <img
          src={KeewiBird}
          alt="Keewi Bird"
          style={{
            width: '1rem', // 작게 조정
            height: '1.5rem',
            flexShrink: 0, // 크기가 줄어들지 않도록 설정
          }}
        />{' '}
        {props.text.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </Box>
    );
  }
  return <></>;
};
PopupView.propTypes = {
  visibility: PropTypes.bool,
  text: PropTypes.string,
  fontSizeStr: PropTypes.string,
};
export default PopupView;
