import {Box} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import React, {useState, useRef, useEffect, useCallback} from 'react';
import Pagination from 'view/mypage/Pagination';
import APIUtils from 'common/utils/APIUtils';
import TaskView3 from './TaskView3';
import ToastPopup2 from 'view/popup/ToastPopup2';
import {modifySideKeewiMsg} from 'common/reducer/auth';
import ApiLoading from 'view/common/ApiLoading2';
import {StringUtils} from 'common/utils/StringUtils';

export const FragmentView = () => {
  const dispatch = useDispatch();
  const authReducer = useSelector(state => state.authReducer);
  const [loading, setLoading] = useState(true);
  const [lastTaskListCount, setLastTaskListCount] = useState(0);

  const [originalTaskList, setOriginalTaskList] = useState([]);
  const [filteredTaskList, setFilteredTaskList] = useState([]);
  const countPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [peerReviewTaskViewList, setPeerReviewTaskViewList] = useState([]);

  const [toastText, setToastText] = useState('');
  const [toastFontSizeText, setToastFontSizeText] = useState('1rem');
  const [toastVisibility, setToastVisibility] = useState(false);
  const toastTimeoutRef = useRef(null);

  function ToastShow(text, time, sizeStr) {
    let selectedSentence = text;
    const regex = new RegExp(`^(.{40,}?)(\\n|\\. |! |$)`);
    const match = text.match(regex);
    if (match) {
      selectedSentence = match[1];
    }

    setToastText(selectedSentence);
    setToastFontSizeText(sizeStr);
    setToastVisibility(true);

    if (toastTimeoutRef.current) {
      clearTimeout(toastTimeoutRef.current);
    }

    toastTimeoutRef.current = setTimeout(() => {
      setToastVisibility(false);
    }, time);
  }

  const updatePeerReviewList = (action, task_id) => {
    if (action === 'add') {
      if (!peerReviewTaskViewList.includes(task_id)) {
        setPeerReviewTaskViewList([...peerReviewTaskViewList, task_id]);
      }
    } else if (action === 'remove') {
      setPeerReviewTaskViewList(
        peerReviewTaskViewList.filter(id => id !== task_id),
      );
    }
  };

  const getFilteredTask = useCallback(() => {
    if (!originalTaskList.length) {
      setFilteredTaskList([]);
      return;
    }

    let filtered = [...originalTaskList];
    filtered.sort(
      (a, b) => new Date(b.task_end_time) - new Date(a.task_end_time),
    );
    setFilteredTaskList(filtered);
    setTotalPage(Math.floor((filtered.length - 1) / countPerPage + 1));
  }, [originalTaskList]);

  const getTaskList = useCallback(async () => {
    if (!authReducer.student_id) return;

    try {
      const taskResponse = await APIUtils.TaskOngoing(authReducer.student_id);

      if (
        taskResponse?.status === 200 &&
        taskResponse?.data?.ret_code === 1000
      ) {
        setOriginalTaskList(taskResponse.data.task_list);
        removeFinishedTaskFromLocalStorage(taskResponse.data.task_list);
        setLoading(false);
      }
      {
        setLastTaskListCount(taskResponse.data.task_list.length);
        let task_list = taskResponse.data.task_list;
        task_list.sort(
          (a, b) => new Date(b.task_end_time) - new Date(a.task_end_time),
        );
        const now_time = StringUtils.getFormattedString(new Date());

        const system_prompt =
          `당신은 학생을 따뜻하게 격려하는 선생님의 역할입니다. 
    학생이 해야 할 과제 리스트를 보고, 하나를 골라 가이드와 팁을 제공합니다. 존댓말을 사용하고  체적인 응원과 조언의 메시지를 작성해주세요.\n현재 시각 : ` +
          now_time +
          '\n학생 이름 : ' +
          authReducer.student_name +
          '\n\n';
        const user_prompt =
          `최근 1주일 이내의 과제수 :  ${task_list.length}개 \n\n` +
          task_list
            .map((task, index) => {
              let peerReviewStatus = '';

              if (task.peer_review_status === 2) {
                peerReviewStatus = `   - 동료 평가: 진행중${
                  task.peer_time_check === 1
                    ? ` (마감 시간: ${task.peer_end_time})`
                    : ''
                }\n`;
              } else if (task.peer_review_status === 3) {
                peerReviewStatus = `   - 동료 평가: 진행중\n`;
              } else if (task.peer_review_status === 4) {
                peerReviewStatus = `   - 동료 평가: 종료\n`;
              } else if (task.peer_review_status === 5) {
                peerReviewStatus = `   - 동료 평가: 결과 공유중\n`;
              }

              return (
                `* 과제명 : ${task.task_name}\n` +
                `   - 선생님 이름: ${task.teacher_name}\n` +
                `   - 마감 시간: ${task.task_end_time}\n` +
                `   - 과제 장르: ${task.task_janre}\n` +
                `   - 제출 상태: ${
                  task.task_submit ? '제출완료후 제출' : '제출전'
                }\n` +
                peerReviewStatus
              );
            })
            .join('\n');
        const additional_system_prompt = ` 메시지의 목표: 실질적인 도움말, 재미있어야 함. 약간 게그 허용.
    필수 : (과제 수행 유도) 최근 과제를 언급하고 학생들이 미리 준비할 수 있도록 과제 작성 관련 아이데이션 팁 제공.  
    선택-1 : (동기 유발) 글쓰기와 문해력을 통해 사회적 성공, 돈을 많이 벌 수 있는 사례 제시
    선택-2 : (감정 호소) 해당 과제의 선생님 이름을 언급하며 학생의 과제 제출을 기다리고 있음, 동료 평가가 있는 경우 다른 학생도 기다리고 있음.
    선택-3 : (준비 자세) AI 글쓰기 연습, 문해력 더하기 도전, 온라인 글쓰기 대회 (선생님 인증번호는 1000-1000)에 참여하기를 권장.
       필수 1개, 선택 중 1개에 대해서 도움말을 해주세요. 초중고 그리고 대학생도 사용자이기 때문에 너무 어린이 대하듯 하지 마세요. 따뜻한 어휘와 존대말를 사용하세요. 메시지는 짧게 100글자 이내로 하고 **은 사용하지 않습니다. 또한 문장 단위로 한줄씩 작성합니다. 인사 하지않고 이름+님으로 호칭합니다. 대화가 아닌 메시지입니다.`;
        const gptResponse = await APIUtils.ChatGPT(
          0.6,
          system_prompt,
          user_prompt,
          additional_system_prompt,
        );

        if (
          gptResponse?.status === 200 &&
          gptResponse?.data?.ret_code === 1000
        ) {
          dispatch(
            modifySideKeewiMsg({
              keewi_msg: gptResponse.data.text.replace(/\n{2,}/g, '\n'),
              last_msg_place: 'task_list',
            }),
          );
          ToastShow(
            gptResponse.data.text.replace(/\n{2,}/g, '\n'),
            2000,
            '1.0rem',
          );
        }
      }
    } catch (err) {
      console.error('Error in getTaskList:', err);
    }
  }, [authReducer.student_id, dispatch]);

  function removeFinishedTaskFromLocalStorage(_originTask) {
    const runningTask = _originTask.map(item => item.task_id.toString());
    for (let i = 0; i < localStorage.length; i++) {
      let foundKey = localStorage.key(i);
      if (foundKey.includes('taskTitle') || foundKey.includes('taskBody')) {
        let foundTask = foundKey.replace(/taskTitle|taskBody/, '');
        if (!runningTask.includes(foundTask)) {
          localStorage.removeItem(foundKey);
        }
      }
    }
  }

  useEffect(() => {
    getTaskList();
  }, []);

  useEffect(() => {
    getFilteredTask();
  }, [getFilteredTask]);
  if (loading) {
    return <ApiLoading />;
  }
  return (
    <Box>
      <ToastPopup2
        text={toastText}
        visibility={toastVisibility}
        fontSizeStr={toastFontSizeText}
      />
      <Box style={{fontWeight: 'bold', fontSize: '1.375rem'}}>최근 과제</Box>
      <Box
        style={{
          backgroundColor: '#E6E8EB',
          width: '100%',
          height: '2px',
          marginTop: '1.3rem',
          marginBottom: '1.75rem',
        }}
      />
      <Box
        style={{
          marginBottom: '1.75rem',
          color: '#87929D',
          fontSize: '0.875rem',
        }}
      >
        최근 1주일 이내에 진행 중인 과제입니다. 제출 기한을 반드시 확인하세요.
      </Box>
      {filteredTaskList.length === 0 && <Box>진행 중 과제가 없습니다.</Box>}
      <Box style={{marginBottom: '2rem'}}>
        {filteredTaskList
          .slice((currentPage - 1) * countPerPage, currentPage * countPerPage)
          .map((value, index) => (
            <TaskView3
              key={`task${index}`}
              task={value}
              updatePeerReviewList={updatePeerReviewList}
              peerReviewTaskViewList={peerReviewTaskViewList}
            />
          ))}
      </Box>
      <Pagination
        totalPage={totalPage}
        currentPage={currentPage}
        clickEvent={setCurrentPage}
      />
    </Box>
  );
};

export default FragmentView;
