import moment from 'moment/moment';
import {DateTime} from 'luxon';
export const StringUtils = {
  getTrimmedBody: function (input = '') {
    const splited = input.split('\n');
    // 24.10.31 문단 들여쓰기 허용, CHOYH
    // .map(val => val.trim())
    // .filter(val => val != '')
    let res = splited.join('\n');
    // 24.10.31 문단 들여쓰기 허용, CHOYH
    // res = res.replace(/  */g, ' ');
    return res;
  },
  getBodyTextLenth: function (input = '') {
    if (input == null) {
      return 0; // null 입력 시 0 반환
    }

    const filteredText = input
      .split('\n') // 텍스트를 줄 단위로 분리
      .filter(line => !line.trim().startsWith('###')) // ###으로 시작하지 않는 줄만 남김
      .join('\n') // 필터링된 줄을 다시 문자열로 결합
      .replace(/\s+/g, ' '); // 공백을 하나로 축소

    return filteredText.length; // 최종 문자열 길이 반환
  },
  getDateString: function (input = '') {
    if (input == null) {
      return '';
    }
    const temp1 = input.split(' ')[0];
    return temp1;
  },
  getDateStringKr: function (input = '') {
    if (input == null) {
      return '';
    }
    const date = new Date(input);
    const dateText =
      date.getFullYear() +
      '년   ' +
      (date.getMonth() + 1) +
      '월 ' +
      date.getDate() +
      '일';
    return dateText;
  },
  getTimeStringKr: function (input = '') {
    if (input == null) {
      return '';
    }
    const date = new Date(input);
    const dateText =
      date.getFullYear() +
      '년   ' +
      (date.getMonth() + 1) +
      '월 ' +
      date.getDate() +
      '일 ' +
      date.getHours() +
      ':' +
      String(date.getMinutes()).padStart(2, '0');
    return dateText;
  },
  getTodayDateKr: function () {
    const date = new Date();
    const dateText =
      date.getFullYear() +
      '년   ' +
      (date.getMonth() + 1) +
      '월 ' +
      date.getDate() +
      '일';
    return dateText;
  },
  getTodayDate: function () {
    const date = new Date();
    const dateText =
      date.getFullYear() + '.' + (date.getMonth() + 1) + '.' + date.getDate();
    return dateText;
  },
  getDateOnly: function (date) {
    const dateText =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1 < 10 ? '0' : '') +
      (date.getMonth() + 1) +
      '-' +
      date.getDate();
    ':' + date.getHours();
    '-' + date.getMinutes();
    return dateText;
  },
  getTodayDateOnly: function () {
    const date = new Date();
    return this.getLocalISOString(date).substring(0, 10);
  },
  getTodayDateMinOnly: function () {
    const date = new Date();
    return this.getLocalISOString(date).substring(0, 16);
  },
  getMonthPlusDate: function (number) {
    const today = new Date();
    const date = new Date(today.setMonth(today.getMonth() + number));
    const dateText =
      date.getFullYear() + '.' + (date.getMonth() + 1) + '.' + date.getDate();
    return dateText;
  },
  getDateDiff: function (endDate) {
    const date = new Date(endDate);
    const today = new Date();
    const dateDiff = moment(date).diff(today, 'days');
    return dateDiff;
  },
  getHourDiff: function (endDate) {
    const date = new Date(endDate);
    const now = new Date();
    const dateDiff = (now - date) / 3600000;
    return dateDiff;
  },
  getTimeDiff: function (endDate) {
    const date = new Date(endDate);
    const now = new Date();
    const timeDiff = date - now;
    return timeDiff;
  },
  getTimeDiffTZ: function (endDate, tz) {
    const target = DateTime.fromFormat(endDate, 'yyyy-MM-dd HH:mm:ss', {
      zone: tz,
    });
    const now = DateTime.now().setZone(tz);
    const diff = target.diff(now).as('milliseconds');
    return diff;
  },
  getFormattedString: function (newDate) {
    let dateString =
      newDate.getFullYear() +
      '-' +
      String(newDate.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(newDate.getDate()).padStart(2, '0') +
      ' ' +
      String(newDate.getHours()).padStart(2, '0') +
      ':' +
      String(newDate.getMinutes()).padStart(2, '0') +
      ':' +
      String(newDate.getSeconds()).padStart(2, '0');
    return dateString;
  },
  getLocalISOString: function (date) {
    const offset = date.getTimezoneOffset();
    const offsetAbs = Math.abs(offset);
    const isoString = new Date(
      date.getTime() - offset * 60 * 1000,
    ).toISOString();
    return `${isoString.slice(0, -1)}${offset > 0 ? '-' : '+'}${String(
      Math.floor(offsetAbs / 60),
    ).padStart(2, '0')}:${String(offsetAbs % 60).padStart(2, '0')}`;
  },
};
