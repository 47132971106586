import React, {useEffect, useState, useRef} from 'react';
import {Box, MenuItem, Select, FormControl, InputLabel} from '@mui/material';
import {Palette} from 'common/utils/ColorUtils';
import sampleText from 'asset/json/sample_text.json';
import {useNavigate, useLocation} from 'react-router-dom';
import {AnalysisText} from 'view/result/AnalysisText';
import {
  TitleComponent2_1,
  BookComponent,
  CenteredButton,
  SingleLineComponent,
  CustomTextField2,
  CustomTextField3,
} from 'view/common/Components';
import HeaderView from 'view/common/Header2';
import {useSelector} from 'react-redux';
import FooterView from 'view/common/Footer';

import emojiRegex from 'emoji-regex';
import {StringUtils} from 'common/utils/StringUtils';
import APIUtils from 'common/utils/APIUtils';
import NoticketPopup from 'view/popup/NoTicket2';
import SubmitDonePopup from 'view/popup/SubmitDonePopup';
import SubmitInvalidPopup from 'view/popup/SubmitInvalidPopup';
import SubmitLateDonePopup from 'view/popup/SubmitLateDonePopup';
import SubmitLateInvalidPopup from 'view/popup/SubmitLateInvalidPopup';
import ApiLoading from 'view/common/ApiLoading';
import ChatOverlay from 'view/chat/ChatOveray';
import ToastPopup from 'view/popup/ToastPopup';

const WritingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authReducer = useSelector(state => state.authReducer);

  const [loading, setLoading] = useState(false);

  const [sample, setSample] = useState({});
  const [selectValue, setSelectValue] = useState('');
  const [selectOption, setSelectOption] = useState([]);
  const [bodyText, setBodyText] = useState('');
  const [titleText, setTitleText] = useState('');

  const [taskID, setTaskID] = useState(0);
  const [taskDateLeft, setTaskDateLeft] = useState(0);
  const [taskDueLeft, setTaskDueLeft] = useState(0);
  const [taskDateText, setTaskDateText] = useState('');
  const [taskTitleText, setTaskTitleText] = useState('');
  const [taskMessage, setTaskMessage] = useState('');
  const [taskJanre, setTaskJanre] = useState('');
  const [taskType, setTaskType] = useState(1);
  const [taskBaseContent, setTaskBaseContent] = useState('');
  const [preKeewiChat, setPreKeewiChat] = useState(0);

  const [submitButtonColor, setsubmitButtonColor] = useState('#00C500');
  const [submitButtonText, setsubmitButtonText] = useState('제출하기');
  const [cancelLateRewrite, setCancelLateRewrite] = useState(false);

  const [textCountChecked, setTextCountChecked] = useState(false);
  const [textCountMin, setTextCountMin] = useState('');
  const [textCountMax, setTextCountMax] = useState('');

  const [requiredWordChecked, setRequiredWordChecked] = useState(false);
  const [requiredWord, setRequiredWord] = useState('');
  const [unfoundRequiredWord, setUnfoundRequiredWord] = useState('');
  const [currentTryCount, setCurrentTryCount] = useState(0);
  const [maxTryLimit, setMaxTryLimit] = useState(0);

  const [chatVisibility, setChatVisibility] = useState(false);
  const [chatTask, setChatTask] = useState(undefined);

  const [toastText, setToastText] = useState('');
  const [toastVisibility, setToastVisibility] = useState(false);
  const toastTimeoutRef = useRef(null); // `setTimeout`을 추적할 ref

  function ToastShow(text, time) {
    setToastText(text);
    setToastVisibility(true);

    // 기존 `setTimeout`이 실행 중이라면 취소
    if (toastTimeoutRef.current) {
      clearTimeout(toastTimeoutRef.current);
    }

    // 새로운 `setTimeout` 설정 후 페이지 이동
    toastTimeoutRef.current = setTimeout(() => {
      setToastVisibility(false);
    }, time);
  }

  const [noticketVisibility, setNoticketVisibility] = useState(false);

  function noticketComplete() {
    setNoticketVisibility(false);
    navigate('/');
  }
  const [submitInvalidVisibility, setSubmitInvalidVisibility] = useState(false);
  const [submitDoneVisibility, setSubmitDoneVisibility] = useState(false);
  const [submitLateInvalidVisibility, setSubmitLateInvalidVisibility] =
    useState(false);
  const [submitLateDoneVisibility, setSubmitLateDoneVisibility] =
    useState(false);

  function submitInvalidCancel() {
    setSubmitInvalidVisibility(false);
  }
  function submitLateInvalidCancel() {
    setSubmitLateInvalidVisibility(false);
    setCancelLateRewrite(true);
  }

  function submitDoneConfirm() {
    setSubmitDoneVisibility(false);
    navigate('/mypage');
  }

  function submitLateDoneConfirm() {
    setSubmitLateDoneVisibility(false);
    navigate('/mypage');
  }

  function submitLateInvalidConfirm() {
    setSubmitLateInvalidVisibility(false);
    const trimmedText = StringUtils.getTrimmedBody(bodyText);
    analyzeEvent(titleText, taskJanre, trimmedText, taskID, '(조건 불만족) ');
  }

  const handleSelect = event => {
    const dictKey = selectOption[event.target.value].text;

    setSelectValue(event.target.value);
    setBodyText(sample[dictKey]);
    setTitleText(dictKey);
  };
  const handleBody = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 10000) {
        inputVal = inputVal.substring(0, 10000);
      }
      setBodyText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };
  const handleTitle = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 50) {
        inputVal = inputVal.substring(0, 50);
      }
      setTitleText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };

  function navigateToResult(disabled) {
    if (disabled) {
      alert('제목, 본문을 모두 입력하셔야 제출하기가 가능합니다.');
      return;
    }

    const trimmedText = StringUtils.getTrimmedBody(bodyText);
    if (taskType == 1) {
      window.localStorage.removeItem('title');
      window.localStorage.removeItem('text');
      window.localStorage.removeItem('janre');
      navigate('/rewrite2', {
        state: {
          title: titleText,
          text: trimmedText,
          writing_janre: taskJanre,
          task_id: taskID,
        },
      });
    }

    if (taskType == 2) {
      if (
        textCountChecked &&
        (StringUtils.getBodyTextLenth(bodyText) < textCountMin ||
          StringUtils.getBodyTextLenth(bodyText) > textCountMax)
      ) {
        if (taskDueLeft >= 0) {
          setSubmitInvalidVisibility(true);
          return;
        } else {
          setSubmitLateInvalidVisibility(true);
          return;
        }
      }
      if (
        requiredWordChecked &&
        0 != unfoundRequiredWord.split(',').filter(val => val != '').length
      ) {
        if (taskDueLeft >= 0) {
          setSubmitInvalidVisibility(true);
          return;
        } else {
          setSubmitLateInvalidVisibility(true);
          return;
        }
      }
      analyzeEvent(titleText, taskJanre, trimmedText, taskID, '');
    }
  }

  function analyzeEvent(new_title, new_janre, new_text, new_task_id, validity) {
    setLoading(true);
    const fetAnalasisResult = async (
      inputText,
      inputTitle,
      inputJanre,
      inputTaskID,
    ) => {
      try {
        const response = await APIUtils.TaskEval(
          authReducer.student_id,
          inputTaskID,
          inputTitle,
          inputJanre,
          inputText,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };

    const saveResult = async result => {
      try {
        const response = await APIUtils.TaskEvalSave(
          authReducer.student_id,
          new_task_id,
          result,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };

    const submitResult = async () => {
      try {
        const response = await APIUtils.TaskSubmit(
          authReducer.student_id,
          new_task_id,
        );
        return response;
      } catch (err) {
        console.log(err);
        return null;
      }
    };

    submitResult().then(res => {
      if (res && res.status === 200 && res.data.ret_code === 1000) {
        ToastShow('제출했습니다.', 1000);
      }
    });

    fetAnalasisResult(new_text, new_title, new_janre, new_task_id)
      .then(res => {
        const textLength = StringUtils.getBodyTextLenth(new_text); // 글자 수 확인
        const missingKeywords = requiredWord
          .split(',')
          .filter(keyword => !new_text.includes(keyword) && keyword !== '');
        const bad_submit_status =
          (textCountChecked &&
            (textLength < textCountMin || textLength > textCountMax)) ||
          (requiredWordChecked && missingKeywords.length > 0)
            ? 0
            : 1;

        if (res.data.ret_code == 1000) {
          const jsonResult = AnalysisText.createSavingJsonFromEvalResult(
            res.data.eval_result,
            validity + new_title,
            new_text,
            '성인',
            authReducer.student_name,
            bad_submit_status,
          );
          return saveResult(jsonResult);
        }
      })
      .then(re => {
        if (re.status == 200 && re.data.ret_code == 1000) {
          return submitResult();
        }
      })
      .then(r => {
        if (r.status == 200 && r.data.ret_code == 1000) {
          setSubmitDoneVisibility(true);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }

  function getPassInfo() {
    const passResult = async () => {
      try {
        const response = await APIUtils.AccountPassCheck(
          authReducer.student_id,
        );
        if (response.status == 200 && response.data.ret_code == 1000) {
          if (!response.data.pass_available) {
            setNoticketVisibility(true);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    passResult();
  }

  function getTaskInfo(task_id) {
    const taskResult = async () => {
      try {
        const response = await APIUtils.TaskCall(
          authReducer.student_id,
          task_id,
        );
        if (response.status == 200 && response.data.ret_code == 1000) {
          if (response.data.max_try > 0 && response.data.eval_try > 0) {
            navigate('/rewrite2', {
              state: {
                title: response.data.title,
                text: response.data.text,
                writing_janre: response.data.task_janre,
                task_id: task_id,
              },
            });
          }
        }

        setChatTask(response.data);
        const dateLeft = StringUtils.getDateDiff(response.data.task_end_time);
        const timeLeft = StringUtils.getTimeDiffTZ(
          response.data.task_end_time,
          response.data.tz,
        );
        setPreKeewiChat(response.data.pre_keewichat);
        if (response.data.pre_keewichat == 0) setChatVisibility(false);
        setTaskDateLeft(dateLeft);
        setTaskDueLeft(timeLeft);
        setsubmitButtonColor(timeLeft > 0 ? '#00C500' : 'red');
        setsubmitButtonText(timeLeft > 0 ? '제출하기' : '늦은제출');
        setTaskDateText(
          StringUtils.getTimeStringKr(response.data.task_start_time) +
            ' ~ ' +
            StringUtils.getTimeStringKr(response.data.task_end_time),
        );
        setTaskTitleText(
          '[' + response.data.task_janre + '] ' + response.data.task_name,
        );
        setTaskMessage(response.data.task_message);
        setTaskBaseContent();
        setTaskJanre(response.data.task_janre);
        setTitleText(response.data.title);
        setBodyText(response.data.text);
        setTaskType(response.data.task_type);
        if (
          response.data.title == '' &&
          response.data.text == '' &&
          localStorage.getItem('taskTitle' + task_id) &&
          localStorage.getItem('taskBody' + task_id)
        ) {
          setTitleText(localStorage.getItem('taskTitle' + task_id));
          setBodyText(localStorage.getItem('taskBody' + task_id));
        }
        setTextCountChecked(response.data.length_check == 1);
        setTextCountMin(response.data.min_length);
        setTextCountMax(response.data.max_length);
        setRequiredWordChecked(response.data.keyword_check == 1);
        setRequiredWord(response.data.keyword);
        setMaxTryLimit(response.data.max_try);
        setCurrentTryCount(response.data.eval_try);
      } catch (err) {
        console.log(err);
      }
    };
    taskResult();
  }

  function createSampleSelector() {
    if (process.env.REACT_APP_ENV == 'live') {
      return <></>;
    } else {
      return (
        <FormControl
          size="small"
          sx={{
            m: 1,
            minWidth: 200,
            color: Palette.white,
          }}
        >
          <InputLabel
            id="sample-select-label"
            style={{
              color: 'blue',
            }}
          >
            예시 글 가져오기
          </InputLabel>
          <Select
            labelId="sample-select-label"
            id="sample-select"
            onChange={handleSelect}
            value={selectValue}
            label="예시 글 가져오기"
            style={{
              color: Palette.white,
              backgroundColor: Palette.grey1,
            }}
          >
            {selectOption.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
  }

  useEffect(() => {
    let input_task_id = 0;
    if (
      location.state == null &&
      window.localStorage.getItem('task_id') == null
    ) {
      navigate('/', {replace: true});
      return;
    } else if (location.state == null) {
      input_task_id = Number(window.localStorage.getItem('task_id'));
    } else {
      input_task_id = location.state.task_id;
    }

    let dict = {};
    let selectList = [];
    sampleText.forEach((item, index) => {
      dict[item.subject] = item.text;
      selectList.push({value: index, text: item.subject});
    });
    setTaskID(input_task_id);
    setSample(dict);
    setSelectOption(selectList);
    getPassInfo();
    getTaskInfo(input_task_id);
  }, []);

  useEffect(() => {
    if (titleText == '' && bodyText == '') {
      return;
    }
    localStorage.setItem('taskBody' + taskID, bodyText);
    localStorage.setItem('taskTitle' + taskID, titleText);
  }, [taskID, titleText, bodyText]);

  useEffect(() => {
    setUnfoundRequiredWord(
      requiredWord
        .split(',')
        .filter(val => !bodyText.includes(val))
        .join(','),
    );
  }, [bodyText, requiredWord]);

  if (loading) return <ApiLoading loading={loading} />;
  return (
    <Box
      style={{
        width: chatVisibility ? '70%' : '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box>
        <ToastPopup text={toastText} visibility={toastVisibility} />
      </Box>
      <NoticketPopup
        visibility={noticketVisibility}
        completeEvent={noticketComplete}
      />
      <SubmitDonePopup
        visibility={submitDoneVisibility}
        completeEvent={submitDoneConfirm}
      />
      <SubmitInvalidPopup
        visibility={submitInvalidVisibility}
        textCountChecked={textCountChecked}
        textCountMin={Number(textCountMin)}
        textCountMax={Number(textCountMax)}
        currentCount={StringUtils.getBodyTextLenth(bodyText)}
        requiredWordChecked={requiredWordChecked}
        requiredWord={requiredWord}
        unfoundRequiredWord={unfoundRequiredWord}
        cancelEvent={submitInvalidCancel}
      />
      <SubmitLateDonePopup
        visibility={submitLateDoneVisibility}
        submitLateDoneConfirm={submitLateDoneConfirm}
      />
      <SubmitLateInvalidPopup
        visibility={submitLateInvalidVisibility}
        textCountChecked={textCountChecked}
        textCountMin={Number(textCountMin)}
        textCountMax={Number(textCountMax)}
        currentCount={StringUtils.getBodyTextLenth(bodyText)}
        requiredWordChecked={requiredWordChecked}
        requiredWord={requiredWord}
        taskID={Number(taskID)}
        unfoundRequiredWord={unfoundRequiredWord}
        submitLateInvalidCancel={submitLateInvalidCancel}
        submitLateInvalidConfirm={submitLateInvalidConfirm}
      />
      <HeaderView />
      <TitleComponent2_1
        dateLeft={taskDateLeft}
        dateLeftText={
          taskDueLeft < 0 ? `D+${Math.abs(taskDateLeft)}` : `D-${taskDateLeft}`
        }
        dateLeftColor={taskDueLeft < 0 ? 'red' : `green`}
        dateText={taskDateText}
        titleText={taskTitleText}
        messageText={taskMessage}
        textCountChecked={textCountChecked}
        textCountMin={textCountMin}
        textCountMax={textCountMax}
        requiredWordChecked={requiredWordChecked}
        requiredWord={requiredWord}
        currentTryCount={currentTryCount}
        maxTryLimit={maxTryLimit}
      />
      <BookComponent shrink={chatVisibility}>
        <>
          {createSampleSelector()}
          <SingleLineComponent stepNumber={'01'} text="과제를 작성해 주세요." />
          <Box style={{marginTop: '1.5rem'}} />
          <CustomTextField2
            title="제목"
            emptyText={'글의 제목을 50자 내로 입력해 주세요.'}
            inputText={titleText}
            handleInput={handleTitle}
            maxCount="50"
          />
          <Box style={{marginTop: '1.5rem'}} />
          <CustomTextField3
            title="본문"
            emptyText={`평가할 글을 입력해주세요. 엔터 키[↲] 입력 시 문단이 나뉘었다고 인식합니다.\n문장 부호 외 특수문자/이모지가 포함된 경우, 평가 결과가 정상적으로 출력되지 않을 수 있습니다.`}
            inputText={bodyText}
            handleInput={handleBody}
            maxCount="10000"
          />
          <CenteredButton
            title={taskType == 2 ? '제출하기' : 'AI 피드백 받기'}
            disabled={titleText == '' || bodyText.trim() == ''}
            clickEvent={navigateToResult}
          ></CenteredButton>

          <Box
            style={{display: taskType == 2 || maxTryLimit > 0 ? 'none' : ''}}
          >
            <Box
              style={{
                textAlign: 'center',
                marginTop: '2rem',
                color: '#87929D',
                fontWeight: 'bold',
              }}
            >
              <span
                style={{
                  color: '#00C500',
                }}
              >
                “AI 피드백 받기”
              </span>
              {' 버튼을 눌러도 선생님에게 제출되지 않습니다.'}
            </Box>
            <Box
              style={{
                textAlign: 'center',
                color: '#87929D',
                fontWeight: 'bold',
              }}
            >
              {'다음 화면에서 '}
              <span
                style={{
                  color: '#00C500',
                }}
              >
                “제출하기”
              </span>
              {' 버튼을 눌러야만 제출이 완료됩니다.'}
            </Box>
          </Box>
          <Box
            style={{
              display: taskType == 1 && maxTryLimit > 0 ? '' : 'none',
            }}
          >
            <Box
              style={{
                textAlign: 'center',
                marginTop: '2rem',
                color: '#87929D',
                fontWeight: 'bold',
              }}
            >
              <span
                style={{
                  color: '#00C500',
                }}
              >
                최대 수정 횟수는 {maxTryLimit}회 입니다.
              </span>
            </Box>
            <Box
              style={{
                textAlign: 'center',
                color: '#87929D',
                fontWeight: 'bold',
              }}
            >
              {'초안부터  자동으로 '}
              <span
                style={{
                  color: '#00C500',
                }}
              >
                제출
              </span>
              되며 앞으로{' '}
              <span
                style={{
                  color: '#00C500',
                }}
              >
                {' '}
                {maxTryLimit}번
              </span>{' '}
              수정할 수 있습니다.
            </Box>
          </Box>

          <Box style={{display: taskType == 2 ? '' : 'none'}}>
            <Box
              style={{
                textAlign: 'center',
                marginTop: '2rem',
                color: '#87929D',
                fontWeight: 'bold',
              }}
            >
              <span
                style={{
                  color: submitButtonColor,
                }}
              >
                {submitButtonText}
              </span>
              {' 버튼을 누르면 선생님에게 제출됩니다.'}
            </Box>
          </Box>
        </>
      </BookComponent>
      <ChatOverlay
        chatVisibility={chatVisibility}
        handleVisibility={() => {
          setChatVisibility(!chatVisibility);
        }}
        taskID={taskID}
        chatTask={chatTask}
      />
      <FooterView />
    </Box>
  );
};
export default WritingPage;
