import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import KeewiBird from 'asset/imageV2/keewi-bird-right.png';

const ItemView = props => {
  return (
    <Box style={{display: 'flex', justifyContent: 'start'}}>
      <Box
        style={{
          margin: '0.7rem 3rem 0.7rem 1rem',
          padding: '0.875rem',
          backgroundColor: 'white',
          fontSize: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '4px 5px 13px 5px #dadedf',
          whiteSpace: 'pre-line',
        }}
      >
        <img
          src={KeewiBird}
          alt="Keewi Bird"
          style={{
            width: '1rem',
            height: '1.5rem',
            marginRight: '0.5rem',
          }}
        />
        {props.content}
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  content: PropTypes.string,
};
export default ItemView;
