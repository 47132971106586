import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import AuthTeacherPopup from 'view/popup/AuthTeacherPopup';
import AuthTeacherCompletePopup from 'view/popup/AuthTeacherCompletePopup';
import KeewiBird from 'asset/imageV2/keewi-bird-right.png';
import APIUtils from 'common/utils/APIUtils';

export const SidebarView = props => {
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  const [completeVisibility, setCompleteVisibility] = useState(false);

  function completeCancel() {
    setCompleteVisibility(false);
  }

  const [authTeacherVisibility, setAuthTeacherVisibility] = useState(false);
  function authTeacherCancel() {
    setAuthTeacherVisibility(false);
  }
  function authTeacherConfirm() {
    setAuthTeacherVisibility(false);
    setCompleteVisibility(true);
  }
  const [keewi_msg, setKeewiMsg] = useState('');
  useEffect(() => {
    if (authReducer.keewi_msg) {
      setKeewiMsg(authReducer.keewi_msg.replace(/\n{2,}/g, '\n')); // payload 내부의 메시지만 사용
    } else {
      setKeewiMsg('');
    }
  }, [authReducer.keewi_msg]);

  async function runKeewiBirdSpeech() {
    try {
      const promptSets = [
        {
          system_prompt:
            '너는 따뜻하고 친근한 조언을 해주는 키위챗이야. 학생들이 고민하거나 지쳤을 때, 힘이 나는 이야기나 조언을 해줘. 친근하지만 진정성 있게 이야기해줘. 초/중/고/대학생들이 공감할 수 있는 주제로 이야기해줘.',
          user_prompt:
            '공부나 일상에서 힘들 때, 용기를 주는 이야기를 해줘. 나의 이름은 ' +
            authReducer.student_name +
            '입니다.',
          additional_system_prompt:
            ' 내용에 따라 이모티콘 허용 이야기는 짧고 간결하게 전달해줘. "너는 할 수 있어!" 같은 단순한 말보다는, 작지만 실천할 수 있는 팁과 경험을 들어줘. 학생이 생각해 볼 수 있는 질문도 함께 던져줘. 100글자 수준 공손하게 존댓말쓰고, 인사하지 말고, 너라고 하지 말고, 이름을 불러줘. 한문장을 한줄에,  bold 사용하지마. ',
        },
        {
          system_prompt:
            '너는 똑똑한 친구 같은 키위챗이야. 학생들이 공부할 때 도움이 될 수 있는 작은 팁이나 습관, 집중력을 높일 수 있는 방법을 유익하게 이야기해줘.',
          user_prompt:
            '공부할 때 도움이 되는 좋은 습관이나 꿀팁을 알려줘. 나의 이름은 ' +
            authReducer.student_name +
            '입니다.',
          additional_system_prompt:
            '내용에 따라 이모티콘 허용 너무 딱딱하지 않게, 친구가 조언해주는 것처럼 친근하게 이야기해줘. 실천하기 쉬운 팁 위주로 이야기하고, 왜 그런 습관이 도움이 되는지 간단한 이유도 설명해줘. 100글자 수준 공손하게 존댓말쓰고, 인사하지 말고, 너라고 하지 말고, 이름을 불러줘. 한문장을 한줄에,  bold 사용하지마. ',
        },
        {
          system_prompt:
            '너는 인생에 대해 깊은 생각을 하게 해주는 키위챗이야. 학생들이 삶에서 소중하게 생각해볼 만한 가치, 우정, 배려, 도전 같은 주제에 대해 이야기해줘. 너무 어렵지 않게 쉽게 풀어서 이야기해줘.',
          user_prompt:
            '살면서 중요한 가치나 생각해볼 만한 이야기를 해줘. 나의 이름은 ' +
            authReducer.student_name +
            '입니다.',
          additional_system_prompt:
            '내용에 따라 이모티콘 허용 너무 무겁지 않게, 일상 속에서 학생들이 공감할 수 있는 이야기로 풀어줘. 가볍게 시작해서 학생이 스스로 생각해볼 수 있는 질문을 던져줘. "너라면 어떻게 할 것 같아?" 같은 말로 마무리해도 좋아. 100글자 수준 공손하게 존댓말쓰고, 인사하지 말고, 너라고 하지 말고, 이름을 불러줘. 한문장을 한줄에,  bold 사용하지마. ',
        },
        {
          system_prompt:
            '너는 창의적이고 호기심 많은 키위챗이야. 학생들에게 세상의 신기하고 흥미로운 이야기나 지식을 알려줘. 과학, 역사, 문화 등 다양한 주제를 다뤄줘.',
          user_prompt:
            '재미있는 상식이나 잘 몰랐던 이야기를 들려줘. 나의 이름은 ' +
            authReducer.student_name +
            '입니다.',
          additional_system_prompt:
            '내용에 따라 이모티콘 허용 학생들이 지루하지 않도록 흥미로운 사실이나 이야기를 짧고 간결하게 전달해줘. 그리고 "이런 사실, 알고 있었어?" 같은 질문으로 학생들의 호기심을 자극해줘. 100글자 수준 공손하게 존댓말쓰고, 인사하지 말고, 너라고 하지 말고, 이름을 불러줘. 한문장을 한줄에,  bold 사용하지마. ',
        },
        {
          system_prompt:
            '너는 긍정적이고 따뜻한 마음을 가진 키위챗이야. 학생들에게 하루를 긍정적으로 시작할 수 있는 좋은 말이나 격려를 해줘.',
          user_prompt:
            '하루를 기분 좋게 시작할 수 있는 말을 해줘. 나의 이름은 ' +
            authReducer.student_name +
            '입니다.',
          additional_system_prompt:
            '내용에 따라 이모티콘 허용  단순히 "화이팅!"이라고 하기보다는, 학생들이 스스로 자신감을 가질 수 있도록 따뜻하고 공감되는 말을 해줘. 일상에서 작은 행복을 찾을 수 있는 방법도 함께 이야기해줘. 100글자 수준 공손하게 존댓말쓰고, 인사하지 말고, 너라고 하지 말고, 이름을 불러줘. 한문장을 한줄에,  bold 사용하지마. ',
        },
        {
          system_prompt:
            '너는 문해력과 어휘력을 키워주는 키위챗이야. 학생들에게 일상에서 사용할 수 있는 다양한 단어와 그 단어의 의미, 사용 방법을 재미있게 설명해줘. 글을 읽고 이해하는 능력과 단어를 더 잘 사용할 수 있도록 도와줘.',
          user_prompt:
            '새로운 단어와 그 단어를 어떻게 활용할 수 있는지 알려줘. 나의 이름은 ' +
            authReducer.student_name +
            '입니다.',
          additional_system_prompt:
            ' 내용에 따라 이모티콘 허용. 학생들이 쉽게 이해할 수 있도록 단어의 뜻과 예시를 간단하게 설명해줘. 그 단어가 일상생활에서 어떻게 사용될 수 있는지도 함께 이야기해줘. 마지막에는 "이 단어를 사용해서 짧은 문장을 만들어볼래?"와 같은 질문으로 학생들이 스스로 연습해볼 수 있도록 해줘. 100글자 수준 공손하게 존댓말쓰고, 인사하지 말고, 너라고 하지 말고, 이름을 불러줘. 한문장을 한줄에,  bold 사용하지마. ',
        },

        {
          system_prompt:
            '너는 문해력과 어휘력을 키워주는 키위챗이야. 학생들에게 일상에서 사용할 수 있는 다양한 단어와 그 단어의 의미, 사용 방법을 재미있게 설명해줘. 글을 읽고 이해하는 능력과 단어를 더 잘 사용할 수 있도록 도와줘.',
          user_prompt:
            '글쓰기를 잘하는 방법, 문해력을 키우는 방법의 팁을 알려줘. 나의 이름은 ' +
            authReducer.student_name +
            '입니다.',
          additional_system_prompt:
            '내용에 따라 이모티콘 허용. 학생들이 쉽게 이해할 수 있도록 단어의 뜻과 예시를 간단하게 설명해줘. 그 단어가 일상생활에서 어떻게 사용될 수 있는지도 함께 이야기해줘. 마지막에는 "이 단어를 사용해서 짧은 문장을 만들어볼래?"와 같은 질문으로 학생들이 스스로 연습해볼 수 있도록 해줘. 100글자 수준 공손하게 존댓말쓰고, 인사하지 말고, 너라고 하지 말고, 이름을 불러줘. 한문장을 한줄에,  bold 사용하지마. ',
        },
      ];

      const randomPrompt =
        promptSets[Math.floor(Math.random() * promptSets.length)];

      const gptResponse = await APIUtils.ChatGPT(
        0.8,
        randomPrompt.system_prompt,
        randomPrompt.user_prompt,
        randomPrompt.additional_system_prompt,
      );

      if (gptResponse?.status === 200 && gptResponse?.data?.ret_code === 1000) {
        const feedbackText = gptResponse.data.text
          .replace(/\n{2,}/g, '\n')
          .replace('**', '');
        setKeewiMsg(feedbackText);
      }
    } catch (error) {
      console.error('오류 발생:', error);
    }
  }

  return (
    <Box
      style={{
        leftMargin: '0.5rem',
        marginTop: '1.5rem',
        display: 'flex',
        flexDirection: 'column',
        width: '12rem',
      }}
    >
      <AuthTeacherCompletePopup
        visibility={completeVisibility}
        cancelEvent={completeCancel}
      />
      <AuthTeacherPopup
        visibility={authTeacherVisibility}
        cancelEvent={authTeacherCancel}
        confirmEvent={authTeacherConfirm}
      />
      <Box
        style={{
          fontWeight: 'bold',
          fontSize: 'clamp(1rem, 2vw, 1.3rem)',
          marginTop: '1rem',
          maxWidth: '10rem',
        }}
      >
        {authReducer.student_name} <font color="#68737d"> 님</font>
      </Box>
      <Box style={{color: '#68737d', fontSize: 'clamp(0.5rem, 2vw, 1rem)'}}>
        {authReducer.student_email.includes('@')
          ? authReducer.student_email
          : 'ID : ' + authReducer.student_email}
      </Box>

      <Button
        style={{
          marginTop: '2.5rem',
          border: '1px solid #898989',
          color: '#49545c',
          fontWeight: 'bold',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
        onClick={() => {
          setAuthTeacherVisibility(true);
        }}
      >
        선생님 연결하기
      </Button>
      <Button
        style={{
          marginTop: '1rem',
          color: 'white',
          backgroundColor: '#00C500',
          fontWeight: 'bold',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
        onClick={() => {
          navigate('/writing1');
        }}
      >
        AI 글쓰기 연습
      </Button>
      <Box
        style={{
          marginTop: '3rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'start',
        }}
      >
        <Button
          style={{
            color: props.currentFragment == 1 ? '#00C500' : '#2f3941',
            backgroundColor:
              props.currentFragment == 1 ? 'rgba(0, 197, 0, 0.10)' : '',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '0.7rem 1rem',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
          onClick={() => {
            props.clickEvent(1);
          }}
        >
          최근 과제
        </Button>

        <Button
          style={{
            color: props.currentFragment == 3 ? '#00C500' : '#2f3941',
            backgroundColor:
              props.currentFragment == 3 ? 'rgba(0, 197, 0, 0.10)' : '',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '0.7rem 1rem',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
          onClick={() => {
            props.clickEvent(3);
          }}
        >
          나의 선생님
        </Button>
        <Button
          style={{
            color: props.currentFragment == 4 ? '#00C500' : '#2f3941',
            backgroundColor:
              props.currentFragment == 4 ? 'rgba(0, 197, 0, 0.10)' : '',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '0.7rem 1rem',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
          onClick={() => {
            props.clickEvent(4);
          }}
        >
          제출한 과제
        </Button>
        <Button
          style={{
            color: props.currentFragment == 5 ? '#00C500' : '#2f3941',
            backgroundColor:
              props.currentFragment == 5 ? 'rgba(0, 197, 0, 0.10)' : '',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '0.7rem 1rem',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
          onClick={() => {
            props.clickEvent(5);
          }}
        >
          연습한 글
        </Button>
        <Button
          style={{
            color: props.currentFragment == 2 ? '#00C500' : '#2f3941',
            backgroundColor:
              props.currentFragment == 2 ? 'rgba(0, 197, 0, 0.10)' : '',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '0.7rem 1rem',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
          onClick={() => {
            props.clickEvent(2);
          }}
        >
          계정 관리
        </Button>
      </Box>

      <Box
        style={{
          color: '#555555',
          backgroundColor: authReducer.keewi_msg === '' ? 'white' : '#fafafa',
          width: 'fit-content',
          maxWidth: '80%',
          padding: '0.7rem 1rem',
          fontSize: '0.875rem',
          whiteSpace: 'pre-line',
          borderRadius: '15px',
          position: 'relative',
          marginTop: '3rem',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
          border: '1px solid #E6FAE6',
        }}
      >
        {keewi_msg}
        <div
          style={{
            position: 'absolute',
            bottom: '-15px',
            right: '15px',
            width: '0',
            height: '0',
            borderTop: '12px solid #DADADA',
            borderLeft: '12px solid transparent',
            borderRight: '12px solid transparent',
            filter: 'drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.1))',
          }}
        />
      </Box>
      <Button
        style={{
          display: keewi_msg === '' ? 'none' : 'block',
          width: '100%',
          justifyContent: 'flex-start',
          padding: '0.7rem 1rem',
          fontSize: '0.8rem',
          marginTop: '0.5rem',
          position: 'relative',
        }}
        onClick={() => runKeewiBirdSpeech()}
      >
        <img
          src={KeewiBird}
          alt="Keewi Bird"
          style={{
            display: keewi_msg === '' ? 'none' : 'block',
            width: '3.5rem',
            height: '5rem',
            marginLeft: '2rem',
            position: 'relative',
            zIndex: 2,
          }}
        />
        <div
          style={{
            display: keewi_msg === '' ? 'none' : 'block',
            width: '3.5rem', // 그림자 크기 확대
            height: '0.8rem', // 그림자의 높이도 살짝 확대
            backgroundColor: 'rgba(0, 0, 0, 0.25)', // 살짝 더 진하게
            borderRadius: '50%',
            position: 'absolute',
            bottom: '0.2rem', // 그림자 위치를 더 붙게
            left: '2rem', // 이미지와 정렬
            zIndex: 1,
            filter: 'blur(6px)', // 더 자연스럽게 퍼지도록
          }}
        />
      </Button>
    </Box>
  );
};
SidebarView.propTypes = {
  currentFragment: PropTypes.number,
  clickEvent: PropTypes.func,
};

export default SidebarView;
