import React, {useEffect, useRef} from 'react';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
const cloudColor = [
  '#1f77b4',
  '#aec7e8',
  '#ff7f0e',
  '#ffbb78',
  '#2ca02c',
  '#98df8a',
  '#d62728',
  '#ff9896',
  '#9467bd',
  '#c5b0d5',
  '#8c564b',
  '#c49c94',
  '#e377c2',
  '#f7b6d2',
  '#7f7f7f',
  '#c7c7c7',
  '#bcbd22',
  '#dbdb8d',
  '#17becf',
  '#9edae5',
];

const ItemView = props => {
  const getFontSize = value => `${Math.min(15 + value * 2.5, 40)}px`;

  const getRandomColor = () =>
    cloudColor[Math.floor(Math.random() * cloudColor.length)];

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: props.height || 300,
        margin: '0rem 2rem',
      }}
      className="flex justify-center items-center relative"
    >
      <div className="flex flex-wrap gap-2 p-4 justify-center items-center">
        {props.data.map((word, index) => (
          <span
            key={index}
            style={{
              fontSize: getFontSize(word.value),
              marginLeft: '0.5rem',
              color: getRandomColor(),
            }}
            className="flex items-center justify-center"
          >
            {word.text}
          </span>
        ))}
      </div>
    </Box>
  );
};

ItemView.propTypes = {
  data: PropTypes.array,
  height: PropTypes.string,
};

export default ItemView;
