import React, {useState, forwardRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Box, Button, TextField} from '@mui/material';
import emojiRegex from 'emoji-regex';
import {StringUtils} from 'common/utils/StringUtils';

const ItemView = props => {
  const backgroundOption = [
    '#C6DBDA',
    '#FEE1E8',
    '#FED7C3',
    '#F6EAC2',
    '#ECD5E3',
    '#D4F0F0',
    '#8FCACA',
    '#CCE2CB',
    '#FCB9AA',
    '#FFDECC',
  ];

  const [screenExtend, setScreenExtend] = useState(false);
  const [essayText, setEssayText] = useState(props.currentText);
  const [coloredText, setColoredText] = useState([]);

  const handleEssayInput = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 10000) {
        inputVal = inputVal.substring(0, 10000);
      }
      setEssayText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };

  const highlightText = inputText => {
    let indexArray = [];
    props.highlightWordList.map((val, idx) => {
      let indices = [...indexOfSubstrings(inputText, val)];
      const infoArray = indices.map(index => {
        return {
          index: index,
          text: val,
          color: backgroundOption[idx % 10],
        };
      });
      indexArray = [...indexArray, ...infoArray];
    });
    indexArray = indexArray.sort((a, b) => {
      return a.index - b.index;
    });
    let resultArray = [];
    let prevIndex = 0;
    for (let i = 0; i < indexArray.length; i++) {
      if (prevIndex > indexArray[i].index) {
        continue;
      }
      // 일반 텍스트
      const normalText = inputText.slice(prevIndex, indexArray[i].index);
      resultArray.push(
        <span key={'para' + i}>{processItalic(normalText)}</span>,
      );
      // 강조 텍스트
      resultArray.push(
        <span
          key={'coloring' + i}
          style={{
            backgroundColor: indexArray[i].color,
          }}
        >
          {inputText.slice(
            indexArray[i].index,
            indexArray[i].index + indexArray[i].text.length,
          )}
        </span>,
      );
      prevIndex = indexArray[i].index + indexArray[i].text.length;
    }
    if (indexArray.length === 0) {
      resultArray.push(<span key={'all'}>{processItalic(inputText)}</span>);
    } else {
      resultArray.push(
        <span key={'paralast'}>
          {processItalic(inputText.slice(prevIndex))}
        </span>,
      );
    }
    setColoredText(resultArray);
  };

  // ###으로 시작하는 문장 감지 후 이탤릭 처리
  const processItalic = text => {
    const parts = text.split(/(###.*?)(?=\n|$)/g);
    return parts.map((part, idx) => {
      if (part.startsWith('###')) {
        return (
          <span key={idx} style={{fontStyle: 'italic', color: '#B1B1B1'}}>
            {part}
          </span>
        );
      }
      return part;
    });
  };

  const indexOfSubstrings = function* (str, searchValue) {
    let i = 0;
    while (true) {
      const r = str.indexOf(searchValue, i);
      if (r !== -1) {
        yield r;
        i = r + 1;
      } else return;
    }
  };

  useEffect(() => {
    highlightText(essayText);
  }, [essayText]);
  return (
    <Box
      style={{
        height: screenExtend ? '85vh' : '45vh',
        width: '80%',
        position: 'sticky',
        bottom: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        zIndex: 3,
        backgroundColor: '#E2FFE2',
        padding: '0.5rem 5%',
        boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.25)',
        borderRadius: '1.625rem 1.625rem 0rem 0rem',
      }}
    >
      <Box
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '0.1rem',
        }}
      >
        <Box
          style={{
            fontWeight: 'bold',
            color: 'black',
            marginLeft: '0.5rem',
            fontSize: '1.125rem',
          }}
        >
          {'본문 수정'} <span style={{color: '#00C500'}}></span>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '2rem',
          }}
        >
          <Button
            variant="filled"
            style={{
              cursor: 'pointer',
              color: 'white',
              backgroundColor: '#87929D',
              borderRadius: '6.25rem',
              padding: '0.4rem 1.5625rem',
              fontWeight: 'bold',
              fontSize: '0.875rem',
              boxShadow: '0px 5px 10px 3px rgba(0, 0, 0, 0.15)',
              zIndex: 2,
            }}
            onClick={() => {
              props.cancelEvent();
            }}
          >
            닫기
          </Button>
          <Button
            variant="filled"
            style={{
              cursor: 'pointer',
              color: 'white',
              backgroundColor:
                props.maxTryLimit == 0 ||
                props.currentTryCount < props.maxTryLimit
                  ? '#00C500'
                  : props.maxTryLimit != 0 &&
                    props.currentTryCount > props.maxTryLimit
                  ? '#35D2BB'
                  : '#279EFF',
              borderRadius: '6.25rem',
              padding: '0.4rem 1.5625rem',
              fontWeight: 'bold',
              fontSize: '0.875rem',
              boxShadow: '0px 5px 10px 3px rgba(0, 0, 0, 0.15)',
              zIndex: 2,
            }}
            onClick={() => {
              // 24.10.31 문단 들여쓰기 허용, CHOYH
              // const trimmedText = essayText.trim();
              const trimmedText = essayText;
              if (trimmedText != '') {
                props.analyzeEvent(trimmedText);
              }
            }}
          >
            {props.maxTryLimit == 0
              ? 'AI 피드백 받기'
              : props.currentTryCount != props.maxTryLimit
              ? '수정하기'
              : '최종 제출하기'}
          </Button>
          <Button
            variant="filled"
            style={{
              cursor: 'pointer',
              color: 'white',
              backgroundColor: '#ff9900',
              borderRadius: '6.25rem',
              padding: '0.4rem 1.5625rem',
              fontWeight: 'bold',
              fontSize: '0.875rem',
              boxShadow: '0px 5px 10px 3px rgba(0, 0, 0, 0.15)',
              zIndex: 2,
            }}
            onClick={() => {
              setScreenExtend(curr => !curr);
            }}
          >
            {screenExtend ? '화면 줄이기' : '화면 늘리기'}
          </Button>
        </Box>
      </Box>
      <Box
        style={{
          width: '100%',
          height: '100%',
          minHeight: '3rem',
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
          paddingBottom: '0.1rem',
          position: 'relative',
        }}
      >
        <Box
          style={{
            position: 'absolute',
            right: '0.5rem',
            bottom: '0.1rem',
            fontSize: '0.9rem',
            color: '#A8B1B7',
          }}
        >
          <span style={{color: '#00C500'}}>
            {StringUtils.getBodyTextLenth(essayText)}
          </span>
          /10000
        </Box>
        <Box
          style={{
            position: 'relative',
            width: '100%',
            overflowY: 'scroll',
            fontSize: '1rem',
            backgroundColor: '#ffffff',
            border: '1px solid var(--01-gray-07, #E9EBED)',
            boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
            height: 'calc(100% - 2rem)',
          }}
        >
          <Box
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
          >
            <TextField
              autoComplete="off"
              multiline
              size="small"
              variant="outlined"
              fullWidth
              sx={{
                height: '100%',
                '& .MuiInputBase-root': {
                  zIndex: 1,
                  height: '100%',
                  justifyContent: 'start',
                  alignItems: 'start',
                  padding: '1rem',
                  lineHeight: '1.5rem',
                  color: 'transparent',
                  background: 'transparent',
                  caretColor: 'black',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                  },

                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none',
                  },
                },
              }}
              label={
                essayText == ''
                  ? '평가할 글을 입력해주세요. 엔터 키[↲] 입력 시 문단이 나뉘었다고 인식합니다. 문장 부호 외 특수문자/이모지가 포함된 경우, 평가 결과가 정상적으로 출력되지 않을 수 있습니다.'
                  : ''
              }
              InputLabelProps={{
                style: {
                  color: 'gray',
                  fontSize: '1rem',
                  whiteSpace: 'pre-wrap',
                  border: 'none',
                },
                shrink: false,
              }}
              InputProps={{
                style: {
                  height: '100%',
                  fontSize: '1rem',
                  border: 'none',
                },
              }}
              value={essayText}
              onChange={handleEssayInput}
            ></TextField>
          </Box>
          <Box
            style={{
              zIndex: 0,
              position: 'absolute',
              padding: '1rem',
              whiteSpace: 'pre-wrap',
              lineHeight: '1.5rem',
              color: 'black',
            }}
          >
            {coloredText}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  currentText: PropTypes.string,
  analyzeEvent: PropTypes.func,
  cancelEvent: PropTypes.func,
  highlightWordList: PropTypes.array,
  currentTryCount: PropTypes.number,
  maxTryLimit: PropTypes.number,
};
export default ItemView;
