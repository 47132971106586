import React, {useEffect, useState, useMemo, useRef} from 'react';
import {Box, Grid, TextField, Button, IconButton} from '@mui/material';
import {useLocation} from 'react-router-dom';
import APIUtils from 'common/utils/APIUtils';
import {AnalysisText} from 'view/result/AnalysisText';
import {StringUtils} from 'common/utils/StringUtils';
import {NumberUtils} from 'common/utils/NumberUtils';
import emojiRegex from 'emoji-regex';
import {
  MarginBoxComponent,
  TitleComponent2_2,
  SingleLineComponent2,
  SingleLineTitle,
  WhiteBox,
} from 'view/common/Components';
import ApiLoading from 'view/common/ApiLoading';
import CommentItem from 'view/comment/CommentItem';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {throttle} from 'lodash';
import HeaderView from 'view/common/Header';
import FooterView from 'view/common/Footer';
import SubmitDonePopup from 'view/popup/SubmitDonePopup';
import SubmitInvalidPopup from 'view/popup/SubmitInvalidPopup';
import SubmitLateDonePopup from 'view/popup/SubmitLateDonePopup';
import SubmitLateInvalidPopup from 'view/popup/SubmitLateInvalidPopup';
import SubmitAnywayDonePopup from 'view/popup/SubmitAnywayDonePopup';

import RadarChart from 'view/common/RadarChart';
import KeewiCloud from 'view/common/KeewiCloud_None';
import EssayEditor from 'view/result/EssayEditor';
import PencilIcon2 from 'asset/imageV2/icon_pencil2.svg';
import SubmitIcon from 'asset/imageV2/icon_submit.svg';
import ToastPopup from 'view/popup/ToastPopup';
import ToastPopup2 from 'view/popup/ToastPopup2';
import KeewiBird from 'asset/imageV2/keewi-bird-right.png';
import ChatOverlay from 'view/chat/ChatOverayRewrite2';
import IconKeewichat from 'asset/imageV2/icon_keewichat.svg';

const MainPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);

  const [commentParagraph, setCommentParagraph] = useState([]);
  const [highlightWords, setHighlightWords] = useState([]);

  const [loading, setLoading] = useState(true);
  const [onError, setOnError] = useState(false);
  const [currentText, setCurrentText] = useState(location.state.text);
  const [isScrollTop, setIsScrollTop] = useState(true);
  const [bottomOverlay, setBottomOverlay] = useState(true);

  const [taskID, setTaskID] = useState(0);
  const [taskDateLeft, setTaskDateLeft] = useState(0);
  const [taskDueLeft, setTaskDueLeft] = useState(0);
  const [taskDateText, setTaskDateText] = useState('');
  const [taskTitleText, setTaskTitleText] = useState('');
  const [taskMessage, setTaskMessage] = useState('');
  const [preKeewiChat, setPreKeewiChat] = useState(0);

  const [submitButtonColor, setsubmitButtonColor] = useState('#00C500');
  const [submitButtonText, setsubmitButtonText] = useState('제출하기');

  const [writingJanre, setWritingJanre] = useState('');
  const [titleText, setTitleText] = useState('');
  const [titleEditable, setTitleEditable] = useState(false);
  const [keewiEvalResult, setKeewiEvalResult] = useState(null);

  const [textCountChecked, setTextCountChecked] = useState(false);
  const [textCountMin, setTextCountMin] = useState('');
  const [textCountMax, setTextCountMax] = useState('');

  const [requiredWordChecked, setRequiredWordChecked] = useState(false);
  const [requiredWord, setRequiredWord] = useState('');
  const [unfoundRequiredWord, setUnfoundRequiredWord] = useState('');

  const [maxEvalTry, setMaxEvalTry] = useState(1);
  const [maxTryLimit, setMaxTryLimit] = useState(0);
  const [currentEvalTry, setCurrentEvalTry] = useState(1);
  const [saveEvalResultDone, setSaveEvalResultDone] = useState(false);
  const [lastEvalTry, setLastEvalTry] = useState(0);

  const [toastText, setToastText] = useState('');
  const [toastVisibility, setToastVisibility] = useState(false);
  const toastTimeoutRef = useRef(null); // `setTimeout`을 추적할 ref

  const [analysisComplete, setAnalysisComplete] = useState(false);

  const [toast2Text, setToast2Text] = useState('');
  const [toast2FontSizeText, setToast2FontSizeText] = useState('1rem');
  const [toast2Visibility, setToast2Visibility] = useState(false);
  const toast2TimeoutRef = useRef(null);

  const [keewiComment, setKeewiComment] = useState('');
  const [toast3Text, setToast3Text] = useState('');
  const [toast3FontSizeText, setToast3FontSizeText] = useState('1rem');
  const [toast3Visibility, setToast3Visibility] = useState(false);
  const toast3TimeoutRef = useRef(null);

  const [prevScore, setPrevScore] = useState(0);
  const [chatVisibility, setChatVisibility] = useState(false);
  const [chatTask, setChatTask] = useState(undefined);

  function Toast2Show(text, time, sizeStr) {
    setToast2Text(text);
    setToast2FontSizeText(sizeStr);
    setToast2Visibility(true);

    if (toast2TimeoutRef.current) {
      clearTimeout(toast2TimeoutRef.current);
    }

    toast2TimeoutRef.current = setTimeout(() => {
      setToast2Visibility(false);
    }, time);
  }

  function Toast3Show(text, time, sizeStr) {
    let selectedSentence = text;
    const regex = new RegExp(`^(.{20,}?)(\\n|\\. |! |$)`);
    const match = text.match(regex);
    if (match) {
      selectedSentence = match[1];
    }

    setToast3Text(selectedSentence);
    setToast3FontSizeText(sizeStr);
    setToast3Visibility(true);

    if (toast3TimeoutRef.current) {
      clearTimeout(toast3TimeoutRef.current);
    }

    toast3TimeoutRef.current = setTimeout(() => {
      setToast3Visibility(false);
    }, time);
  }

  function ToastShow(text, time) {
    setToastText(text);
    setToastVisibility(true);

    // 기존 `setTimeout`이 실행 중이라면 취소
    if (toastTimeoutRef.current) {
      clearTimeout(toastTimeoutRef.current);
    }

    // 새로운 `setTimeout` 설정 후 페이지 이동
    toastTimeoutRef.current = setTimeout(() => {
      setToastVisibility(false);
    }, time);
  }
  const level_mark = {
    1: 'F',
    2: 'D',
    3: 'D+',
    4: 'C',
    5: 'C+',
    6: 'B',
    7: 'B+',
    8: 'A',
    9: 'A+',
  };
  const [labelText, setLabelText] = useState('');
  const [analyText, setAnalyText] = useState('');
  const [isGPT, setIsGPT] = useState(false);
  const [prevKeewi6TraitLevels, setPrevKeewi6TraitLevels] =
    useState('기록 없음');
  const [prevKeewiScore, setPrevKeewiScore] = useState('기록 없음');

  const [keewiScore, setKeewiScore] = useState({
    겹치는단어비율: 0,
    단어의평균길이: 0,
    문단별문장수: 0,
    문장별단어수: 0,
    분석오류문장수: 0,
    전체글자수: 0,
    전체문장수: 0,
    전체문단수: 0,
    흐름이자연스러운정도: 0,
    긴문장의수: 0,
    독자나이: 0,
  });

  const [radarData, setRadarData] = useState({
    labels: [],
    datasets: [],
  });

  const [wordCloudData, setWordCloudData] = useState([
    {text: '키위', value: 1},
    {text: '글쓰기', value: 2},
    {text: '평가', value: 3},
    {text: '엔진', value: 4},
    {text: '피드백', value: 5},
  ]);

  const handleTitle = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 100) {
        inputVal = inputVal.substring(0, 100);
      }
      setTitleText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };

  const memoizedWordCloud = useMemo(
    () => <KeewiCloud data={wordCloudData} height={'15rem'} />,
    [wordCloudData],
  );

  const [tScore, setTScore] = useState(0);
  const [tryCount, setTryCount] = useState(0);
  const [submitInvalidVisibility, setSubmitInvalidVisibility] = useState(false);
  const [submitDoneVisibility, setSubmitDoneVisibility] = useState(false);
  const [submitAnywayDoneVisibility, setSubmitAnywayDoneVisibility] =
    useState(false);
  const [submitLateInvalidVisibility, setSubmitLateInvalidVisibility] =
    useState(false);
  const [submitLateDoneVisibility, setSubmitLateDoneVisibility] =
    useState(false);

  function submitInvalidCancel() {
    setSubmitInvalidVisibility(false);
    setBottomOverlay(false);
  }

  function submitDoneConfirm() {
    setSubmitDoneVisibility(false);
    setSubmitAnywayDoneVisibility(false);
    navigate('/mypage');
  }

  function submitLateDoneConfirm() {
    setSubmitLateDoneVisibility(false);
    navigate('/mypage');
  }

  function goBackHome() {
    navigate('/mypage');
  }

  function submitLateInvalidConfirm() {
    setSubmitLateDoneVisibility(false);
    const this_task_id = taskID;
    const submitResult = async () => {
      try {
        const response = await APIUtils.TaskSubmit(
          authReducer.student_id,
          this_task_id,
        );
        return response;
      } catch (err) {
        console.log(err);
        return null;
      }
    };
    submitResult().then(res => {
      if (res && res.status === 200 && res.data.ret_code === 1000) {
        setSubmitDoneVisibility(true);
      }
    });
    navigate('/mypage');
  }

  function submitLateInvalidCancel() {
    setSubmitLateInvalidVisibility(false);
    setBottomOverlay(false);
  }

  function transformWordCloudData(wordCloud) {
    // 원본 데이터의 weight 합계 계산
    const totalWeight = wordCloud
      .slice(0, 40)
      .reduce((sum, item) => sum + item.weight, 0);

    // weight를 100으로 정규화 (스케일링)
    const transformedData = wordCloud.slice(0, 40).map(item => ({
      text: item.name,
      value: (item.weight / totalWeight) * 100,
    }));

    // 변환된 데이터를 설정
    setWordCloudData(transformedData);
  }

  async function getTaskInfo(
    task_id,
    savedTitle,
    savedJanre,
    savedText,
    fromPrev,
  ) {
    const taskResult = async () => {
      try {
        const response = await APIUtils.TaskCall(
          authReducer.student_id,
          task_id,
        );

        setMaxTryLimit(response.data.max_try);
        setMaxEvalTry(response.data.eval_try);

        if (response.status == 200 && response.data.ret_code == 1000) {
          const dateLeft = StringUtils.getDateDiff(response.data.task_end_time);
          const timeLeft = StringUtils.getTimeDiffTZ(
            response.data.task_end_time,
            response.data.tz,
          );
          setTaskDateLeft(dateLeft);
          setTaskDueLeft(timeLeft);
          setsubmitButtonColor(timeLeft > 0 ? '#00C500' : 'red');
          setsubmitButtonText(timeLeft > 0 ? '제출하기' : '늦은제출');
          setTaskDateText(
            StringUtils.getDateStringKr(response.data.task_start_time) +
              ' ~ ' +
              StringUtils.getDateStringKr(response.data.task_end_time),
          );
          setTaskTitleText(
            '[' + response.data.task_janre + '] ' + response.data.task_name,
          );
          setChatTask(response.data);
          setTaskMessage(response.data.task_message);
          setTextCountChecked(response.data.length_check == 1);
          setTextCountMin(response.data.min_length);
          setTextCountMax(response.data.max_length);
          setRequiredWordChecked(response.data.keyword_check == 1);
          setRequiredWord(response.data.keyword);
          setMaxTryLimit(response.data.max_try);
          setPreKeewiChat(response.data.pre_keewichat);
          if (response.data.pre_keewichat == 0) setChatVisibility(false);
          let curText = '';
          let analyzeResponse;

          if (
            (response.data.title == '' && response.data.text == '') ||
            fromPrev
          ) {
            setMaxEvalTry(response.data.eval_try + 1);
            setCurrentEvalTry(response.data.eval_try + 1);
            curText = savedText;
            analyzeResponse = await analyzeEvent(
              savedTitle,
              savedJanre,
              savedText,
              task_id,
              true,
            );
          } else {
            setMaxEvalTry(response.data.eval_try);
            setCurrentEvalTry(response.data.eval_try);
            curText = response.data.text;
            analyzeResponse = await analyzeEvent(
              response.data.title,
              savedJanre,
              response.data.text,
              task_id,
              false,
            );
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    await taskResult(); // ✅ `await` 추가하여 실행 순서 보장
  }

  function setAnalysisInfoFromKeewiApiResult(res, anlayzedText) {
    setKeewiEvalResult(res.data.eval_result);
    const keewiScore = {
      겹치는단어비율: NumberUtils.getAnalysisNumber(
        res.data.eval_result.겹치는단어비율 * 100,
      ),
      단어의평균길이: NumberUtils.getAnalysisNumber(
        res.data.eval_result.단어의평균길이,
      ),
      문단별문장수: NumberUtils.getAnalysisNumber(
        res.data.eval_result.문단별문장수,
      ),
      문장별단어수: NumberUtils.getAnalysisNumber(
        res.data.eval_result.문장별단어수,
      ),
      분석오류문장수: res.data.eval_result.분석오류문장수,
      // 전체글자수: textLength,
      전체글자수: res.data.eval_result.전체글자수,
      전체문장수: res.data.eval_result.전체문장수,
      전체문단수: res.data.eval_result.전체문단수,
      흐름이자연스러운정도: NumberUtils.getAnalysisNumber(
        res.data.eval_result.흐름이자연스러운정도 * 100,
      ),
      긴문장의수: res.data.eval_result.긴문장의수 ?? 0,
      독자나이: res.data.eval_result.독자나이 ?? 10,
    };

    setPrevKeewiScore(keewiScore);
    setPrevKeewi6TraitLevels(prevKeewi6TraitLevels);
    setKeewiScore(keewiScore);
    const wholeComment = [
      ...res.data.eval_result.trait_6_comment,
      ...res.data.eval_result.trait_5_comment,
      ...res.data.eval_result.trait_4_comment,
      ...res.data.eval_result.trait_3_comment,
      ...res.data.eval_result.trait_2_comment,
      ...res.data.eval_result.trait_1_comment,
    ];
    setHighlightWords(AnalysisText.createHighlightWordList(wholeComment));
    setCommentParagraph(
      AnalysisText.createComment(
        anlayzedText,
        res.data.eval_result.paragraph_texts,
        wholeComment,
      ),
    );
    setLabelText(AnalysisText.createHtmlTextLabel(res.data.eval_result.type));
    setAnalyText(
      AnalysisText.createHtmlTextAnaly(
        res.data.eval_result.paragraph_texts,
        res.data.eval_result.type,
      ),
    );

    setTScore(NumberUtils.getAnalysisNumber(res.data.eval_result.keewi_score));
    setIsGPT(res.data.eval_result.is_gpt_text);

    let datasets = [];
    datasets = [
      {
        data: [
          res.data.eval_result.trait_1_score,
          res.data.eval_result.trait_2_score,
          res.data.eval_result.trait_3_score,
          res.data.eval_result.trait_4_score,
          res.data.eval_result.trait_5_score,
          res.data.eval_result.trait_6_score,
        ],
        fill: true,
        backgroundColor: ({chart: {ctx}}) => {
          const bg = ctx.createLinearGradient(90, 90, 200, 200);
          bg.addColorStop(0, 'rgba(178, 223, 51, 0.5)');
          bg.addColorStop(1, 'rgba(255, 153, 0, 0.5)');
          return bg;
        },
        borderColor: '#FF9900',
        borderWidth: 1,
        pointStyle: 'circle',
        pointBackgroundColor: 'rgba(255, 138, 0, 1)',
        pointBorderColor: 'rgba(255, 255, 255, 0)',
      },
    ];
    const newRadarData = {
      labels: ['문법', '어휘', '표현', '문장', '조직', '주제'],
      datasets: datasets,
    };
    setRadarData(newRadarData);
    transformWordCloudData(res.data.eval_result.word_cloud); // 데이터를 변환하고 상태에 설정
    setAnalysisComplete(true);
    setLoading(false);
  }

  async function loadSavedEval(input_task_id, input_eval_try) {
    setLoading(true);
    const loadAnalysisResult = async (task_id, eval_try) => {
      try {
        const response = await APIUtils.TaskResult(
          authReducer.student_id,
          task_id,
          eval_try,
        );
        setAnalysisInfoFromKeewiApiLoad(response);
        return response;
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    try {
      const res = await loadAnalysisResult(input_task_id, input_eval_try); // ✅ `await` 추가
    } catch (err) {
      console.log(err);
    }
  }

  function setAnalysisInfoFromKeewiApiLoad(res) {
    setKeewiEvalResult(res.data.eval_result);
    setTitleText(res.data.eval_result.title);
    setCurrentText(res.data.eval_result.originalText);
    setHighlightWords(
      AnalysisText.createHighlightWordListFromSaved(
        res.data.eval_result.commentList,
      ),
    );

    setCommentParagraph(
      AnalysisText.createCommentFromSaved(
        res.data.eval_result.originalText,
        res.data.eval_result.commentList,
      ),
    );
    setLabelText(AnalysisText.createHtmlTextLabel(res.data.eval_result.type));

    setAnalyText(
      AnalysisText.createHtmlTextAnaly(
        res.data.eval_result.paragraph_texts,
        res.data.eval_result.type,
      ),
    );

    setTScore(res.data.eval_result.score);
    setIsGPT(res.data.eval_result.is_gpt_text);

    let datasets = [];
    datasets = [
      {
        data: [
          AnalysisText.getNumericScore(res.data.eval_result.trait_1_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_2_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_3_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_4_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_5_score),
          AnalysisText.getNumericScore(res.data.eval_result.trait_6_score),
        ],
        fill: true,
        backgroundColor: ({chart: {ctx}}) => {
          const bg = ctx.createLinearGradient(90, 90, 200, 200);
          bg.addColorStop(0, 'rgba(178, 223, 51, 0.5)');
          bg.addColorStop(1, 'rgba(255, 153, 0, 0.5)');
          return bg;
        },
        borderColor: '#FF9900',
        borderWidth: 1,
        pointStyle: 'circle',
        pointBackgroundColor: 'rgba(255, 138, 0, 1)',
        pointBorderColor: 'rgba(255, 255, 255, 0)',
      },
    ];
    const newRadarData = {
      labels: ['문법', '어휘', '표현', '문장', '조직', '주제'],
      datasets: datasets,
    };
    setRadarData(newRadarData);
    transformWordCloudData(res.data.eval_result.word_cloud); // 데이터를 변환하고 상태에 설정
    setLoading(false);
  }

  async function analyzeEvent(
    new_title,
    new_janre,
    new_text,
    new_task_id,
    flag_save,
  ) {
    const trimmedText = StringUtils.getTrimmedBody(new_text);
    setLoading(true);
    setCurrentText(new_text);
    setTitleText(new_title);
    setWritingJanre(new_janre);

    try {
      const response = await APIUtils.TaskEval(
        authReducer.student_id,
        new_task_id,
        new_title,
        new_janre,
        trimmedText,
      );

      if (response?.data?.ret_code === 1000) {
        setAnalysisInfoFromKeewiApiResult(response, trimmedText);
        const textLength = StringUtils.getBodyTextLenth(currentText); // 글자 수 확인
        const missingKeywords = requiredWord
          .split(',')
          .filter(keyword => !currentText.includes(keyword) && keyword !== '');
        const bad_submit_status =
          (textCountChecked &&
            (textLength < textCountMin || textLength > textCountMax)) ||
          (requiredWordChecked && missingKeywords.length > 0)
            ? 0
            : 1;

        const jsonResult = AnalysisText.createSavingJsonFromEvalResult(
          response.data.eval_result,
          new_title,
          trimmedText,
          '성인',
          authReducer.student_name,
          bad_submit_status,
        );
        setSaveEvalResultDone(false);
        if (flag_save) {
          try {
            const saveResponse = await APIUtils.TaskEvalSave(
              authReducer.student_id,
              new_task_id,
              jsonResult,
            );
            if (
              saveResponse?.status === 200 &&
              saveResponse.data.ret_code === 1000
            ) {
              setSaveEvalResultDone(true);
            }
          } catch (saveError) {
            console.log(saveError);
          }
        }
      }
    } catch (err) {
      setOnError(true);
    } finally {
      setLoading(false);
    }
  }

  const keewichat_comment = async (prevScore, keewiRes) => {
    const required_keyword = requiredWordChecked
      ? requiredWord
      : '필수 키워드 조건 없음';
    const required_length = textCountChecked
      ? `${textCountMin}~${textCountMax} 글자`
      : '제한 없음';
    const modify_status =
      maxTryLimit != 0
        ? `최대 수정 허용 횟수 : ${maxTryLimit} 중 현재 수정 횟수 : ${currentEvalTry}`
        : '제한 없음';
    const truncatedText =
      currentText.length > 2000
        ? currentText.slice(0, 1000) + '\n . . . \n' + currentText.slice(-1000)
        : currentText;

    const formatKeewiContent = `
    다음은 선생님이 과제를 설명한 것입니다.
    장르 : ${writingJanre}
    과제명 :  ${taskTitleText}
    과제 내용 :  ${writingJanre}
    필수 키워드 : ${required_keyword}
    필수 길이 : ${required_length}
    수정 횟수 : ${modify_status}

    다음은 학생이 과제로 작성한 글입니다. 
    이름 : ${authReducer.student_name}
    제목 : ${titleText}
    본문 : ${truncatedText}

    다음은 키위 글쓰기 평가 엔진이 자동 평가한 결과입니다. 
    ** 키위 엔진이 분석한 6 Traits of Writing 평가 **
    문법의 정확성 : ${keewiRes.trait_1_score} / 9
    어휘의 풍부성 : ${keewiRes.trait_2_score} / 9
    의도의 표현 : ${keewiRes.trait_3_score} / 9 
    문장 구사력 : ${keewiRes.trait_4_score} / 9 
    조직과 전개 : ${keewiRes.trait_5_score} / 9
    주제의 명확성 : ${keewiRes.trait_6_score} / 9

    ** 키위 엔진이 분석한 글의 통계 **
    겹치는 단어비율 : ${keewiRes.겹치는단어비율.toFixed(2)} / 1
    단어의 평균길이 : ${keewiRes.단어의평균길이.toFixed(1)} 글자
    문단별 문장수 : ${keewiRes.문단별문장수.toFixed(1)} 문장
    문장별 단어수: ${keewiRes.문장별단어수.toFixed(1)} 단어
    전체 글자수 : ${keewiRes.전체글자수} 글자
    전체 문장수 : ${keewiRes.전체문단수} 문장

    키위 엔진이 분석한 글의 최종 점수 : ${keewiRes.keewi_score}
    이전 버전 글의 점수 : ${prevScore}
  `;

    if (prevScore != 0 && prevScore < keewiRes.keewi_score) {
      try {
        const gptResponse = await APIUtils.ChatGPT(
          0.8,
          '너는 글쓰기 평가 전문가야. 학생이 AI 피드백을 받고 여러 번 글을 수정하는 과정에서 **실질적인 도움을 줄 수 있도록** 구체적인 피드백을 제공해 줘.  이름을 불러주면 좋겠어.',
          formatKeewiContent,
          '50자 이내로 짧지만 강렬하게 작성해 줘. 첫 문단에서는 점수가 올라간 것을 칭찬해줘. 학생의 글에서 **좋았던 점을 구체적으로 칭찬**해 줘.  유쾌한 표현을 사용해 자신감을 심어 줘. 두 번째 줄에서는 **점수를 100점으로 올릴 수 있는 현실적인 힌트**를 줘.  긍정적이고 친근하게 조언해 줘.  글의 수준에 맞추어 독자를 예상해서 어휘를 사용하고, 항상 존댓말로 말해 줘.  "더 노력해 보세요" 같은 딱딱한 표현을 사용하지 말고 따뜻한 톤을 유지해 줘. 이모티콘은 사용하지 말아줘. ',
        );

        if (
          gptResponse?.status === 200 &&
          gptResponse?.data?.ret_code === 1000
        ) {
          Toast3Show(gptResponse.data.text, 2000, '1.2rem');
          setKeewiComment(gptResponse.data.text.replace(/\n{2,}/g, '\n'));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const gptResponse = await APIUtils.ChatGPT(
          0.8,
          '너는 글쓰기 교육 전문가야. 학생이 AI 피드백을 받고 여러 번 글을 수정하는 과정에서 AI 자동 평가 결과를 반영해서 실질적인 도움을 줄 수 있도록 구체적인 피드백을 제공해 줘. "이름+님"을 불러주면 좋겠어.',
          formatKeewiContent,
          '이전 버전 글의 점수가 0점이라면 처음 작성한 글이야, 더 많은 설명을 해줘도 되. 수정 회수가 늘어나면 도움말의 내용을 줄여줘. 과제의 내용과 제약 조건을 검토하고 점수를 올리기위한 구체적인 방안을 제시해줘. 1. 키위 AI 평가 결과를 활용하여, 현재 글의 강점과 약점을 분석해 줘. 2. 무조건 긍정적인 칭찬은 삼가고 학생이 직접 개선할 수 있도록 구체적인 수정 방향을 제시해 줘.  3. 어떤 부분을 어떻게 수정하면 더 좋은 글이 될지, 실질적인 조언을 해 줘. 피드백 형식 (세 문단, 150자 내외) 첫 문단에서는 학생의 글에서 수정해야 할 주요 부분을 알려 줘. 두 번째 문단에서는 수정 방법을 예시와 함께 제시해 줘. 학생이 어떤 부분을 어떻게 바꾸면 더 좋은 글이 되는지 예시를 포함해 줘. 세번째 문단에서는 글에 사용되었으면 좋을 어휘와 이유를 설명해줘. 점수가 이미 90점 이상이라면 어휘추천 대신에 조직과 주제의 점수를 높이기 위한 전략을 제시해줘.  학생글의 수준에 맞는 피드백을 주고, 글을 개선할 수 있도록 구체적인 가이드라인을 제공해 줘. 두 문단, 100자 내외로 짧지만 강렬하게 작성해 줘.  첫 문단에서는 학생의 글에서 좋았던 점을 구체적으로 칭찬해 줘.  두 번째 문단에서는 점수를 올릴 수 있는 현실적인 힌트를 줘.  "이 부분을 살짝 다듬으면 한 단계 더 업그레이드될 거예요!"  이런 식으로 긍정적이고 친근하게 조언해 줘.  학생의 수준에 맞춘 쉬운 어휘를 사용하고, 항상 존댓말로 말해 줘. 노력하라는 말 대신에 잘할 수 있는 방향을 제시해줘. 그리고 이모티콘은 사용하지 말아줘. ',
        );

        if (
          gptResponse?.status === 200 &&
          gptResponse?.data?.ret_code === 1000
        ) {
          Toast3Show(gptResponse.data.text, 2000, '1.2rem');
          setKeewiComment(gptResponse.data.text.replace(/\n{2,}/g, '\n'));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (
      saveEvalResultDone &&
      maxTryLimit > 0 &&
      maxEvalTry - 1 <= maxTryLimit
    ) {
      autoEvalSubmit(taskID);
    }
    if (
      saveEvalResultDone &&
      preKeewiChat == 1 &&
      !taskMessage.replace(/\s/g, '').includes('###평가모델')
    ) {
      keewichat_comment(prevScore, keewiEvalResult);
      setPrevScore(keewiEvalResult.keewi_score);
    }
    setLoading(false);
  }, [saveEvalResultDone]);

  function evalSubmit(new_task_id) {
    const isLate = taskDueLeft <= 0; // 마감 여부 확인
    const textLength = StringUtils.getBodyTextLenth(currentText); // 글자 수 확인
    const missingKeywords = requiredWord
      .split(',')
      .filter(keyword => !currentText.includes(keyword) && keyword !== '');

    const bad_submit_status =
      (textCountChecked &&
        (textLength < textCountMin || textLength > textCountMax)) ||
      (requiredWordChecked && missingKeywords.length > 0);

    if (
      bad_submit_status &&
      maxTryLimit != 0 &&
      maxEvalTry >= maxTryLimit + 1
    ) {
      setSubmitAnywayDoneVisibility(true);
      return;
    }

    if (isLate) {
      if (
        (textCountChecked &&
          (textLength < textCountMin || textLength > textCountMax)) ||
        (requiredWordChecked && missingKeywords.length > 0)
      ) {
        // 늦은 제출에서 필수 조건이 충족되지 않음
        setSubmitLateInvalidVisibility(true);
        return;
      } else {
        // 늦은 제출에서 필수 조건이 충족됨
        setSubmitLateDoneVisibility(true);
        const submitResult = async () => {
          try {
            const response = await APIUtils.TaskSubmit(
              authReducer.student_id,
              new_task_id,
            );
            return response;
          } catch (err) {
            console.log(err);
          }
        };
        submitResult().then(res => {
          if (res.status === 200 && res.data.ret_code === 1000) {
            setSubmitLateDoneVisibility(true);
          }
        });
      }
    } else {
      if (
        (textCountChecked &&
          (textLength < textCountMin || textLength > textCountMax)) ||
        (requiredWordChecked && missingKeywords.length > 0)
      ) {
        setSubmitInvalidVisibility(true);
        return;
      } else {
        setSubmitDoneVisibility(true);
        const submitResult = async () => {
          try {
            const response = await APIUtils.TaskSubmit(
              authReducer.student_id,
              new_task_id,
            );
            return response;
          } catch (err) {
            console.log(err);
          }
        };
        submitResult().then(res => {
          if (res.status === 200 && res.data.ret_code === 1000) {
            setSubmitDoneVisibility(true);
          }
        });
      }
    }
  }

  function autoEvalSubmit(new_task_id) {
    const isLate = taskDueLeft <= 0; // 마감 여부 확인
    const textLength = StringUtils.getBodyTextLenth(currentText); // 글자 수 확인
    const missingKeywords = requiredWord
      .split(',')
      .filter(keyword => !currentText.includes(keyword) && keyword !== '');
    const bad_submit_status =
      (textCountChecked &&
        (textLength < textCountMin || textLength > textCountMax)) ||
      (requiredWordChecked && missingKeywords.length > 0);
    setToast2Visibility(false);
    if (maxEvalTry > maxTryLimit + 1) {
      setToast2Visibility(false);
      setToast2Text('');
      return;
    }
    if (isLate || maxEvalTry === maxTryLimit + 1) {
      const submitResult = async () => {
        try {
          return await APIUtils.TaskSubmit(authReducer.student_id, new_task_id);
        } catch (err) {
          console.log(err);
          return null;
        }
      };

      submitResult().then(res => {
        if (res?.status === 200 && res.data.ret_code === 1000) {
          let message = '';

          if (isLate) {
            message = bad_submit_status
              ? '과제 제출 마감이 지나 조건을 충족하지 못했지만, 선생님께 제출되었습니다.'
              : '과제 제출 마감이 지나서 선생님께 늦은 제출되었습니다.';
          } else if (maxTryLimit != 0 && maxEvalTry === maxTryLimit + 1) {
            message = bad_submit_status
              ? '마지막 수정 기회이므로 조건을 충족하지 않았지만, 선생님께 제출되었습니다.'
              : '마지막 수정 기회였습니다. 무사히 선생님께 제출되었습니다.';
          }

          Toast2Show(message, 2000, '1.5rem');
        }
      });
    } else {
      if (
        (textCountChecked &&
          (textLength < textCountMin || textLength > textCountMax)) ||
        (requiredWordChecked && missingKeywords.length > 0)
      ) {
        Toast2Show(
          '필수 조건을 충족하지 않아 자동 제출이 불가능했습니다.',
          3000,
          '1.2rem',
        );
        return;
      } else {
        const submitResult = async () => {
          try {
            const response = await APIUtils.TaskSubmit(
              authReducer.student_id,
              new_task_id,
            );
            return response;
          } catch (err) {
            console.log(err);
          }
        };
        submitResult().then(res => {
          if (res.status === 200 && res.data.ret_code === 1000) {
            Toast2Show('수정한 글이 자동으로 제출되었습니다.', 2000, '1.2rem');
          }
        });
      }
    }
  }

  function createWholeStatisticsText() {
    let flowLevel = '어려움';
    if (keewiScore.흐름이자연스러운정도 >= 71) flowLevel = '쉬움';
    else if (keewiScore.흐름이자연스러운정도 >= 63) flowLevel = '보통';
    return (
      <Box style={{width: '90%', marginBottom: '3rem'}}>
        <Box>
          {createStatisticsText('전체 글자 수', keewiScore.전체글자수, '자')}
        </Box>
        <Box>
          {createStatisticsText('전체 문단 수', keewiScore.전체문단수, '개')}
        </Box>
        <Box>
          {createStatisticsText('전체 문장 수', keewiScore.전체문장수, '개')}
        </Box>
        <Box>
          {createStatisticsText(
            '문단별 문장 수',
            keewiScore.문단별문장수,
            '개',
          )}
        </Box>
        <Box>
          {createStatisticsText(
            '긴 문장(50자)의 수',
            keewiScore.긴문장의수,
            '개',
          )}
        </Box>
        <Box>
          {createStatisticsText('예상 독자 나이', keewiScore.독자나이, '세')}
        </Box>
        <Box>{createStatisticsText('읽기 쉬운 정도', flowLevel, '')}</Box>
      </Box>
    );
  }
  function createStatisticsText(title, score, rest) {
    return (
      <Box
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '0.4rem',
          fontSize: '1rem',
          fontWeight: '500',
        }}
      >
        <Box>{title}</Box>
        <Box>
          <span style={{color: '#f67f10'}}>{score}</span> {rest}
        </Box>
      </Box>
    );
  }
  function showSaveOverlay() {
    if (chatVisibility) {
      return <></>;
    }
    if (bottomOverlay) {
      return (
        <Box
          style={{
            // width: '100%',
            paddingBottom: '2rem',
            position: 'fixed',
            bottom: 0,
            right: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            zIndex: 3,
          }}
        >
          <IconButton
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#279EFF',
              border: 'solid 2px #279EFF',
              width: '5rem',
              height: '5rem',
              color: 'white',
              fontSize: '0.8rem',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            }}
            onClick={() => {
              setBottomOverlay(false);
            }}
          >
            <img src={PencilIcon2} alt="PencilIcon2" />
            수정하기
          </IconButton>
          <IconButton
            style={{
              display:
                maxTryLimit === 0 || maxEvalTry <= maxTryLimit + 1
                  ? 'flex'
                  : 'none',
              flexDirection: 'column',
              backgroundColor: submitButtonColor,
              border: 'solid 2px {submitButtonColor}',
              width: '5rem',
              height: '5rem',
              color: 'white',
              fontSize: '0.8rem',
              marginTop: '1rem',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            }}
            onClick={() => {
              evalSubmit(taskID);
            }}
          >
            <img src={SubmitIcon} alt="SubmitIcon" />
            {submitButtonText}
          </IconButton>
          <Button
            style={{
              display: preKeewiChat == 0 ? 'none' : 'flex',
              width: '5rem',
              height: '5rem',
              borderRadius: '50%',
              flexDirection: 'column',
              backgroundColor: '#BCEB80',
              color: 'black',
              fontSize: '0.8rem',
              fontWeight: 'bold',
              marginTop: '1rem',
              marginBottom: '8rem',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            }}
            onClick={() => {
              setChatVisibility(!chatVisibility);
            }}
          >
            <img
              src={IconKeewichat}
              alt="IconKeewichat"
              style={{
                width: '1.2rem',
                height: '1.2rem',
              }}
            />
            <Box>{'키위챗'}</Box>
          </Button>
        </Box>
      );
    }
    return (
      <EssayEditor
        currentText={currentText}
        analyzeEvent={bodyText => {
          analyzeEvent(titleText, writingJanre, bodyText, taskID, true);
          setBottomOverlay(true);
          setMaxEvalTry(prev => {
            setCurrentEvalTry(prev + 1);
            return prev + 1;
          });
        }}
        cancelEvent={() => {
          setBottomOverlay(true);
        }}
        highlightWordList={highlightWords}
        currentTryCount={maxEvalTry}
        maxTryLimit={maxTryLimit}
      />
    );
  }
  function showGPTValidation() {
    if (isGPT) {
      return (
        <Box
          style={{
            width: '100%',
            backgroundColor: '#f5d7da',
            color: '#bb0000',
            fontSize: '1rem',
            padding: '0.5rem',
            textAlign: 'center',
          }}
        >
          ⚠ GPT로 생성한 글일 가능성이 높습니다.
        </Box>
      );
    }
  }
  function showFooter() {
    if (bottomOverlay) {
      return <FooterView />;
    }
  }
  const throttledScroll = useMemo(
    () =>
      throttle(() => {
        if (window.scrollY > 500) {
          setIsScrollTop(false);
        } else {
          setIsScrollTop(true);
        }
      }, 1),
    [isScrollTop],
  );
  useEffect(() => {
    setUnfoundRequiredWord(
      requiredWord
        .split(',')
        .filter(val => !currentText.includes(val))
        .join(','),
    );
  }, [currentText, requiredWord]);
  useEffect(() => {
    window.addEventListener('scroll', throttledScroll);
    return () => {
      window.removeEventListener('scroll', throttledScroll); //clean up
    };
  }, [throttledScroll]);
  useEffect(() => {
    let savedText = '';
    let savedTitle = '';
    let savedJanre = '';
    let savedTaskID = 0;
    if (location.state != null) {
      savedTaskID = location.state.task_id;
    } else {
      savedTaskID = Number(window.localStorage.getItem('task_id'));
    }
    if (location.state == null && window.localStorage.getItem('text') == null) {
      navigate('/', {replace: true});
      return;
    } else if (window.localStorage.getItem('text') != null) {
      savedTitle = window.localStorage.getItem('title');
      savedText = window.localStorage.getItem('text');
      savedJanre = window.localStorage.getItem('janre');
      getTaskInfo(savedTaskID, savedTitle, savedJanre, savedText, false);
    } else {
      savedTitle = location.state.title;
      savedText = location.state.text;
      savedJanre = location.state.writing_janre;

      window.localStorage.setItem('title', location.state.title);
      window.localStorage.setItem('text', location.state.text);
      window.localStorage.setItem('janre', location.state.writing_janre);
      window.localStorage.setItem('task_id', location.state.task_id);
      getTaskInfo(savedTaskID, savedTitle, savedJanre, savedText, true);
    }
    setTaskID(savedTaskID);
  }, []);
  // if (onError) return <ServerError />;
  if (loading) return <ApiLoading loading={loading} />;
  return (
    <Box
      style={{
        width: chatVisibility ? '70%' : '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box>
        <ToastPopup2
          text={toast3Text}
          visibility={toast3Visibility}
          fontSizeStr={toast3FontSizeText}
        />
        <ToastPopup2
          text={toast2Text}
          visibility={toast2Visibility}
          fontSizeStr={toast2FontSizeText}
        />
        <ToastPopup text={toastText} visibility={toastVisibility} />
      </Box>
      <SubmitDonePopup
        visibility={submitDoneVisibility}
        completeEvent={submitDoneConfirm}
      />
      <SubmitAnywayDonePopup
        visibility={submitAnywayDoneVisibility}
        completeEvent={submitDoneConfirm}
      />

      <SubmitInvalidPopup
        visibility={submitInvalidVisibility}
        textCountChecked={textCountChecked}
        textCountMin={Number(textCountMin)}
        textCountMax={Number(textCountMax)}
        currentCount={StringUtils.getBodyTextLenth(currentText)}
        requiredWordChecked={requiredWordChecked}
        requiredWord={requiredWord}
        unfoundRequiredWord={unfoundRequiredWord}
        cancelEvent={submitInvalidCancel}
      />
      <SubmitLateDonePopup
        visibility={submitLateDoneVisibility}
        completeEvent={submitLateDoneConfirm}
      />
      <SubmitLateInvalidPopup
        visibility={submitLateInvalidVisibility}
        textCountChecked={textCountChecked}
        textCountMin={Number(textCountMin)}
        textCountMax={Number(textCountMax)}
        currentCount={StringUtils.getBodyTextLenth(currentText)}
        requiredWordChecked={requiredWordChecked}
        requiredWord={requiredWord}
        taskID={Number(taskID)}
        unfoundRequiredWord={unfoundRequiredWord}
        submitLateInvalidCancel={submitLateInvalidCancel}
        submitLateInvalidConfirm={submitLateInvalidConfirm}
      />
      <HeaderView />
      <TitleComponent2_2
        dateLeft={taskDateLeft}
        dateLeftText={
          taskDueLeft < 0 ? `D+${Math.abs(taskDateLeft)}` : `D-${taskDateLeft}`
        }
        dateLeftColor={taskDueLeft < 0 ? 'red' : `green`}
        dueLeft={taskDueLeft}
        dateText={taskDateText}
        titleText={taskTitleText}
        messageText={taskMessage}
        textCountChecked={textCountChecked}
        textCountMin={textCountMin}
        textCountMax={textCountMax}
        requiredWordChecked={requiredWordChecked}
        requiredWord={requiredWord}
        currentTryCount={maxEvalTry}
        maxTryLimit={maxTryLimit}
      />

      <Box style={{marginTop: '3rem'}}></Box>
      {showGPTValidation()}
      <MarginBoxComponent>
        <div>
          <Box>
            <Box
              style={{
                borderBottom: '1px solid #7d7d7d',
                marginTop: '2rem',
                marginBottom: '3rem',
                paddingBottom: '1rem',
              }}
            >
              <SingleLineComponent2
                stepNumber="02"
                text={'AI 피드백을 참고하여 글을 수정하세요.'}
                currentIndex={currentEvalTry}
                maxIndex={maxEvalTry}
                leftClickEvent={() => {
                  setCurrentEvalTry(prev => {
                    if (prev > 1) {
                      loadSavedEval(taskID, prev - 1);
                      return prev - 1;
                    }
                  });
                }}
                rightClickEvent={() => {
                  setCurrentEvalTry(prev => {
                    if (prev < maxEvalTry) {
                      loadSavedEval(taskID, prev + 1);
                      return prev + 1;
                    }
                  });
                }}
              />
              <SingleLineTitle
                title={titleText}
                tScore={tScore}
                handleInput={handleTitle}
                editable={titleEditable}
                changeEditStatus={status => {
                  setTitleEditable(status);
                }}
              />
            </Box>
            <Box
              style={{
                display: toast2Text !== '' ? 'flex' : 'none',
                flexDirection: 'row', // 가로 정렬
                alignItems: 'center', // 세로 중앙 정렬
                marginLeft: '2rem',
                gap: '1rem', // 이미지와 텍스트 사이 간격
                marginTop: '1rem',
                marginBottom: '3rem',
              }}
            >
              {/* 이미지 */}
              <img
                src={KeewiBird}
                alt="Keewi Bird"
                style={{
                  width: '2rem',
                  height: '3rem',
                }}
              />

              {/* 텍스트 */}
              <Box
                style={{
                  wordBreak: 'break-word', // break-all 대신 break-word가 더 자연스러움
                  fontSize: '1rem',
                  fontWeight: '500',
                  color: 'rgba(48, 48, 48, 1)',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {toast2Text.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </Box>
            </Box>
            <Grid container spacing={'1rem'}>
              <Grid
                item
                xs={8}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  style={{
                    fontSize: '1.375rem',
                    fontWeight: '700',
                    marginBottom: '1.5rem',
                  }}
                >
                  내용 분석
                </Box>
                <WhiteBox>
                  <>
                    {labelText}
                    {analyText}
                  </>
                </WhiteBox>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  style={{
                    fontSize: '1.375rem',
                    fontWeight: '700',
                    marginBottom: '1.5rem',
                  }}
                >
                  글 통계 자료
                </Box>
                <WhiteBox>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'start',
                      height: '100%',
                    }}
                  >
                    {createWholeStatisticsText()}
                    <Box
                      style={{
                        width: '100%',
                        minHeight: '20rem',
                        backgroundColor: '#F7F8FA',
                        borderRadius: '1.25rem',
                        padding: '1.25rem 0rem',
                        marginBottom: '2rem', // 간격 추가
                      }}
                    >
                      <RadarChart radarData={radarData} />
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        width: '100%',
                        minHeight: '20rem',
                        backgroundColor: '#F7F8FA',
                        borderRadius: '1.25rem',
                        padding: '1.25rem 0rem',
                        alignItems: 'center',
                        justifyContent: 'center', // 중앙 정렬
                      }}
                    >
                      {memoizedWordCloud}
                    </Box>
                  </Box>
                </WhiteBox>
              </Grid>
            </Grid>
            <Box
              style={{
                display: keewiComment !== '' ? 'flex' : 'none',
                flexDirection: 'row', // 가로 정렬
                alignItems: 'center', // 세로 중앙 정렬
                gap: '1rem', // 이미지와 텍스트 사이 간격
                marginTop: '3rem',
                width: '90%',
                backgroundColor: 'white',
                borderRadius: '1.25rem',
                border: '1px solid var(--01-gray-07, #E9EBED)',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                paddingLeft: '5%',
                paddingRight: '5%',
                paddingTop: '1rem',
                paddingBottom: '1rem',
              }}
            >
              {/* 이미지 */}
              <img
                src={KeewiBird}
                alt="Keewi Bird"
                style={{
                  width: '2rem',
                  height: '3rem',
                }}
              />

              {/* 텍스트 */}
              <Box
                style={{
                  wordBreak: 'break-word', // break-all 대신 break-word가 더 자연스러움
                  fontSize: '1rem',
                  fontWeight: '500',
                  color: 'rgba(48, 48, 48, 1)',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {keewiComment.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </Box>
            </Box>

            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2rem',
                marginTop: '3rem',
              }}
            >
              {commentParagraph.map((paragraph, paragraphIndex) => {
                if (paragraph.length <= 2) {
                  return;
                }
                if (paragraph.length == 3 && paragraphIndex == 1) {
                  return;
                }
                return paragraph.map((value, idx) => {
                  return (
                    <CommentItem
                      key={'commentbox' + paragraphIndex + '_' + idx}
                      paragraphIndex={paragraphIndex}
                      idx={idx}
                      commentID={value.commentID}
                      type={value.type}
                      A={value.A}
                      B={value.B}
                      C={value.C}
                      subtype={value.subtype}
                      text={value.text}
                    />
                  );
                });
              })}
            </Box>
          </Box>
          <ChatOverlay
            chatVisibility={chatVisibility}
            handleVisibility={() => {
              setChatVisibility(!chatVisibility);
            }}
            taskID={taskID}
            chatTask={chatTask}
            keewiEvalRes={keewiEvalResult}
          />
        </div>
      </MarginBoxComponent>
      {showSaveOverlay()}
      {showFooter()}
    </Box>
  );
};
export default MainPage;
