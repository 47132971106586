import {Palette} from 'common/utils/ColorUtils';

export const mainColoredBtn = {
  marginTop: '2.5rem',
  padding: '0.9rem 1.6rem',
  backgroundColor: '#00C500',
  borderRadius: '6.25rem',
  color: 'white',
  fontWeight: '700',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
};
export const mainReverseColoredBtn = {
  marginTop: '2.5rem',
  padding: '0.9rem 1.6rem',
  color: '#00C500',
  borderRadius: '6.25rem',
  backgroundColor: 'white',
  fontWeight: '700',
};

export const iconBox = {
  width: '4rem',
  height: '4rem',
  borderRadius: '1.5625rem',
  border: '1px solid #F3F3F3',
  background: '#FFF',
  boxShadow: '0px 4px 20px 0px rgba(40, 173, 141, 0.15)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '1.5rem',
};
