import PropTypes from 'prop-types';
import {Box, Button} from '@mui/material';
import {StringUtils} from 'common/utils/StringUtils';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import APIUtils from 'common/utils/APIUtils';
import {useSelector} from 'react-redux';
import SubmitCancelPopup from 'view/popup/SubmitCancelPopup';
import TaskPeerView3 from 'view/mypage/TaskPeerView3';
import TaskPeerView4 from 'view/mypage/TaskPeerView4';

export const TaskView = props => {
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);
  const peer_review_view_list = props.peerReviewTaskViewList;
  const isPeerReviewViewVisible = peer_review_view_list.includes(
    props.task.task_id,
  );
  const dateLeft = StringUtils.getDateDiff(props.task.task_end_time);
  const timeLeft = StringUtils.getTimeDiffTZ(
    props.task.task_end_time,
    props.task.tz,
  );
  const [submitCancelVisibility, SetSubmitCancelVisibility] = useState(false);
  const [loading, setLoading] = useState(true);
  const [studentSubmitList, setStudentSubmitList] = useState(null);
  const [submitListCount, setSubmitListCount] = useState('-');
  const [viewType, setViewType] = useState(0);
  const [timeSortOrder, setTimeSortOrder] = useState(1);
  const [titleSortOrder, setTitleSortOrder] = useState(1);
  const [evalTrySortOrder, setEvalTrySortOrder] = useState(1);
  const [textLengthSortOrder, setTextLengthSortOrder] = useState(1);
  const [nameSortOrder, setNameSortOrder] = useState(1);
  const [submitListSortType, setSubmitListSortType] = useState('name');
  const [originalSubmitList, setOriginalSubmitList] = useState(null);

  function getTaskSubmitList(task_id) {
    const submitList = async () => {
      try {
        const response = await APIUtils.TaskStudentList(task_id);
        if (response.status == 200 && response.data.ret_code == 1000) {
          const myId = authReducer.student_id;
          const sortedInitialList = [...response.data.submit_list]
            .sort((a, b) => a.student_name.localeCompare(b.student_name)) // 이름순 정렬
            .sort((a, b) =>
              a.student_id === myId ? -1 : b.student_id === myId ? 1 : 0,
            ); // 내 ID를 맨 앞으로

          setStudentSubmitList(sortedInitialList);
          setSubmitListSortType('name');

          setOriginalSubmitList(response.data.submit_list);
          setSubmitListCount(response.data.submit_list.length);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (studentSubmitList == null) submitList(task_id);
  }

  function sortSubmitListBy(key) {
    let sortedList;

    setSubmitListSortType(key);

    sortedList = [...originalSubmitList].sort((a, b) => {
      if (
        a.student_id === authReducer.student_id &&
        b.student_id !== authReducer.student_id
      ) {
        return -1;
      }
      if (
        b.student_id === authReducer.student_id &&
        a.student_id !== authReducer.student_id
      ) {
        return 1;
      }

      // 기존 정렬 조건 적용
      if (key === 'submit_time') {
        return timeSortOrder === 0
          ? new Date(a.submit_time) - new Date(b.submit_time)
          : new Date(b.submit_time) - new Date(a.submit_time);
      } else if (key === 'title') {
        return titleSortOrder === 0
          ? a.title.localeCompare(b.title)
          : b.title.localeCompare(a.title);
      } else if (key === 'name') {
        return titleSortOrder === 0
          ? a.student_name.localeCompare(b.student_name)
          : b.student_name.localeCompare(a.student_name);
      } else if (key === 'length') {
        return textLengthSortOrder === 0
          ? a.length - b.length
          : b.length - a.length;
      }
      return 0;
    });

    // 정렬 순서 변경
    if (key === 'submit_time') {
      setTimeSortOrder(prev => (prev === 0 ? 1 : 0));
    } else if (key === 'title') {
      setTitleSortOrder(prev => (prev === 0 ? 1 : 0));
    } else if (key === 'name') {
      setTitleSortOrder(prev => (prev === 0 ? 1 : 0));
    } else if (key === 'length') {
      setTextLengthSortOrder(prev => (prev === 0 ? 1 : 0));
    }

    if (sortedList) {
      setStudentSubmitList(sortedList);
    }
  }

  function encodeBase64(input) {
    try {
      if (typeof window !== 'undefined') {
        // 브라우저 환경
        return window
          .btoa(unescape(encodeURIComponent(input)))
          .replace(/\+/g, '-')
          .replace(/\//g, '_'); // URLSafe 변환
      } else {
        // Node.js 환경
        return Buffer.from(input, 'utf-8')
          .toString('base64')
          .replace(/\+/g, '-')
          .replace(/\//g, '_'); // URLSafe 변환
      }
    } catch (e) {
      return '';
    }
  }
  function clickResult(student_id, task_id) {
    // 새 창에 URL과 쿼리 문자열 포함
    function getRandomChar() {
      const chars =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      return chars.charAt(Math.floor(Math.random() * chars.length));
    }

    const my_id = authReducer.student_id;
    const my_name = authReducer.student_name;
    const encodedParams = encodeBase64(
      `${my_name}:${my_id}:${task_id}:${student_id}`,
    );
    const randomPrefix = getRandomChar();
    const randomSuffix = getRandomChar();
    const secureEncodedParams = randomPrefix + encodedParams + randomSuffix;

    const url = `/peer/review?gul=${secureEncodedParams}`;
    window.open(url, '_blank', 'noopener, noreferrer');
  }

  function submitCancel() {
    const cancleSubmit = async () => {
      try {
        const response = await APIUtils.CancelTaskSubmit(
          authReducer.student_id,
          props.task.task_id,
        );
      } catch (err) {
        console.log(err);
      }
    };
    cancleSubmit();
    SetSubmitCancelVisibility(false);
    window.location.reload();
  }

  function submitCancelCancel() {
    SetSubmitCancelVisibility(false);
  }

  function clickPeerReviewResult(my_id, my_name, task_id, student_id) {
    function getRandomChar() {
      const chars =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      return chars.charAt(Math.floor(Math.random() * chars.length));
    }
    const encodedParams = encodeBase64(
      `${my_name}:${my_id}:${task_id}:${student_id}`,
    );
    const randomPrefix = getRandomChar();
    const randomSuffix = getRandomChar();
    const secureEncodedParams = randomPrefix + encodedParams + randomSuffix;
    let url = `http://192.168.219.39:6006/peer/review?gul=${secureEncodedParams}`;
    if (process.env.REACT_APP_ENV === 'live') {
      url = `https://keewi-t.korean.ai/peer/review?gul=${secureEncodedParams}`;
    } else if (process.env.REACT_APP_ENV === 'stage') {
      url = `https://keewi-t.keewi.site/peer/review?gul=${secureEncodedParams}`;
    }
    window.open(url, '_blank', 'noopener, noreferrer');
  }

  function showLabel() {
    if (timeLeft >= 0) {
      props.task.task_status = '진행중';
    } else {
      props.task.task_status = '종료';
    }
    if (props.task.task_status == '진행중') {
      return (
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Box
            style={{
              color: 'white',
              backgroundColor: '#00C500',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
            }}
          >
            D-{dateLeft}
          </Box>
          <Box
            style={{
              color: '#FF9900',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
              border: '1px solid #FF9900',
            }}
          >
            진행중
          </Box>
          <Box
            style={{
              display: props.task.task_type == 1 ? '' : 'none',
              color: '#279EFF',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
              border: '1px solid #279EFF',
            }}
          >
            {'AI피드백'}
          </Box>
          <Box
            style={{
              display: props.task.peer_review_status != 0 ? '' : 'none',
              color: props.task.peer_review_status == 2 ? '#4FD7C3' : '#B1B1B1',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
              border:
                props.task.peer_review_status == 2
                  ? '1px solid #4FD7C3'
                  : '1px solid #B1B1B1',
            }}
          >
            {'동료평가'}
          </Box>
          <Box
            style={{
              display: props.task.max_try != 0 ? '' : 'none',
              color: '#00C500',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
              border: '1px solid #00C500',
            }}
          >
            수정 {props.task.max_try}회
          </Box>
        </Box>
      );
    } else if (props.task.task_status == '대기') {
      return (
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Box
            style={{
              color: '#68737D',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
              border: '1px solid #68737D',
            }}
          >
            대기
          </Box>
          <Box
            style={{
              display: props.task.peer_review_status == 2 ? '' : 'none',
              color: props.task.peer_review_status == 2 ? '#4FD7C3' : '#B1B1B1',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
              border:
                props.task.peer_review_status == 2
                  ? '1px solid #4FD7C3'
                  : '1px solid #B1B1B1',
            }}
          >
            {'동료평가'}
          </Box>
        </Box>
      );
    } else if (props.task.task_status == '종료') {
      return (
        <Box style={{display: 'flex', gap: '0.5rem'}}>
          <Box
            style={{
              color: '#87929D',
              backgroundColor: '#ffe4e4',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.875rem',
              fontWeight: 'bolder',
            }}
          >
            {timeLeft < 0 ? `D+${Math.abs(dateLeft)}` : `D-${dateLeft}`}
          </Box>
          <Box
            style={{
              color: '#87929D',
              backgroundColor: '#E9EBED',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
            }}
          >
            종료
          </Box>
          <Box
            style={{
              display: [2].includes(props.task.peer_review_status)
                ? ''
                : 'none',
              color: props.task.peer_review_status == 2 ? '#4FD7C3' : '#B1B1B1',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
              border:
                props.task.peer_review_status == 2
                  ? '1px solid #4FD7C3'
                  : '1px solid #B1B1B1',
            }}
          >
            {'동료평가'}
          </Box>
          <Box
            style={{
              display: props.task.peer_review_status == 4 ? '' : 'none',
              color: '#87929D',
              backgroundColor: '#E9EBED',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
            }}
          >
            {'동료평가'}
          </Box>
          <Box
            style={{
              display: props.task.peer_review_status == 5 ? '' : 'none',
              color: '#4FD7C3',
              backgroundColor: 'white',
              width: 'fit-content',
              padding: '0.125rem 0.375rem',
              borderRadius: '0.3125rem',
              fontSize: '0.7rem',
              border: '1px solid #4FD7C3',
            }}
          >
            {'동료평가'}
          </Box>
        </Box>
      );
    }
  }

  function showButton() {
    if (props.task.task_status == '진행중') {
      if (props.task.task_submit) {
        return (
          <Box style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
            <Button
              style={{
                display: props.task.maxTryCount == 0 ? 'block' : 'none',
                color: '#B1B1B1',
                fontSize: '0.875rem',
                border: '1px solid #B1B1B1',
                borderRadius: '1.625rem',
                padding: '0.7rem 0rem',
                width: '6rem',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              }}
              onClick={() => {
                SetSubmitCancelVisibility(true);
              }}
            >
              제출 취소
            </Button>
            <Button
              style={{
                color: '#00C500',
                fontSize: '0.875rem',
                border: '1px solid #00C500',
                borderRadius: '1.625rem',
                padding: '0.7rem 0rem',
                width: '6rem',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              }}
              onClick={() => {
                navigate('/writing2', {
                  state: {
                    task_id: props.task.task_id,
                  },
                });
              }}
            >
              수정하기
            </Button>
            {!isPeerReviewViewVisible &&
              [2].includes(props.task.peer_review_status) && (
                <Button
                  style={{
                    color: '#4FD7C3',
                    fontSize: '0.875rem',
                    border: '1px solid #4FD7C3',
                    borderRadius: '1.625rem',
                    padding: '0.7rem 0rem',
                    width: '6rem',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  }}
                  onClick={() => {
                    props.updatePeerReviewList('add', props.task.task_id);
                    getTaskSubmitList(props.task.task_id);
                  }}
                >
                  동료평가
                </Button>
              )}
            {isPeerReviewViewVisible &&
              [2].includes(props.task.peer_review_status) && (
                <Button
                  style={{
                    color: '#FF9900',
                    fontSize: '0.875rem',
                    border: '1px solid #FF9900',
                    borderRadius: '1.625rem',
                    padding: '0.7rem 0rem',
                    width: '6rem',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  }}
                  onClick={() => {
                    props.updatePeerReviewList('remove', props.task.task_id);
                  }}
                >
                  △ 동료평가
                </Button>
              )}
            {props.task.task_submit &&
              [5].includes(props.task.peer_review_status) && (
                <Button
                  style={{
                    color: '#4FD7C3',
                    fontSize: '0.875rem',
                    border: '1px solid #4FD7C3',
                    borderRadius: '1.625rem',
                    padding: '0.7rem 0rem',
                    width: '6rem',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  }}
                  onClick={() => {
                    clickPeerReviewResult(
                      authReducer.student_id,
                      authReducer.student_name,
                      props.task.task_id,
                      authReducer.student_id,
                    );
                  }}
                >
                  평가결과
                </Button>
              )}
          </Box>
        );
      }
      return (
        <Box style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
          <Button
            style={{
              color: '#FF9900',
              fontSize: '0.875rem',
              border: '1px solid #FF9900',
              borderRadius: '1.625rem',
              padding: '0.7rem 0rem',
              width: '6rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => {
              navigate('/writing2', {
                state: {
                  task_id: props.task.task_id,
                },
              });
            }}
          >
            작성하기
          </Button>
          {!isPeerReviewViewVisible &&
            [2].includes(props.task.peer_review_status) && (
              <Button
                style={{
                  color: '#4FD7C3',
                  fontSize: '0.875rem',
                  border: '1px solid #4FD7C3',
                  borderRadius: '1.625rem',
                  padding: '0.7rem 0rem',
                  width: '6rem',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
                onClick={() => {
                  props.updatePeerReviewList('add', props.task.task_id);
                  getTaskSubmitList(props.task.task_id);
                }}
              >
                동료평가
              </Button>
            )}
          {isPeerReviewViewVisible &&
            [2].includes(props.task.peer_review_status) && (
              <Button
                style={{
                  color: '#FF9900',
                  fontSize: '0.875rem',
                  border: '1px solid #FF9900',
                  borderRadius: '1.625rem',
                  padding: '0.7rem 0rem',
                  width: '6rem',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
                onClick={() => {
                  props.updatePeerReviewList('remove', props.task.task_id);
                }}
              >
                △ 동료평가
              </Button>
            )}
          {props.task.task_submit &&
            [5].includes(props.task.peer_review_status) && (
              <Button
                style={{
                  color: '#4FD7C3',
                  fontSize: '0.875rem',
                  border: '1px solid #4FD7C3',
                  borderRadius: '1.625rem',
                  padding: '0.7rem 0rem',
                  width: '6rem',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
                onClick={() => {
                  clickPeerReviewResult(
                    authReducer.student_id,
                    authReducer.student_name,
                    props.task.task_id,
                    authReducer.student_id,
                  );
                }}
              >
                동료평가
              </Button>
            )}
        </Box>
      );
    }
    if (!props.task.task_submit) {
      return (
        <Box style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
          <Button
            disabled={props.task.task_share == '1'}
            style={{
              display: props.task.task_share == 1 ? 'none' : '',
              color: '#FF9900',
              fontSize: '0.875rem',
              border: '1px solid #FF9900',
              borderRadius: '1.625rem',
              padding: '0.7rem 0rem',
              width: '6rem',
              boxShadow:
                props.task.task_share == '1'
                  ? ''
                  : '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => {
              navigate('/writing2', {
                state: {
                  task_id: props.task.task_id,
                },
              });
            }}
          >
            {props.task.task_share == '1' ? '' : '늦은 제출'}
          </Button>
          {!isPeerReviewViewVisible &&
            [2].includes(props.task.peer_review_status) && (
              <Button
                style={{
                  color: '#4FD7C3',
                  fontSize: '0.875rem',
                  border: '1px solid #4FD7C3',
                  borderRadius: '1.625rem',
                  padding: '0.7rem 0rem',
                  width: '6rem',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
                onClick={() => {
                  props.updatePeerReviewList('add', props.task.task_id);
                  getTaskSubmitList(props.task.task_id);
                }}
              >
                동료평가
              </Button>
            )}
          {isPeerReviewViewVisible &&
            [2].includes(props.task.peer_review_status) && (
              <Button
                style={{
                  color: '#FF9900',
                  fontSize: '0.875rem',
                  border: '1px solid #FF9900',
                  borderRadius: '1.625rem',
                  padding: '0.7rem 0rem',
                  width: '6rem',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
                onClick={() => {
                  props.updatePeerReviewList('remove', props.task.task_id);
                }}
              >
                △ 동료평가
              </Button>
            )}
          {props.task.task_submit &&
            [5].includes(props.task.peer_review_status) && (
              <Button
                style={{
                  color: '#4FD7C3',
                  fontSize: '0.875rem',
                  border: '1px solid #4FD7C3',
                  borderRadius: '1.625rem',
                  padding: '0.7rem 0rem',
                  width: '6rem',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
                onClick={() => {
                  clickPeerReviewResult(
                    authReducer.student_id,
                    authReducer.student_name,
                    props.task.task_id,
                    authReducer.student_id,
                  );
                }}
              >
                동료평가
              </Button>
            )}
        </Box>
      );
    } else if (props.task.task_share == '1') {
      return (
        <Box style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
          <Button
            style={{
              display: props.task.pdf_url != '' ? '' : 'none',
              color: '#279EFF',
              fontSize: '0.875rem',
              borderRadius: '1.625rem',
              padding: '0.7rem 0rem',
              border: '1px solid #279EFF',
              width: '6rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => {
              window.open(props.task.pdf_url);
            }}
          >
            {'평가 리포트'}
          </Button>

          <Button
            style={{
              color: '#00C500',
              fontSize: '0.875rem',
              border: '1px solid #00C500',
              borderRadius: '1.625rem',
              padding: '0.7rem 0rem',
              width: '6rem',
              whiteSpace: 'nowrap',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => {
              navigate('/result', {
                state: {
                  task_id: props.task.task_id,
                },
              });
            }}
          >
            결과 확인
          </Button>
          {!isPeerReviewViewVisible &&
            [2].includes(props.task.peer_review_status) && (
              <Button
                style={{
                  color: '#4FD7C3',
                  fontSize: '0.875rem',
                  border: '1px solid #4FD7C3',
                  borderRadius: '1.625rem',
                  padding: '0.7rem 0rem',
                  width: '6rem',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
                onClick={() => {
                  props.updatePeerReviewList('add', props.task.task_id);
                  getTaskSubmitList(props.task.task_id);
                }}
              >
                동료평가
              </Button>
            )}
          {isPeerReviewViewVisible &&
            [2].includes(props.task.peer_review_status) && (
              <Button
                style={{
                  color: '#FF9900',
                  fontSize: '0.875rem',
                  border: '1px solid #FF9900',
                  borderRadius: '1.625rem',
                  padding: '0.7rem 0rem',
                  width: '6rem',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
                onClick={() => {
                  props.updatePeerReviewList('remove', props.task.task_id);
                }}
              >
                △ 동료평가
              </Button>
            )}
          {props.task.task_submit &&
            [5].includes(props.task.peer_review_status) && (
              <Button
                style={{
                  color: '#4FD7C3',
                  fontSize: '0.875rem',
                  border: '1px solid #4FD7C3',
                  borderRadius: '1.625rem',
                  padding: '0.7rem 0rem',
                  width: '6rem',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
                onClick={() => {
                  clickPeerReviewResult(
                    authReducer.student_id,
                    authReducer.student_name,
                    props.task.task_id,
                    authReducer.student_id,
                  );
                }}
              >
                동료평가
              </Button>
            )}
        </Box>
      );
    }
    return (
      <Box style={{display: 'flex', alignItems: 'center'}}>
        <Button
          style={{
            color: '#00C500',
            border: '1px solid #00C500',
            fontSize: '0.875rem',
            borderRadius: '1.625rem',
            padding: '0.7rem 0rem',
            width: '6rem',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
          onClick={() => {
            navigate('/result/submit', {
              state: {
                task_id: props.task.task_id,
              },
            });
          }}
        >
          제출한 글
        </Button>
        {!isPeerReviewViewVisible &&
          [2].includes(props.task.peer_review_status) && (
            <Button
              style={{
                color: '#4FD7C3',
                fontSize: '0.875rem',
                border: '1px solid #4FD7C3',
                borderRadius: '1.625rem',
                padding: '0.7rem 0rem',
                width: '6rem',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              }}
              onClick={() => {
                props.updatePeerReviewList('add', props.task.task_id);
                getTaskSubmitList(props.task.task_id);
              }}
            >
              동료평가
            </Button>
          )}
        {isPeerReviewViewVisible &&
          [2].includes(props.task.peer_review_status) && (
            <Button
              style={{
                color: '#FF9900',
                fontSize: '0.875rem',
                border: '1px solid #FF9900',
                borderRadius: '1.625rem',
                padding: '0.7rem 0rem',
                width: '6rem',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              }}
              onClick={() => {
                props.updatePeerReviewList('remove', props.task.task_id);
              }}
            >
              △ 동료평가
            </Button>
          )}
        {props.task.task_submit && [5].includes(props.task.peer_review_status) && (
          <Button
            style={{
              color: '#4FD7C3',
              fontSize: '0.875rem',
              backgroundColor: '#EBFBF9',
              border: '1px solid #4FD7C3',
              borderRadius: '1.625rem',
              padding: '0.7rem 0rem',
              width: '6rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onClick={() => {
              clickPeerReviewResult(
                authReducer.student_id,
                authReducer.student_name,
                props.task.task_id,
                authReducer.student_id,
              );
            }}
          >
            동료평가
          </Button>
        )}
      </Box>
    );
  }
  return (
    <Box style={{width: '100%', maxWidth: '120rem'}}>
      <SubmitCancelPopup
        visibility={submitCancelVisibility}
        submitReset={submitCancel}
        cancelEvent={submitCancelCancel}
      />
      <Box
        style={{
          display: 'flex',
          // justifyContent: 'start',
          marginTop: '1.0rem',
          paddingBottom: '0.5rem',
          borderBottom: '2px solid #A8B1B7',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.75rem',
            flex: 1,
          }}
        >
          <Box style={{display: 'flex', gap: '0.5rem'}}>{showLabel()}</Box>
          <Box style={{fontSize: '1.125rem', fontWeight: 'bold'}}>
            {props.task.task_name}
          </Box>
          <Box
            style={{
              display: 'initial',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: '40vw',
            }}
          >
            과제 기간: {props.task.task_start_time.slice(5, -3) + ' ~ '}
            {props.task.task_end_time.slice(5, -3)}
            {props.task.tz && props.task.tz !== 'Asia/Seoul'
              ? ' (' + props.task.tz.split('/')[1].replace('_', ' ') + ') '
              : ''}
            {' | '}
            {props.task.teacher_name
              ? props.task.teacher_name + '선생님 | '
              : ''}
            {props.task.task_janre}
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          {showButton()}
        </Box>
      </Box>
      <Box
        style={{
          display: isPeerReviewViewVisible ? '' : 'none',
        }}
      >
        <Box
          style={{
            display: submitListCount === 0 ? '' : 'none',
            color: '#898989',
            marginTop: '1rem',
            marginBottom: '1rem',
            fontWeight: 'bold',
          }}
        >
          제출한 과제가 없습니다.
        </Box>
        <Box>
          <Box
            style={{
              display: submitListCount === 0 ? 'none' : '',
              color: 'black',
              marginTop: '1rem',
              marginBottom: '1rem',
              fontWeight: 'bold',
            }}
          >
            공유한 글 : {submitListCount} 건
            <Button
              style={{
                color: '#262C31',
                fontSize: '0.875rem',
                border: '1.5px solid var(--gray-2, #898989)',
                borderRadius: '1.925rem',
                marginTop: '0.1rem',
                marginLeft: '1rem',
                padding: '0.1rem 1rem',
                backgroundColor:
                  submitListSortType === 'name' ? '#EBFBF9' : 'white',
                whiteSpace: 'nowrap',
              }}
              onClick={() => {
                sortSubmitListBy('name');
              }}
            >
              {submitListSortType !== 'name'
                ? ''
                : nameSortOrder === 0
                ? '↓'
                : '↑'}
              {'이름'}
            </Button>
            <Button
              style={{
                color: '#262C31',
                fontSize: '0.875rem',
                border: '1.5px solid var(--gray-2, #898989)',
                borderRadius: '1.925rem',
                marginTop: '0.1rem',
                marginLeft: '1rem',
                padding: '0.1rem 1rem',
                backgroundColor:
                  submitListSortType === 'title' ? '#EBFBF9' : 'white',
                whiteSpace: 'nowrap',
              }}
              onClick={() => {
                sortSubmitListBy('title');
              }}
            >
              {submitListSortType !== 'title'
                ? ''
                : titleSortOrder === 0
                ? '↓'
                : '↑'}
              {'제목'}
            </Button>
          </Box>
        </Box>
        <Box
          style={{
            display: viewType === 0 ? 'flex' : '', // Flexbox 레이아웃
            flexWrap: viewType === 0 ? 'wrap' : '', // 항목이 넘치면 다음 줄로 감싸도록 설정
            gap: '1rem', // 항목 간 간격 설정
            marginBottom: '5rem',
            marginLeft: '2rem',
            justifyContent: submitListCount > 2 ? 'start' : 'start', // 가운데 정렬
          }}
        >
          {studentSubmitList?.map((value, index) => {
            return viewType === 0 ? (
              <TaskPeerView3
                key={'taskEval' + index}
                index={index + 1}
                submitTask={value}
                resultEvent={clickResult}
                my_id={authReducer.student_id}
              />
            ) : (
              <TaskPeerView4
                key={'taskEval' + index}
                index={index + 1}
                submitTask={value}
                resultEvent={clickResult}
                my_id={authReducer.student_id}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
TaskView.propTypes = {
  task: PropTypes.object,
  updatePeerReviewList: PropTypes.func,
  peerReviewTaskViewList: PropTypes.array.isRequired,
};
export default TaskView;
