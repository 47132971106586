import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Rating,
  TextField,
} from '@mui/material';

const PeerEvalComponent = ({
  peerEvalItems,
  peerEvalMaxScore,
  peerEvalChecked,
  peerEvalScores,
  setPeerEvalScores,
}) => {
  if (!peerEvalChecked) return null;

  useEffect(() => {
    if (
      !peerEvalScores || // null 또는 undefined일 경우
      !Array.isArray(peerEvalScores) || // 배열이 아닐 경우
      peerEvalScores.length !== peerEvalItems.length // 길이가 다르면
    ) {
      const initialScores = Array(peerEvalItems.length).fill(0);
      setPeerEvalScores(initialScores);
    }
  }, [peerEvalItems, peerEvalScores, setPeerEvalScores]);

  const getStarColor = value => {
    if (value <= 1) return '#FFFFFF'; // 1개일 때 하얀색
    if (value <= 2) return '#DFFFD7'; // 2개일 때 연한 녹색
    if (value <= 3) return '#A5F3AB'; // 3개일 때 연두색
    if (value <= 4) return '#5BD875'; // 4개일 때 진한 녹색
    return '#00C500'; // 5개일 때 선명한 녹색
  };

  const handleScoreChange = (index, value) => {
    setPeerEvalScores(prevScores => {
      const newScores = [...prevScores];
      newScores[index] = value; // ✅ 변경한 값이 ''이면 0으로 설정

      return newScores;
    });
  };
  return (
    <Box
      sx={{
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'column',
        width: '98%',
      }}
    >
      {peerEvalItems.map((value, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            height: '3rem',
            padding: '0 1rem',
          }}
        >
          <Box sx={{minWidth: '180px', width: '60%'}}>{value}</Box>

          {/* 평가하기 박스 (정렬) */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1rem',
              flex: 1,
            }}
          >
            {/* 1점: 하트 ON/OFF */}
            {peerEvalMaxScore === 1 && (
              <Box
                onClick={() =>
                  handleScoreChange(index, peerEvalScores[index] ? 0 : 1)
                }
                sx={{
                  cursor: 'pointer',
                  fontSize: '1rem',
                }}
              >
                {peerEvalScores[index] ? '♥️' : '🤍'}
              </Box>
            )}

            {/* 3점: 🙂😐🙁 선택 */}
            {peerEvalMaxScore === 3 && (
              <ToggleButtonGroup
                value={peerEvalScores[index] ?? 2}
                exclusive
                onChange={(e, newValue) => {
                  if (newValue !== null) {
                    handleScoreChange(index, newValue);
                  }
                }}
                sx={{justifyContent: 'flex-start'}}
              >
                <ToggleButton
                  value={1}
                  sx={{
                    backgroundColor:
                      peerEvalScores[index] === 1 ? '#FF9900' : 'transparent',
                    color: peerEvalScores[index] === 1 ? 'white' : 'black',
                    '&:hover': {backgroundColor: '#FF9900', color: 'white'},
                  }}
                >
                  🙁 부족
                </ToggleButton>

                <ToggleButton
                  value={2}
                  sx={{
                    backgroundColor:
                      peerEvalScores[index] === 2 ? '#A0A0A0' : 'transparent',
                    color: peerEvalScores[index] === 2 ? 'white' : 'black',
                    '&:hover': {backgroundColor: '#A0A0A0', color: 'white'},
                  }}
                >
                  😐 보통
                </ToggleButton>

                <ToggleButton
                  value={3}
                  sx={{
                    backgroundColor:
                      peerEvalScores[index] === 3 ? '#00C500' : 'transparent',
                    color: peerEvalScores[index] === 3 ? 'white' : 'black',
                    '&:hover': {backgroundColor: '#00C500', color: 'white'},
                  }}
                >
                  🙂 좋음
                </ToggleButton>
              </ToggleButtonGroup>
            )}

            {/* 5점 이하일 때 별점 사용 */}
            {peerEvalMaxScore === 5 && (
              <Rating
                name={`peer-rating-${index}`}
                value={
                  peerEvalScores[index] ?? Math.round(peerEvalMaxScore / 2)
                }
                max={peerEvalMaxScore}
                onChange={(e, newValue) => {
                  if (newValue !== null) {
                    handleScoreChange(index, newValue);
                  }
                }}
                icon={<StarIcon sx={{fontSize: '2rem', color: '#00C500'}} />}
                emptyIcon={
                  <StarBorderIcon sx={{fontSize: '2rem', color: '#DDD'}} />
                }
              />
            )}

            {peerEvalMaxScore > 5 && (
              <Box sx={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    min: 0,
                    max: peerEvalMaxScore,
                    step: 0.1, // ✅ 0.1 단위 입력 가능
                  }}
                  value={peerEvalScores[index]}
                  onChange={e => {
                    let value = parseFloat(e.target.value);
                    if (isNaN(value)) {
                      handleScoreChange(index, '');
                      return;
                    }
                    if (value < 0) value = 0;
                    if (value > peerEvalMaxScore) value = peerEvalMaxScore;

                    handleScoreChange(index, parseFloat(value.toFixed(1))); // ✅ 소수점 한 자리까지 반올림
                  }}
                  onBlur={() => {
                    if (
                      peerEvalScores[index] === '' ||
                      peerEvalScores[index] === undefined
                    ) {
                      handleScoreChange(index, Math.ceil(peerEvalMaxScore / 2));
                    }
                  }}
                  sx={{width: '4rem', textAlign: 'center'}}
                />
                <Box sx={{fontSize: '0.875rem', color: '#555'}}>
                  / {peerEvalMaxScore}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

// ✅ `peerEvalScores`를 `arrayOf(PropTypes.number)`로 변경
PeerEvalComponent.propTypes = {
  peerEvalItems: PropTypes.array,
  peerEvalMaxScore: PropTypes.number,
  peerEvalChecked: PropTypes.bool,
  peerEvalScores: PropTypes.arrayOf(PropTypes.number), // ✅ 배열로 변경
  setPeerEvalScores: PropTypes.func,
};

export default PeerEvalComponent;
