import React, {useEffect, useMemo, useState, useRef} from 'react';
import {Box, Button, Typography, IconButton} from '@mui/material';
import {useLocation} from 'react-router-dom';
import APIUtils from 'common/utils/APIUtils';
import {AnalysisText} from 'view/result/AnalysisText';
import ApiLoading from 'view/common/ApiViewLoading';
import KeewiCloud from 'view/common/KeewiCloud';
import backgroundMain2 from 'asset/imageV2/background_main2.png';
import CommentListComponent from 'page/peer/CommentList';
import CommentInput from 'page/peer/PeerComment';
import PeerEvalComponent from 'page/peer/EvalItems';
import SaveIcon from 'asset/imageV2/icon_save.svg';
import SavePopup from 'view/popup/PeerReviewSavePopup';
import ToastPopup from 'view/popup/ToastPopup3';
import IconRefresh from 'asset/imageV2/rotatecw.svg';
import {StringUtils} from 'common/utils/StringUtils';

const ResultViewPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const [student_id, setStudentID] = useState(0);
  const [task_id, setTaskID] = useState(0);
  const [my_id, setMyID] = useState(0);
  const [my_name, setMyName] = useState('');
  const [taskText, setTaskText] = useState('');
  const [janreText, setJanreText] = useState('');

  const [titleText, setTitleText] = useState('');
  const [nameText, setNameText] = useState('');
  const [bodyText, setBodyText] = useState('');
  const [dateText, setDateText] = useState('');
  const [peerEvalTimeReserved, setPeerEvalTimeReserved] = useState(0);
  const [peerEvalStartTime, setPeerEvalStartTime] = useState(new Date());
  const [peerEvalEndTime, setPeerEvalEndTime] = useState(new Date());
  const [peerReviewStatus, setPeerReviewStatus] = useState(0);
  const [peerCommentChecked, setPeerCommentChecked] = useState(0);

  const [savePopupVisibility, setSavePopupVisibility] = useState(false);
  const [wordCloudData, setWordCloudData] = useState([
    {text: '키위', value: 5},
    {text: '글쓰기', value: 4},
    {text: '평가', value: 3},
    {text: '엔진', value: 2},
    {text: '피드백', value: 1},
  ]);

  const [peerEvalMaxScore, setPeerEvalMaxScore] = useState(3);
  const [peerEvalChecked, setPeerEvalChecked] = useState(false);
  const [peerEvalScores, setPeerEvalScores] = useState(null);
  const [loveMark, setLoveMark] = useState(0);
  const [good_count, setGoodCount] = useState(0);

  const [toastText, setToastText] = useState('');
  const [toastVisibility, setToastVisibility] = useState(false);
  const toastTimeoutRef = useRef(null); // `setTimeout`을 추적할 ref
  const [toast2FontSizeText, setToast2FontSizeText] = useState('1rem');

  const [peerEvalItems, setPeerEvalItems] = useState([]);
  const [commentList, setCommentList] = useState(null);

  const [peerEvalTeacherMsg, setTeacherCommnetForPeerEval] = useState('');
  const [comments, setComments] = useState(() => []);
  const [myCommentExist, setMyCommentExist] = useState(false);

  const handleCommentSubmit = async newComment => {
    try {
      const current_time = StringUtils.getFormattedString
        ? StringUtils.getFormattedString(new Date())
        : new Date().toISOString().slice(0, 19).replace('T', ' '); // 변환 문제 대비

      const response = await APIUtils.PutPeerComment(
        'new',
        my_id,
        my_name,
        task_id,
        student_id,
        newComment,
        current_time,
      );
      if (response?.data?.comment_list) {
        setCommentList(response.data.comment_list); // 서버에서 받은 최신 댓글 목록으로 갱신

        // 내 댓글이 존재하는지 체크
        const hasMyComment = response.data.comment_list.some(
          comment => Number(comment.a_id) === Number(my_id),
        );
        setMyCommentExist(hasMyComment);
      } else {
        console.warn(
          'handleCommentSubmit: 응답 데이터가 비정상적입니다.',
          response,
        );
      }
    } catch (err) {
      console.error('handleCommentSubmit Error:', err);
    }
  };

  const handleUpdateComment = async (this_mode, this_comment, updatedText) => {
    if (!commentList || commentList.length === 0) {
      return;
    }
    async function PutPeerReComment(
      mode,
      userId,
      name,
      taskId,
      studentId,
      comment,
      current_time,
    ) {
      try {
        const response = await APIUtils.PutPeerComment(
          mode,
          userId,
          name,
          taskId,
          studentId,
          comment,
          current_time,
        );

        setCommentList(response.data.comment_list);
        const hasMyComment = response.data.comment_list.some(
          comment => Number(comment.a_id) === Number(my_id),
        );

        setMyCommentExist(hasMyComment);
      } catch (err) {
        console.log(err);
      }
    }

    let this_student_id = student_id;
    let writer_id = my_id;
    let writer_name = my_name;
    let new_mode = this_mode;
    // "re"라면 my_id는 과제의 student_id이고, reviewer_id는 a_id가 되어야 함
    // 아니라면 my_id는 reviewer_id가 되어야 하고,

    if (this_mode === 're') {
      writer_id = my_id; // 대댓글 모드
      this_student_id = this_comment.a_id;
    } else {
      this_student_id = student_id;
    }
    if (this_mode === 'a-delete') {
      writer_id = this_comment.a_id;
      writer_name = this_comment.a_name;
      new_mode = 'update';
    } else if (this_mode === 'b-delete') {
      writer_id = student_id;
      writer_name = this_comment.a_name;
      this_student_id = this_comment.a_id;
      new_mode = 're';
    }
    const current_time = StringUtils.getFormattedString(new Date());
    const re_comment = updatedText;

    await PutPeerReComment(
      new_mode,
      writer_id,
      writer_name,
      task_id,
      this_student_id,
      re_comment,
      current_time,
    );
  };

  const handleDeleteComment = index => {
    const updatedComments = comments.filter((_, i) => i !== index);
    setComments(updatedComments);
  };
  const commentRef = useRef(null);
  const inputRef = useRef(null);

  function setPeerReviewScreenFromKeewiApiLoad(res) {
    setTaskText(res.data.task_name);
    setJanreText(res.data.task_janre);
    setTitleText(res.data.eval_result.title);
    setNameText(res.data.eval_result.student_name);
    setDateText(res.data.submit_time);
    setPeerEvalTimeReserved(res.data.peer_eval_info.peerEvalTimeReserved);
    setPeerEvalStartTime(res.data.peer_eval_info.peerEvalStartTime);
    setPeerEvalEndTime(res.data.peer_eval_info.peerEvalEndTime);

    if (
      res.data.peer_eval_info.peerEvalTimeReserved != 0 &&
      res.data.peer_review_status == 2 &&
      new Date(res.data.peer_eval_info.peerEvalEndTime) < new Date()
    )
      setPeerReviewStatus(4);
    else setPeerReviewStatus(res.data.peer_review_status);

    setPeerCommentChecked(res.data.peer_eval_info.peerCommentChecked);

    const wordCloudData = res.data.eval_result.word_cloud;
    setWordCloudData(wordCloudData);
    setBodyText(
      AnalysisText.createHtmlText(
        res.data.eval_result.paragraph_texts,
        res.data.eval_result.type,
      ),
    );
    setTeacherCommnetForPeerEval(res.data.peer_eval_info.peerEvalTeacherMsg);
    setPeerEvalChecked(
      res.data.peer_eval_info.peerEvalChecked === 1 ? true : false,
    );
    if (res.data.peer_eval_info.peerEvalChecked == 1) {
      setPeerEvalItems(res.data.peer_eval_info.peerEvalItems);
      setPeerEvalMaxScore(res.data.peer_eval_info.peerEvalMaxScore);
    }
    setPeerEvalScores(res.data.review_result);
    setGoodCount(res.data.good_count);
    setLoveMark(res.data.good_count == 0 ? false : true);
    setCommentList(res.data.comment_list ?? []);
    if (res.data.comment_list && res.data.comment_list.length > 0) {
      const hasMyComment = res.data.comment_list.some(
        comment => Number(comment.a_id) === Number(my_id),
      );
      setMyCommentExist(hasMyComment);
    } else {
      setMyCommentExist(false); // ✅ 댓글이 없을 경우 false로 설정
    }

    setLoading(false);
  }

  const memoizedWordCloud = useMemo(
    () => <KeewiCloud data={wordCloudData} height={300} />,
    [wordCloudData],
  );

  const closeWindow = () => {
    window.close(); // 현재 창 닫기
  };

  function ShowResult(text, time, font_size) {
    setSavePopupVisibility(false);
    setToastText(text);
    setToastVisibility(true);
    setToast2FontSizeText(font_size);

    // 기존 `setTimeout`이 실행 중이라면 취소
    if (toastTimeoutRef.current) {
      clearTimeout(toastTimeoutRef.current);
    }

    // 새로운 `setTimeout` 설정 후 페이지 이동
    toastTimeoutRef.current = setTimeout(() => {
      setToastVisibility(false);
    }, time);
  }

  async function peerReviewSave() {
    try {
      const res = await APIUtils.PutPeerReviewResult(
        my_id,
        my_name,
        task_id,
        student_id,
        peerEvalScores,
      );
    } catch (err) {
      console.log(err);
    }
    setSavePopupVisibility(false);
    return;
  }
  async function handleLoveMarkChange(value) {
    if (value == 0) ShowResult('🤍 좋아요를 취소합니다.', 700, '1rem');
    else ShowResult('❤️ 좋아요를 보냅니다.', 700, '1rem');
    setLoveMark(value);
    try {
      const res = APIUtils.SetLoveScore(
        my_id,
        my_name,
        task_id,
        student_id,
        value,
      );
    } catch (err) {
      console.log(err);
    }
    return;
  }

  const handleScrollToComment = () => {
    if (commentRef.current) {
      commentRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 300);
  };

  function createOverallComment() {
    if (peerEvalTeacherMsg == '') return <></>;
    return (
      <>
        <Box
          style={{
            marginTop: '1rem',
            marginLeft: '1rem',
            fontSize: '1rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
          }}
        >
          동료 평가 안내
        </Box>
        <Box
          style={{
            marginLeft: '1rem',
            marginRight: '1rem',
            whiteSpace: 'pre-line',
            backgroundColor: '#EBFBF9',
            fontSize: '0.875rem',
            padding: '1rem',
            borderRadius: '8px',
            lineHeight: '1.5',
          }}
        >
          {peerEvalTeacherMsg}
        </Box>
      </>
    );
  }

  function createPeerComments() {
    if (commentList.length === 0 || peerCommentChecked != 1) return <></>;
    return (
      <Box
        style={{
          width: '100%',
          marginTop: '0.5rem',
        }}
      >
        <Box
          style={{
            marginLeft: '1rem',
            fontSize: '1rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
          }}
        >
          댓글
          <IconButton
            style={{
              fontSize: '0.875rem',
              marginLeft: '0.5rem',
            }}
            onClick={() => {
              setAnalasisResult(my_id, student_id, task_id);
            }}
          >
            새로고침
            <img
              src={IconRefresh}
              style={{
                marginLeft: '0.5rem',
                width: '1rem',
                height: '1rem',
              }}
            />
          </IconButton>
        </Box>
        <Box style={{marginLeft: '1rem'}}>
          <CommentListComponent
            commentData={commentList}
            handleUpdateComment={handleUpdateComment}
            my_id={my_id}
            owner_id={student_id}
            student_name={nameText}
          />
        </Box>
      </Box>
    );
  }

  useEffect(() => {
    if (commentList && commentList.length > 0) {
      const hasMyComment = commentList.some(
        comment => Number(comment.a_id) === Number(my_id),
      );
      setMyCommentExist(hasMyComment);
    } else {
      setMyCommentExist(false); // ✅ 댓글이 없을 경우 false로 설정
    }
  }, [commentList]);

  const setAnalasisResult = async (my_id, student_id, task_id) => {
    try {
      const response = await APIUtils.GetPeerReviewContent(
        my_id,
        task_id,
        student_id,
      );

      setPeerReviewScreenFromKeewiApiLoad(response);
    } catch (err) {
      console.error('Error fetching peer review content:', err);
    }
  };

  useEffect(() => {
    const setAnalasisResult = async (my_id, student_id, task_id) => {
      try {
        const response = await APIUtils.GetPeerReviewContent(
          my_id,
          task_id,
          student_id,
        );
        setPeerReviewScreenFromKeewiApiLoad(response);
      } catch (err) {
        console.log(err);
      }
    };

    function decodeBase64(input) {
      try {
        if (!input) throw new Error('🚨 디코딩할 문자열이 없습니다.');

        let base64 = input.slice(1, -1).replace(/-/g, '+').replace(/_/g, '/');

        if (typeof window !== 'undefined') {
          return decodeURIComponent(escape(window.atob(base64)));
        } else {
          return Buffer.from(base64, 'base64').toString('utf-8');
        }
      } catch (e) {
        console.error('🚨 Base64 디코딩 중 오류 발생:', e.message);
        return '';
      }
    }

    const fetchData = async () => {
      try {
        const query = new URLSearchParams(location.search);
        const encodedData = query.get('gul');
        if (!encodedData)
          throw new Error('🚨 인코딩된 데이터(gul)가 없습니다!');
        const decodedString = decodeBase64(encodedData);
        if (!decodedString) throw new Error('🚨 Base64 디코딩 실패!');
        const decodedParts = decodedString.split(':');
        if (decodedParts.length < 4)
          throw new Error('🚨 디코딩된 데이터가 올바른 형식이 아닙니다!');
        const decodeMyName = decodeURIComponent(decodedParts[0]);
        const myid = parseInt(decodedParts[1], 10);
        const taskid = parseInt(decodedParts[2], 10);
        const studentid = parseInt(decodedParts[3], 10);
        setMyName(decodeMyName);
        setMyID(myid);
        setTaskID(taskid);
        setStudentID(studentid);
        setAnalasisResult(myid, studentid, taskid);
      } catch (err) {
        console.error('🚨 fetchData 오류:', err.message);
      }
    };
    fetchData();
  }, []);

  if (loading) return <ApiLoading loading={loading} />;
  return (
    <>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <SavePopup
          visibility={savePopupVisibility}
          cancelEvent={() => setSavePopupVisibility(false)} // 팝업 닫기
          completeEvent={peerReviewSave} // 하루 동안 팝업 표시 안 함
        />

        <Box
          style={{
            width: '95%',
            backgroundColor: '#F0FBFA',
            backgroundImage: `url(${backgroundMain2})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            display: 'flex',
            flexDirection: 'column',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            paddingTop: '2rem',
            paddingBottom: '2rem',
          }}
        >
          <Box
            style={{fontSize: '1.5rem', marginLeft: '1rem', fontWeight: 'bold'}}
          >
            {titleText}
          </Box>
          <Box
            style={{
              display: 'flex',
              marginTop: '-2rem',
              justifyContent: 'flex-end', // 오른쪽 끝으로 정렬
            }}
          >
            <button
              className="print-hide"
              style={{
                color: '#898989',
                fontWeight: 'bold',
                fontSize: '0.874rem',
                border: 'none',
                borderRadius: '1.925rem',
                padding: '0.5rem 1rem',
                backgroundColor: 'white',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                height: '3rem',
                width: '4rem',
                marginRight: '1rem',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
              }}
              onClick={closeWindow}
            >
              닫기
            </button>
          </Box>
          <Box
            style={{
              fontSize: '1rem',
              marginLeft: '1rem',
              marginTop: '2rem',
              fontWeight: 'bold',
            }}
          >
            {`과제 : ${taskText} [${janreText}]`}
          </Box>
          <Box
            style={{
              fontSize: '1rem',
              marginLeft: '1rem',
              fontWeight: 'bold',
            }}
          >
            {`이름 : ${nameText} (${dateText.substring(0, 16)})`}
          </Box>
        </Box>
        <Box
          style={{
            marginTop: '1rem',
            paddingBottom: '1rem',
            width: '95%',
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              flex: 1,
            }}
          >
            <Box text={toastText} visibility={toastVisibility} />
            <ToastPopup
              text={toastText}
              visibility={toastVisibility}
              fontSizeStr={toast2FontSizeText}
            />
            <Box
              style={{
                width: '100%',
                backgroundColor: 'white',
                borderRadius: '1.25rem',
                border: '1px solid var(--01-gray-07, #E9EBED)',
                boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
                padding: '2rem 1%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '2rem',
              }}
            >
              <Box
                style={{
                  width: '70%',
                  textAlign: 'left',
                  alignSelf: 'flex-start',
                  position: 'relative',
                  marginBottom: '5rem',
                }}
              >
                {bodyText}
              </Box>
              <Box
                className="print-hide"
                style={{
                  width: '30%',
                  minHeight: '20rem',
                  backgroundColor: '#F7F8FA',
                  borderRadius: '1.25rem',
                  padding: '1.25rem 0rem',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {memoizedWordCloud}
              </Box>
            </Box>
          </Box>
          <Button
            style={{
              display: my_id == student_id ? 'none' : '',
              marginLeft: '1rem',
              width: '3rem',
              fontSize: '3rem',
              marginTop: '-10rem',
            }}
            onClick={() => handleLoveMarkChange(loveMark ? 0 : 1)}
          >
            {loveMark ? '❤️' : '🤍'}
          </Button>
        </Box>

        <Box
          style={{
            display: peerEvalChecked ? 'block' : 'none',
            marginTop: '1rem',
            paddingBottom: '1rem',
            width: '95%',
          }}
        >
          <Box
            style={{
              display: 'block',
              justifyContent: 'center',
              width: '98%',
              flex: 1,
            }}
          >
            <Box
              style={{
                width: '100%',
                backgroundColor: 'white',
                borderRadius: '1.25rem',
                border: '1px solid var(--01-gray-07, #E9EBED)',
                boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
                padding: '0.5rem 1%',
                display: 'block',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '2rem',
              }}
            >
              <Box
                style={{
                  width: '100%',
                  textAlign: 'left',
                  alignSelf: 'flex-start',
                  position: 'relative',
                  marginBottom: '1rem',
                }}
              >
                {createOverallComment()}
              </Box>
              <Box
                style={{
                  width: '100%',
                  textAlign: 'left',
                  alignSelf: 'flex-start',
                  position: 'relative',
                  marginBottom: '1rem',
                }}
              >
                {peerEvalTimeReserved == 1 && (
                  <Box
                    style={{
                      marginTop: '1rem',
                      marginLeft: '1rem',
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      marginBottom: '1rem',
                      color: '#898989',
                    }}
                  >
                    동료 평가 기간 : {peerEvalStartTime.substring(0, 16)} ~{' '}
                    {peerEvalEndTime.substring(0, 16)}
                  </Box>
                )}
                {peerEvalTimeReserved == 0 && (
                  <Box
                    style={{
                      marginTop: '1rem',
                      marginLeft: '1rem',
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      marginBottom: '1rem',
                      color: '#898989',
                    }}
                  >
                    동료 평가 상태 :{' '}
                    {peerReviewStatus == 1
                      ? '시작전'
                      : peerReviewStatus == 2
                      ? '진행중'
                      : peerReviewStatus == 3
                      ? '중단중'
                      : peerReviewStatus == 4
                      ? '종료'
                      : '공유중'}
                  </Box>
                )}
              </Box>

              {peerEvalChecked && my_id != student_id && (
                <>
                  <Box
                    style={{
                      display: 'flex',
                      width: '80%',
                    }}
                  >
                    <Typography
                      sx={{
                        marginLeft: '1rem',
                        fontSize: '0.875rem',
                        color: '#4FD7C3',
                      }}
                    >
                      ※ 동료 평가 후 저장하기 버튼을 눌러야 평가 결과에
                      반영됩니다.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <PeerEvalComponent
                      peerEvalItems={peerEvalItems}
                      peerEvalMaxScore={peerEvalMaxScore}
                      peerEvalChecked={peerEvalChecked}
                      peerEvalScores={peerEvalScores}
                      setPeerEvalScores={setPeerEvalScores}
                    />
                  </Box>
                  <Box>
                    <button
                      className="print-hide"
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '0.874rem',
                        border: 'none',
                        borderRadius: '1.925rem',
                        padding: '0.5rem 1rem',
                        backgroundColor: '#35D2BB',
                        whiteSpace: 'nowrap',
                        cursor: 'pointer',
                        height: '3rem',
                        width: '7rem',
                        margin: '1rem 1rem 1rem 2rem',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                      }}
                      onClick={() => {
                        setSavePopupVisibility(true);
                      }}
                    >
                      저장하기
                    </button>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
        {commentList.length > 0 && (
          <Box
            style={{
              display: commentList.length > 0 ? 'block' : 'none',
              marginTop: '1rem',
              paddingBottom: '1rem',
              width: '95%',
            }}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                flex: 1,
              }}
            >
              <Box
                style={{
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: '1.25rem',
                  border: '1px solid var(--01-gray-07, #E9EBED)',
                  boxShadow: '4px 5px 18px 5px rgba(111, 121, 128, 0.08)',
                  padding: '0.5rem 1%',
                  display: 'flex', // Flexbox 적용
                  justifyContent: 'center', // 중앙 정렬
                  alignItems: 'center', // 세로 중앙 정렬
                  gap: '2rem', // 본문과 단어 클라우드 사이 간격 조정
                }}
              >
                <Box
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    alignSelf: 'flex-start', // ✅ 위쪽 정렬
                    position: 'relative', // ✅ 상대 위치 설정
                    marginBottom: '1rem',
                  }}
                >
                  {createPeerComments()}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: !myCommentExist ? 'block' : 'none',
            width: '95%',
            padding: '1rem',
          }}
        >
          <Box>
            <CommentInput onSubmit={handleCommentSubmit} />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ResultViewPage;
