import React, {useEffect, useState, useRef} from 'react';
import {Box, Button, TextField, IconButton} from '@mui/material';
import PropTypes from 'prop-types';
import APIUtils from 'common/utils/APIUtils';
import {useSelector} from 'react-redux';
import ApiLoading from 'view/common/ApiLoading2';
import Bubble from 'view/chat/Bubble';
import emojiRegex from 'emoji-regex';
import IconSend from 'asset/imageV2/icon_send.svg';
import IconKeewichat from 'asset/imageV2/icon_keewichat.svg';
import IconCancel from 'asset/imageV2/icon_cancel.svg';
import IconRefresh from 'asset/imageV2/icon_refresh.svg';

const OverlayView = props => {
  const authReducer = useSelector(state => state.authReducer);
  const messageEndRef = useRef();
  const [loading, setLoading] = useState(true);
  const [messageList, setMessageList] = useState([]);
  const [chatText, setChatText] = useState('');
  const [chatDiabled, setChatDisabled] = useState(false);
  const handleChatText = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 500) {
        inputVal = inputVal.substring(0, 500);
      }
      setChatText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };
  function getChatLogs() {
    const chatLogsAPI = async () => {
      try {
        const response = await APIUtils.ChatLogs(
          props.taskID,
          authReducer.student_id,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    const chatInitializeAPI = async () => {
      try {
        const response = await APIUtils.ChatInitialize(
          props.taskID,
          authReducer.student_id,
          props.chatTask.task_name,
          props.chatTask.task_message,
          props.chatTask.task_janre,
          props.chatTask.keyword_check,
          props.chatTask.keyword,
          props.chatTask.length_check,
          props.chatTask.min_length,
          props.chatTask.max_length,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    chatLogsAPI()
      .then(ret => {
        if (ret.data.ret_code == 1000) {
          setMessageList(ret.data.messages);
          setLoading(false);
          return false;
        } else if (ret.data.ret_code == 1001) {
          return chatInitializeAPI();
        } else {
          alert('채팅창 불러오기 실패! : ' + ret.data.ret_code);
          setLoading(false);
          return false;
        }
      })
      .then(r => {
        if (!r) {
          return;
        }
        if (r.data.ret_code == 1000) {
          setMessageList(prev => {
            let copyMessage = [...prev, r.data.message];
            return copyMessage;
          });
          setLoading(false);
        } else {
          alert('채팅창 불러오기 실패!!: ' + r.data.ret_code);
          setLoading(false);
        }
      });
  }
  function resetChatLogs() {
    setLoading(true);
    const chatInitializeAPI = async () => {
      try {
        const response = await APIUtils.ChatInitialize(
          props.taskID,
          authReducer.student_id,
          props.chatTask.task_name,
          props.chatTask.task_message,
          props.chatTask.task_janre,
          props.chatTask.keyword_check,
          props.chatTask.keyword,
          props.chatTask.length_check,
          props.chatTask.min_length,
          props.chatTask.max_length,
        );

        return response;
      } catch (err) {
        console.log(err);
      }
    };

    chatInitializeAPI().then(r => {
      if (r.data.ret_code == 1000) {
        setMessageList([r.data.message]);
        setLoading(false);
      } else {
        alert('채팅창 리셋 실패! : ' + r.data.ret_code);
        setLoading(false);
      }
    });
  }
  function sendMessage(inputMessage) {
    if (inputMessage.trim() == '') return;
    setChatText('');
    setChatDisabled(true);
    setMessageList(prev => {
      let copyMessage = [
        ...prev,
        {content: inputMessage, role: 'user'},
        {content: '', role: 'loading'},
      ];
      return copyMessage;
    });
    const sendMessageAPI = async () => {
      try {
        const response = await APIUtils.ChatDialogue(
          props.taskID,
          authReducer.student_id,
          inputMessage,
          props.chatTask.task_name,
          props.chatTask.task_message,
          props.chatTask.task_janre,
          props.chatTask.keyword_check,
          props.chatTask.keyword,
          props.chatTask.length_check,
          props.chatTask.min_length,
          props.chatTask.max_length,
        );

        return response;
      } catch (err) {
        console.log(err);
      }
    };
    sendMessageAPI().then(ret => {
      if (ret.data.ret_code == 1000) {
        setMessageList(prev => {
          let copyMessage = [
            ...prev.filter(val => val.role != 'loading'),
            ret.data.message,
          ];
          return copyMessage;
        });
        setChatDisabled(false);
        return false;
      } else {
        setMessageList(prev => {
          let copyMessage = [...prev.filter(val => val.role != 'loading')];
          copyMessage.slice(0, -1);
          return copyMessage;
        });
        setChatDisabled(false);
        alert('키위챗 대화 전송 실패');
        return false;
      }
    });
  }
  useEffect(() => {
    if (props.taskID != 0 && props.chatTask != undefined) getChatLogs();
  }, [props.taskID, props.chatTask]);
  useEffect(() => {
    if (messageEndRef.current != undefined)
      messageEndRef.current.scrollIntoView({behavior: 'smooth'});
  }, [messageList]);
  useEffect(() => {
    if (messageEndRef.current != undefined)
      messageEndRef.current.scrollIntoView({behavior: 'instant'});
  }, [props.chatVisibility]);
  if (props.chatTask != undefined && props.chatTask.pre_keewichat == 0) {
    return <></>;
  }
  if (props.chatVisibility) {
    if (loading) {
      return (
        <Box
          style={{
            width: '30%',
            position: 'fixed',
            bottom: 0,
            top: 0,
            right: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            zIndex: 3,
            backgroundColor: '#fdfffa',
          }}
        >
          <ApiLoading />
        </Box>
      );
    }
    return (
      <Box
        style={{
          height: '100%',
          width: '30%',
          position: 'fixed',
          bottom: 0,
          top: 0,
          right: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          zIndex: 3,
          backgroundColor: '#fdfffa',
        }}
      >
        <Box
          style={{
            display: 'flex',
            marginTop: '0.5rem',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <img
              src={IconKeewichat}
              alt="IconKeewichat"
              style={{
                width: '1rem',
                height: '1rem',
                marginRight: '0.5rem',
              }}
            />
            글쓰기 도우미{' '}
            <span style={{fontWeight: 'bold', marginLeft: '0.3rem'}}>
              키위챗
            </span>
            <IconButton
              onClick={() => {
                resetChatLogs();
              }}
            >
              <img
                src={IconRefresh}
                style={{
                  width: '2rem',
                  height: '2rem',
                }}
              />
            </IconButton>
          </Box>

          <IconButton
            onClick={() => {
              props.handleVisibility();
            }}
          >
            <img src={IconCancel} />
          </IconButton>
        </Box>
        <Box
          style={{
            fontSize: '0.8rem',
            borderBottom: '2px solid #75e275',
            padding: '0.5rem 0.2rem',
          }}
        >
          키위챗이 실수할 수도 있으니, 중요한 정보는 직접 확인하세요!
        </Box>

        <Box
          style={{
            flex: 1,
            display: 'flex',
            width: '100%',
            // height: '100%',
            flexDirection: 'column',
            overflowY: 'auto',
            paddingTop: '1.5rem',
          }}
        >
          {messageList.map((value, idx) => {
            return (
              <Bubble
                key={'bubble' + idx}
                content={value.content}
                role={value.role}
              />
            );
          })}
          <Box ref={messageEndRef}></Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            padding: '0.5rem',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TextField
            autoComplete="off"
            size="small"
            variant="outlined"
            fullWidth
            multiline
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '0px solid black',
                },

                '&:hover fieldset': {
                  border: '0px solid black',
                },
                '&.Mui-focused fieldset': {
                  border: '0px solid black',
                },
              },
            }}
            InputProps={{
              style: {
                fontSize: '1rem',
                backgroundColor: 'white',
                border: '2px solid #D8DCDE',
                borderRadius: '0.5rem',
              },
            }}
            value={chatText}
            onChange={handleChatText}
            onKeyDown={ev => {
              if (ev.key === 'Enter' && !ev.shiftKey) {
                ev.preventDefault();
                if (chatDiabled) return;
                sendMessage(chatText);
              }
            }}
          ></TextField>
          <IconButton
            disabled={chatDiabled}
            onClick={() => {
              sendMessage(chatText);
            }}
            style={{
              width: '3.3rem',
              height: '3.3rem',
            }}
          >
            <img
              src={IconSend}
              alt="IconSend"
              style={{
                width: '2.7rem',
                height: '2.7rem',
              }}
            />
          </IconButton>
        </Box>
      </Box>
    );
  }
  return (
    <Box
      style={{
        position: 'fixed',
        bottom: 0,
        top: 0,
        right: '3rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        zIndex: 3,
      }}
    >
      <Button
        style={{
          width: '5rem',
          height: '5rem',
          borderRadius: '50%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#BCEB80',
          color: 'black',
          fontSize: '0.8rem',
          fontWeight: 'bold',
          marginTop: '1rem',
          marginBottom: '8rem',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        }}
        onClick={() => {
          props.handleVisibility();
        }}
      >
        <img
          src={IconKeewichat}
          alt="IconKeewichat"
          style={{
            width: '1.2rem',
            height: '1.2rem',
          }}
        />
        <Box>{'키위챗'}</Box>
      </Button>
    </Box>
  );
};
OverlayView.propTypes = {
  chatVisibility: PropTypes.bool,
  handleVisibility: PropTypes.func,
  taskID: PropTypes.number,
  chatTask: PropTypes.object,
};
export default OverlayView;
