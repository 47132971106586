import React from 'react';
import {Box, Button} from '@mui/material';
import PropTypes from 'prop-types';
const ItemView = props => {
  if (!props.visibility) {
    return <></>;
  }
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
        backgroundColor: 'rgba(55,55,55,0.5)',
      }}
    >
      <Box
        style={{
          width: '28rem',
          textAlign: 'left',
          borderRadius: '20px',
          background: 'white',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          paddingBottom: '2rem',
          paddingTop: '1rem',
          color: 'black',
        }}
      >
        <Box
          style={{
            fontSize: '1.7rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
            marginTop: '0.5rem',
            display: 'flex',
            gap: '0.5rem',
            alignItems: 'center',
          }}
        >
          과제 제출
        </Box>
        <Box>
          {
            '마지막 수정 기회이므로 조건을 충족하지 않았지만, 선생님께 제출되었습니다.'
          }
        </Box>

        <Box
          style={{
            marginTop: '2rem',
            borderTop: '2px dotted #717171',
          }}
        ></Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
          }}
        >
          <Button
            style={{
              backgroundColor: '#00C500',
              width: '95%',
              color: 'white',
              fontSize: 'calc(1rem + 0.2vw)',
              fontWeight: 'bold',
              borderRadius: '10px',
              paddingTop: '0.7rem',
              paddingBottom: '0.7rem',
            }}
            onClick={() => {
              props.completeEvent();
            }}
          >
            확인
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
ItemView.propTypes = {
  visibility: PropTypes.bool,
  completeEvent: PropTypes.func,
};
export default ItemView;
