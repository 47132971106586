import {Backdrop, Button, Box} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import logo from 'asset/imageV2/logo_keewi_learn.png';
import {useNavigate} from 'react-router-dom';
import {saveLogin, removeLogin} from 'common/reducer/auth';
import home from 'asset/image/icon_home.png';
import cap from 'asset/image/icon_graduation_cap.png';
import logout from 'asset/image/icon_logout.png';
import youtube from 'asset/image/icon_youtube.png';
// import logo from 'asset/imageV2/logo_keewi_learn.png';

const MobileMenu = props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authReducer = useSelector(state => state.authReducer);
  const isLogin = authReducer.isLogin;
  const name = authReducer.student_name;

  if (isLogin) {
    return (
      <>
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: theme => theme.zIndex.drawer + 1,
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'start',
          }}
          open={true}
          onClick={() => {
            props.clickOutside();
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
              alignItems: 'start',
              backgroundColor: 'white',
              width: '70%',
              height: '80%',
            }}
          >
            <Box
              style={{
                width: '84%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8%',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '0.5rem',
                }}
              >
                <Box>
                  <img src={logo} alt={'logoT'} style={{height: '30px'}} />
                </Box>
                <Box
                  style={{
                    color: '#717171',
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                  }}
                >
                  {name + ' 님'}
                </Box>
              </Box>
            </Box>
            <Box
              style={{
                width: '100%',
                height: '1px',
                backgroundColor: '#d9d9d9',
              }}
            />
            <Box
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                alignItems: 'center',
                fontSize: '1.1rem',
                fontWeight: 'medium',
              }}
            >
              <Box
                style={{
                  width: '90%',
                  marginTop: '1rem',
                  color: '#717171',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => {
                  navigate('/');
                }}
              >
                <img
                  src={home}
                  alt={'home'}
                  style={{
                    height: '1.2rem',
                    marginRight: '0.5rem',
                  }}
                />
                홈
              </Box>
              <Box
                style={{
                  width: '90%',
                  marginTop: '1.4rem',
                  color: '#717171',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => {
                  navigate('/mypage', {replace: true});
                }}
              >
                <img
                  src={cap}
                  alt={'cap'}
                  style={{height: '1.2rem', marginRight: '0.5rem'}}
                />
                나의 선생님
              </Box>
            </Box>
            <Box
              style={{
                marginTop: '1.5rem',
                width: '100%',
                height: '1px',
                backgroundColor: '#d9d9d9',
              }}
            />
            <Box
              style={{
                marginTop: '0.3rem',
                width: '90%',
                height: '90%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '5%',
              }}
            >
              <Box style={{color: '#717171', fontSize: '1.0rem'}}>
                ※ 대부분의 기능은{' '}
              </Box>
              <Box style={{color: '#717171', fontSize: '1.0rem'}}>
                <span style={{fontWeight: 'bold'}}> 데스크톱 사이트</span>{' '}
                모드에서 지원합니다.
              </Box>
              <Box
                style={{
                  color: '#717171',
                  marginTop: '1rem',
                  fontSize: '1.0rem',
                }}
              >
                ※ 브라우져에서 [데스크톱 사이트] 모드를 선택하세요.
              </Box>
              <Box
                style={{
                  height: '100%',
                  color: '#717171',
                  fontSize: '0.9rem',
                  display: 'flex',
                  alignItems: 'end',
                }}
                onClick={() => {
                  dispatch(removeLogin());
                }}
              >
                <img
                  src={logout}
                  alt={'logout'}
                  style={{
                    height: '1.1rem',
                    marginRight: '0.5rem',
                  }}
                />
                로그아웃
              </Box>
            </Box>
          </Box>
        </Backdrop>
      </>
    );
  }
  return (
    <>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: theme => theme.zIndex.drawer + 1,
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'start',
        }}
        open={true}
        onClick={() => {
          props.clickOutside();
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
            backgroundColor: 'white',
            width: '70%',
            height: '80%',
          }}
        >
          <Box
            style={{
              width: '84%',
              display: 'flex',
              margin: '8%',
              alignItems: 'center',
            }}
          >
            <img src={logo} alt={'logoT'} style={{height: '30px'}} />
            <Box
              style={{
                color: '#717171',
                marginLeft: '1rem',
                fontWeight: 'bold',
                fontSize: '1.1rem',
              }}
            >
              {'키위런'}
            </Box>
          </Box>
          <Box
            style={{
              width: '100%',
              height: '1px',
              backgroundColor: '#d9d9d9',
            }}
          />
          <Box
            style={{
              marginTop: '1rem',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              // justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              style={{
                marginTop: '1rem',
                backgroundColor: '#00C500',
                borderRadius: '10px',
                color: 'white',
                width: '80%',
                fontWeight: 'medium',
                fontSize: '1rem',
              }}
              onClick={() => {
                navigate('/login');
              }}
            >
              간편 회원가입/로그인
            </Button>

            <Button
              style={{
                marginTop: '1rem',
                color: '#00C500',
                backgroundColor: 'white',
                borderRadius: '10px',
                border: '1px solid #00C500',
                width: '80%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                fontSize: '1rem',
              }}
              onClick={() =>
                window.open(
                  'https://keewi-t.notion.site/KEEwi-LEARN-a98343d0b17a4ab7ab90b6b58ab00527?pvs=4',
                  '_blank',
                )
              }
            >
              <img
                src={youtube}
                alt={'youtube'}
                style={{height: '30px', marginRight: '0.5rem'}}
              />
              키위런 사용법
            </Button>
          </Box>
        </Box>
      </Backdrop>
    </>
  );
};

MobileMenu.propTypes = {
  clickOutside: PropTypes.func,
};

export default MobileMenu;
