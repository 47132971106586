import React, {useEffect, useState} from 'react';
import {
  Box,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
} from '@mui/material';
import {AnalysisText} from 'view/result/AnalysisText';
import {Palette} from 'common/utils/ColorUtils';
import sampleText from 'asset/json/sample_text.json';
import {useNavigate, useLocation} from 'react-router-dom';
import {
  MarginBoxComponent,
  TitleComponent,
  BookComponent,
  LayerBoxComponent,
  CenteredButton,
  SingleLineComponent,
  CustomTextField1,
  CustomTextField2,
  CustomTextField3,
} from 'view/common/MobileComponents';
import HeaderView from 'view/common/MobileHeader';
import {useSelector} from 'react-redux';
import FooterView from 'view/common/MobileFooter';
import emojiRegex from 'emoji-regex';
import APIUtils from 'common/utils/APIUtils';
import {StringUtils} from 'common/utils/StringUtils';
import ApiLoading from 'view/common/ApiLoading';
import SubmitDonePopup from 'view/popupMobile/SubmitDonePopup';
import NoticketPopup from 'view/popupMobile/NoTicket2';

const WritingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authReducer = useSelector(state => state.authReducer);

  const [loading, setLoading] = useState(false);

  const [sample, setSample] = useState({});
  const [selectValue, setSelectValue] = useState('');
  const [selectOption, setSelectOption] = useState([]);
  const [bodyText, setBodyText] = useState('');
  const [titleText, setTitleText] = useState('');

  const [taskID, setTaskID] = useState(0);
  const [taskTitleText, setTaskTitleText] = useState('');
  const [taskMessage, setTaskMessage] = useState('');
  const [taskJanre, setTaskJanre] = useState('');

  const [noticketVisibility, setNoticketVisibility] = useState(false);

  function noticketComplete() {
    setNoticketVisibility(false);
    navigate('/');
  }
  const [submitDoneVisibility, setSubmitDoneVisibility] = useState(false);
  function submitDoneConfirm() {
    setSubmitDoneVisibility(false);
    navigate('/mypage');
  }

  const handleSelect = event => {
    const dictKey = selectOption[event.target.value].text;

    setSelectValue(event.target.value);
    setBodyText(sample[dictKey]);
    setTitleText(dictKey);
  };
  const handleBody = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 10000) {
        inputVal = inputVal.substring(0, 10000);
      }
      setBodyText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };
  const handleTitle = event => {
    let inputVal = event.target.value;
    const EMOJI_REGEX = emojiRegex();
    if (!EMOJI_REGEX.test(inputVal)) {
      if (inputVal.length > 50) {
        inputVal = inputVal.substring(0, 50);
      }
      setTitleText(inputVal);
    } else {
      alert('이모지는 입력이 불가능합니다.');
    }
  };

  function navigateToResult(disabled) {
    if (disabled) {
      alert('학생 이름, 제목, 본문을 모두 입력하셔야 제출하기가 가능합니다.');
      return;
    }
    const trimmedText = StringUtils.getTrimmedBody(bodyText);
    analyzeEvent(titleText, taskJanre, trimmedText, taskID);
  }
  function analyzeEvent(new_title, new_janre, new_text, new_task_id) {
    setLoading(true);
    const fetAnalasisResult = async (
      inputText,
      inputTitle,
      inputJanre,
      inputTaskID,
    ) => {
      try {
        const response = await APIUtils.TaskEval(
          authReducer.student_id,
          inputTaskID,
          inputTitle,
          inputJanre,
          inputText,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    const saveResult = async result => {
      try {
        const response = await APIUtils.TaskEvalSave(
          authReducer.student_id,
          new_task_id,
          result,
        );
        return response;
      } catch (err) {
        console.log(err);
      }
    };
    const submitResult = async () => {
      try {
        const response = await APIUtils.TaskSubmit(
          authReducer.student_id,
          new_task_id,
        );
        return response;
      } catch (err) {
        console.log(err);
        return null;
      }
    };

    fetAnalasisResult(new_text, new_title, new_janre, new_task_id)
      .then(res => {
        if (res.data.ret_code == 1000) {
          const jsonResult = AnalysisText.createSavingJsonFromEvalResult(
            res.data.eval_result,
            new_title,
            new_text,
            '성인',
            authReducer.student_name,
            1,
          );
          return saveResult(jsonResult);
        }
      })
      .then(re => {
        if (re.status == 200 && re.data.ret_code == 1000) {
          return submitResult();
        }
      })
      .then(r => {
        if (r.status == 200 && r.data.ret_code == 1000) {
          setSubmitDoneVisibility(true);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }

  function getPassInfo() {
    const passResult = async () => {
      try {
        const response = await APIUtils.AccountPassCheck(
          authReducer.student_id,
        );
        if (response.status == 200 && response.data.ret_code == 1000) {
          if (!response.data.pass_available) {
            setNoticketVisibility(true);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    passResult();
  }
  function getTaskInfo(task_id) {
    const taskResult = async () => {
      try {
        const response = await APIUtils.TaskCall(
          authReducer.student_id,
          task_id,
        );
        if (response.status == 200 && response.data.ret_code == 1000) {
          setTaskTitleText(
            '[' + response.data.task_janre + '] ' + response.data.task_name,
          );
          setTaskMessage(response.data.task_message);
          setTaskJanre(response.data.task_janre);
          setTitleText(response.data.title);
          setBodyText(response.data.text);
        }
      } catch (err) {
        console.log(err);
      }
    };
    taskResult();
  }
  function createSampleSelector() {
    if (process.env.REACT_APP_ENV == 'live') {
      return <></>;
    } else {
      return (
        <FormControl
          size="small"
          sx={{
            m: 1,
            minWidth: 200,
            color: Palette.white,
          }}
        >
          <InputLabel
            id="sample-select-label"
            style={{
              color: 'blue',
            }}
          >
            예시 글 가져오기
          </InputLabel>
          <Select
            labelId="sample-select-label"
            id="sample-select"
            onChange={handleSelect}
            value={selectValue}
            label="예시 글 가져오기"
            style={{
              color: Palette.white,
              backgroundColor: Palette.grey1,
            }}
          >
            {selectOption.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
  }
  useEffect(() => {
    let input_task_id = 0;
    if (
      location.state == null &&
      window.localStorage.getItem('task_id') == null
    ) {
      navigate('/', {replace: true});
      return;
    } else if (location.state == null) {
      input_task_id = Number(window.localStorage.getItem('task_id'));
    } else {
      input_task_id = location.state.task_id;
    }

    let dict = {};
    let selectList = [];
    sampleText.forEach((item, index) => {
      dict[item.subject] = item.text;
      selectList.push({value: index, text: item.subject});
    });
    setTaskID(input_task_id);
    setSample(dict);
    setSelectOption(selectList);
    getPassInfo();
    getTaskInfo(input_task_id);
  }, []);
  if (loading) return <ApiLoading loading={loading} />;

  return (
    <Box style={{width: '100%'}}>
      <NoticketPopup
        visibility={noticketVisibility}
        completeEvent={noticketComplete}
      />
      <SubmitDonePopup
        visibility={submitDoneVisibility}
        completeEvent={submitDoneConfirm}
      />
      <HeaderView />
      <TitleComponent title={taskTitleText} message={taskMessage} />
      <BookComponent>
        <>
          {createSampleSelector()}
          <Box style={{marginTop: '1.5rem'}} />
          <CustomTextField2
            title="제목"
            emptyText={'글의 제목을 50자 내로 입력해 주세요.'}
            inputText={titleText}
            handleInput={handleTitle}
            maxCount="50"
          />
          <Box style={{marginTop: '1.5rem'}} />
          <CustomTextField3
            title="본문"
            emptyText={`평가할 글을 입력해주세요. 엔터 키[↲] 입력 시 문단이 나뉘었다고 인식합니다.\n문장 부호 외 특수문자/이모지가 포함된 경우, 평가 결과가 정상적으로 출력되지 않을 수 있습니다.`}
            inputText={bodyText}
            handleInput={handleBody}
            maxCount="10000"
          />
          <CenteredButton
            title="제출하기"
            disabled={titleText == '' || bodyText.trim() == ''}
            clickEvent={navigateToResult}
          ></CenteredButton>
        </>
      </BookComponent>
      <FooterView />
    </Box>
  );
};
export default WritingPage;
