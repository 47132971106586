import React, {useState} from 'react';
import {Box, TextField, Button} from '@mui/material';
import PropTypes from 'prop-types';

const CommentInput = ({onSubmit}) => {
  const [comment, setComment] = useState('');

  const handleCommentChange = e => {
    setComment(e.target.value);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() === '') return; // 빈 댓글 방지
    onSubmit(comment); // ✅ 댓글 전달
    setComment(''); // 입력창 초기화
  };

  return (
    <Box
      sx={{
        display: 'flex',
        marginLeft: '1rem',
        alignItems: 'center',
        gap: '0.5rem',
        width: '100%',
      }}
    >
      {/* ✅ 크기를 줄인 댓글 입력창 */}
      <TextField
        label="댓글 입력"
        variant="outlined"
        multiline
        rows={1} // ✅ 줄 수 줄이기
        value={comment}
        onChange={handleCommentChange}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault(); // ✅ 기본 동작(줄 바꿈) 방지
            handleCommentSubmit();
          }
        }}
        inputProps={{maxLength: 200}} // 최대 글자 수 제한
        sx={{flex: 1, minWidth: '200px'}} // ✅ 입력창 크기 조정
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleCommentSubmit}
        disabled={comment.trim() === ''} // 빈 값이면 비활성화
        sx={{
          whiteSpace: 'nowrap',
          backgroundColor: '#35D2BB',
          marginRight: '1rem',
        }} // ✅ 버튼 크기 유지
      >
        등록
      </Button>
    </Box>
  );
};

// ✅ props 검증 추가
CommentInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CommentInput;
